import React, { Dispatch, FC, ReactNode, useEffect, useState } from 'react'
import {
  Contributor,
  ContributorsOptionsForm,
  ContributorsTextForm
} from '../interfaces/contributors/ContributorsInterfaces'
import { isFilled } from '../utils/validationUtils'
import {
  CheckboxField,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_HOLDERS_CONTESTED_MARK,
  CONTRIBUTOR_RESET_CHECK_EXCLUDED_FIELDS,
  ContributorInfos,
  ContributorValidator,
  Country,
  DOCUMENT_TYPES,
  EventType,
  FieldStatus,
  FRANCE,
  FRMI_TYPE_EXTENSION,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  isDepositDepositor,
  Language,
  ManageableQuality,
  OtherContributorFields,
  PERSONNE_MORALE,
  PERSONNE_PHYSIQUE,
  PROCEDURE_FRMI,
  SelectButton,
  SirenDenomination,
  Transaction,
  UE
} from '../index'
import { FormattedMessage, IntlProvider } from 'react-intl'
import AddressFields from './address/AddressFields'
import Message from '../constants/Message'
import PersonFields from './person/PersonFields'
import { TransactionDocument } from '@inpi-marques/components'
import { ENTITE } from '../constants/contributors/LegalStatusFormationConstants'
import ContributorService from '../services/ContributorService'

interface ContributorFormProps {
  // transaction concernée par le formulaire
  transaction: Transaction,
  // Options pour le formulaire
  optionsForm: ContributorsOptionsForm,
  // type d'intervenant
  contributorType: string,
  // Si le texte a besoin de modification : utiliser cet attribut
  contributorTypeLabel?: string,
  // texte à afficher : titre, soustitre
  text: ContributorsTextForm,
  // Intervenant que l'on peut copier
  contributorsToCopy?: Contributor[] | null,
  // Compte Ets transformé en Contributor pour pouvoir copier ses informations
  currentETSUser?: Contributor,
  // intervenant actuel à modifier et compléter
  contributor?: Contributor,
  // fonction pour modifier l'intervenant
  setContributor: (newContributor: Contributor) => void,
  // informations concernant les erreurs sur le formulaire
  fieldStatus?: FieldStatus,
  // fonction permettant la récupération des pays
  getCountries: () => Promise<void | Country[]>,
  // fonction permettant de compléter un intervenant depuis son siren
  fillContributorInfo?: (siren: string) => Promise<ContributorInfos | null>,
  // fonction permettant de trouver une liste de siren depuis un nom d'entreprise
  findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[] | null>,
  // liste de qualités
  manageableQualities?: ManageableQuality[],
  // boutons a afficher
  children?: ReactNode,
  // fonction pour ajouter un document de pouvoir à un intervenant
  addPowerDocument?: (event: EventType) => void,
  // fonction pour supprimer un document de pouvoir à un intervenant
  deletePowerDocument?: (editedContributor?: Contributor) => void
  dispatch?: Dispatch<any>,
  resetError?: (fieldStatus: FieldStatus) => void,
  // fonction pour afficher la modale de sélection depuis le carnet d'adresses
  selectFromAddressBook?: () => void,
  // fonction pour ajouter un intervenant au carnet d'adresses
  addContributorToAddressBook?: (contributor: Contributor) => void,
  actionWithAddressBook?: boolean,
  // className pour le header
  classNameHeader?: string,
  // Liste des intervenants
  contributorList?: Contributor[],
  // Activation du mode guidé
  activeGuidedMode?: boolean,
  // Choix d'identification des intervenants
  choiceContributor?: string,
  handleDownload?: (document: TransactionDocument) => void,
  isBO?: boolean,
  onDownloadClick?: () => Promise<ArrayBuffer | string | Blob | null>,
  getLanguages?: () => Promise<Language[]>,
  isFirst?: boolean,
  isAdded?: boolean,
  isEdited?: boolean,
  isRecord?: boolean
}

const ContributorForm: FC<ContributorFormProps> = ({
  transaction,
  optionsForm,
  contributorType,
  contributorTypeLabel,
  text,
  contributorsToCopy,
  currentETSUser,
  contributor,
  setContributor,
  fieldStatus,
  getCountries,
  fillContributorInfo,
  findListSirenByNamePromise,
  manageableQualities,
  children,
  addPowerDocument,
  deletePowerDocument,
  dispatch,
  resetError,
  selectFromAddressBook,
  addContributorToAddressBook,
  actionWithAddressBook = true,
  classNameHeader,
  contributorList,
  activeGuidedMode,
  choiceContributor,
  handleDownload,
  isBO = false,
  onDownloadClick,
  getLanguages,
  isFirst,
  isAdded,
  isEdited,
  isRecord
}) => {
  // Si le mode guidé est activé, on remplie par défaut les champs du Déposant en fonction de l'identification des intervenants
  useEffect(() => {
    if (activeGuidedMode) {
      // si l'intervenant est déjà enregistré dans le state de la transaction, on ne veut pas changer le champs type automatiquement
      // si le type ne correspond pas à celui du mode guidé, pour garder les valeurs
      if (contributorType === CONTRIBUTOR_DEPOSITORS.value && transaction?.depositors?.find((depositor) => depositor.id === contributor?.id && depositor?.type === contributor?.type)) {
        return
      }

      // On met par default le type du déposant en fonction de l'identification des intervenants
      let type: string | undefined

      if (choiceContributor === CONTRIBUTOR_GUIDED_PP.idBtnSwitch || choiceContributor === CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch) {
        type = PERSONNE_PHYSIQUE.value
      } else if (choiceContributor === CONTRIBUTOR_GUIDED_PM.idBtnSwitch) {
        type = PERSONNE_MORALE.value
      }

      if (!contributorList?.length) {
        setStateFieldStatus({})
        updateContributor(ContributorValidator.cleanCopyContributor({
          ...currentETSUser,
          type: type
        }, contributorType, { ...contributor }, transaction.subProcedureType))
      } else {
        setContributor({ ...contributor, type: type })
      }
    }
  }, [])

  /**
   * Enregistrement des modifications de l'intervenant
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    const newContributor: Contributor = {
      ...contributor,
      [name]: value
    }

    if (name === 'address' && contributor?.otherAddress && contributor.address?.country === FRANCE) {
      newContributor.otherAddress = undefined
    }

    updateContributor(newContributor)
  }

  /**
   * Mise à jour d'un des champs du formulaire PersonField
   * @param event
   * @returns
   */
  const onPersonFieldChanged = (event:EventType): void => {
    const { name, value } = event.target

    // Si la nature de l'entité de formation est différente de "Autre entité", on supprime la description liée
    if (name === 'legalFormFormation' && value !== ENTITE.value) {
      const newContributor: Contributor = {
        ...contributor,
        legalFormFormation: value,
        descriptionFormation: undefined
      }
      updateContributor(newContributor)
      return
    }

    // Si on décoche la case "J'agis au nom et pour le compte d'une entité en cours de formation", on supprime les infos liées
    if (name === 'personFormation' && !value) {
      const newContributor: Contributor = {
        ...contributor,
        personFormation: value,
        companyNameFormation: undefined,
        legalFormFormation: undefined,
        descriptionFormation: undefined
      }
      updateContributor(newContributor)
      return
    }

    if (name !== 'manageableQuality') {
      handleChanges(event)
      return
    }

    // Si on met à jour la qualité, alors on clean les champs qui ne sont plus nécessaire à la nouvelle qualité
    const newManageableQuality: ManageableQuality|undefined = manageableQualities?.find((quality: ManageableQuality) => quality.code === value.code)

    const newContributor: Contributor = {
      ...contributor,
      manageableQuality: value
    }

    // On supprime le numéro requis s'il est présent et que la prochaine qualité n'en a pas besoin
    if (newManageableQuality && !newManageableQuality.numberNeeded && !newManageableQuality.numberOptional && contributor?.manageableQuality?.numberNeeded) {
      newContributor.inscriptionNumber = undefined
    }

    /**
     * On met à jour le state du parent
     */
    updateContributor(newContributor)

    /**
     * Si un document de pouvoir était lié au contributeur alors qu'il n'est plus nécessaire pour la prochaine qualité,
     * alors on le supprime de la transaction.
     * L'upload du document se faisant directement après l'upload browser (chose à ne plus faire pour des raisons d'opti, pour éviter
     * de faire X appel en cas de modification de fichier récurent dans ce formulaire), on le supprime en "background" après avoir
     * changé les valeurs cîté front.
     * On supprime le fichier et le store sera mis à jour
     */
    if (!newManageableQuality?.powerNeeded && deletePowerDocument) {
      const powerDocument: TransactionDocument|undefined =
        transaction.documents?.find((document: TransactionDocument) => document.type === DOCUMENT_TYPES.POWER &&
        contributor?.internalNameDocuments?.some((internalNameDocument: string) => document.internalName === internalNameDocument))
      if (powerDocument) {
        deletePowerDocument(newContributor)
      }
    }
  }

  /**
   * Met à jour l'intervenant (mais pas l'email si l'option isEmailReadOnly est à true)
   * @param updatedContributor
   */
  const updateContributor = (updatedContributor: Contributor) => {
    let newContributor = { ...updatedContributor }
    if (optionsForm?.isEmailReadOnly) {
      newContributor = {
        ...newContributor,
        email: contributor?.email
      }
    }
    setContributor(newContributor)
  }

  /**
   * Complète un intervenant avec les informations récupérées depuis son siren
   * @param siren
   */
  const fillContributorInfoAndChange = (siren: string) => {
    fillContributorInfo && fillContributorInfo(siren).then((newContributorInfo: ContributorInfos | null) => {
      const newContributor = {
        ...contributor,
        ...newContributorInfo
      }
      updateContributor(newContributor)
    })
  }

  /**
   * Modifie le type de personne (physique ou morale) et clean les fields
   * @param event
   */
  const changePPOrPMAndCleanContributorInfo = (event: EventType) => {
    const { name, value } = event.target

    let newContributor = {
      ...contributor,
      [name]: value
    }

    if (value === PERSONNE_MORALE.value) {
      if (!optionsForm.hasPhysiqueField) {
        newContributor = {
          ...newContributor,
          lastname: '',
          firstname: ''
        }
      }
      newContributor = {
        ...newContributor,
        nationality: undefined
      }
    } else if (value === PERSONNE_PHYSIQUE.value) {
      newContributor = {
        ...newContributor,
        publicLaw: false,
        companyName: '',
        legalForm: '',
        siren: '',
        fullCompanyName: ''
      }
    }

    if (!(contributor && (contributor?.firstname || contributor.lastname))) {
      newContributor.civilityCode = ''
    }
    if (isDepositDepositor(transaction, contributorType)) {
      newContributor.nationality = 'FR'
    }
    updateContributor(newContributor)
  }

  /**
   *  On récupère le field status du bon contributeur en fonction de son type (contributorType)
   *  On y ajoute également un type de fieldStatusType afin de mettre à jour plus tard dans le store, le bon attribut
   *
   * @returns
   */
  const getFieldStatus = (): FieldStatus | undefined => {
    if (fieldStatus) {
      if (fieldStatus[contributorType]) {
        return { ...(fieldStatus[contributorType] as FieldStatus), fieldStatusType: contributorType }
      } else {
        return { ...fieldStatus, fieldStatusType: contributorType }
      }
    }
    return undefined
  }

  const [stateFieldStatus, setStateFieldStatus] = useState<FieldStatus | undefined>(getFieldStatus())

  useEffect(() => {
    setStateFieldStatus(getFieldStatus())
  }, [fieldStatus, contributorType])

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='contributor-form'>
        <div className={`form-header ${isBO ? 'border-bottom-0' : ''} ${classNameHeader}`}>
          <div className='col options px-0 form-header mb-2'>
            {
              !optionsForm.allFieldReadOnly && actionWithAddressBook && selectFromAddressBook &&
                <>
                  <button
                    className='btn btn-link text-primary'
                    onClick={selectFromAddressBook}
                  >
                    <FormattedMessage id='contributor_from_address_book' />
                  </button>
                  <div className='separator' />
                </>
            }
            {
              !optionsForm.allFieldReadOnly && isFilled(contributorsToCopy) && (
                <>
                  <SelectButton
                    className='btn btn-link text-primary'
                    options={contributorsToCopy?.map(contributorToCopy => ({
                      label: ContributorService.getName(contributorToCopy),
                      value: contributorToCopy
                    }))}
                    onSelect={(contributorToCopy) => {
                      updateContributor(ContributorValidator.cleanCopyContributor({ ...contributorToCopy }, contributorType, { ...contributor }, transaction.subProcedureType, isAdded, isEdited))
                    }}
                  >
                    <FormattedMessage id='contributor_copy' />
                  </SelectButton>
                  <div className='separator' />
                </>
              )
            }
            {
              !optionsForm.allFieldReadOnly && currentETSUser && (typeof contributor?.idUser === 'string' ? parseInt(contributor?.idUser) : contributor?.idUser) !== currentETSUser?.idUser &&
            contributorType !== CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value &&
            (
              <>
                <button
                  className='btn btn-link text-primary' onClick={() => {
                    setStateFieldStatus({})
                    updateContributor({ ...ContributorValidator.cleanCopyContributor({ ...currentETSUser }, contributorType, { ...contributor }, transaction.subProcedureType, isAdded, isEdited) })
                  }}
                >
                  <FormattedMessage
                    id={contributorTypeLabel ? `contributor_auto_${contributorTypeLabel}` : `contributor_auto_${contributorType}`}
                  />
                </button>
                <div className='separator' />
              </>
            )
            }
            {
              !optionsForm.allFieldReadOnly && setContributor && contributor && ((currentETSUser && currentETSUser?.idUser !== contributor?.idUser) || !currentETSUser) && !!Object.keys(contributor)?.find(key => (!CONTRIBUTOR_RESET_CHECK_EXCLUDED_FIELDS?.[contributorType]?.find(field => field === key) && contributor[key])) &&
            (
              <>
                <button className='btn btn-link text-primary' onClick={() => updateContributor(ContributorService.getDefaultContributor(transaction, contributorType))}>
                  <FormattedMessage id='contributor_reinitialiser' />
                </button>
                <div className='separator' />
              </>
            )
            }
            {
              !optionsForm.allFieldReadOnly && setContributor && contributor && currentETSUser && currentETSUser?.idUser === contributor?.idUser &&
            !!Object.keys(contributor)?.find((key: string) => (!CONTRIBUTOR_RESET_CHECK_EXCLUDED_FIELDS?.[contributorType]?.find(field => field === key) && contributor[key])) &&
            (
              <button
                className='btn btn-link text-primary'
                onClick={() => updateContributor(ContributorService.getDefaultContributor(transaction, contributorType))}
              >
                <FormattedMessage id='contributor_reinitialiser' />
              </button>
            )
            }
          </div>
          <div className='d-flex justify-content-between'>
            <div>
              <div className='d-flex'>
                {text.title}
              </div>
              {
                !isBO &&
              text.subtitle
              }
            </div>
          </div>
        </div>

        <PersonFields
          getCountries={getCountries}
          transaction={transaction}
          contributor={contributor}
          onChange={onPersonFieldChanged}
          options={optionsForm}
          fieldStatus={stateFieldStatus}
          fillContributorInfo={fillContributorInfo && fillContributorInfoAndChange}
          findListSirenByNamePromise={findListSirenByNamePromise}
          changePPOrPMAndCleanContributorInfo={changePPOrPMAndCleanContributorInfo}
          manageableQualities={manageableQualities?.filter(manageableQuality => manageableQuality.contributors.includes(contributorType.includes(CONTRIBUTOR_AGENT.value) ? CONTRIBUTOR_AGENT.value : contributorType))}
          addPowerDocument={addPowerDocument}
          deletePowerDocument={deletePowerDocument}
          dispatch={dispatch}
          resetError={resetError}
          contributorType={contributorType}
          handleDownload={handleDownload}
          isBO={isBO}
          onDownloadClick={onDownloadClick}
        />

        {
        optionsForm?.hasAddressField &&
          <AddressFields
            options={optionsForm}
            onChange={handleChanges}
            address={contributor?.address}
            getCountries={getCountries}
            fieldStatus={stateFieldStatus}
            dispatch={dispatch}
            resetError={resetError}
          />
        }

        {
          (optionsForm?.hasAddressField && transaction && transaction.procedureType === PROCEDURE_FRMI.value) &&

        (((((contributor?.type === PERSONNE_PHYSIQUE.value && contributor.nationality && contributor.nationality !== FRANCE) || (contributor?.address?.country === UE))) ||
          (contributor?.type === PERSONNE_MORALE.value)) && contributor.address?.country !== FRANCE) &&
            <AddressFields
              title='field_other_address_label'
              options={optionsForm}
              fieldInputPrefix='otherAddress'
              onChange={handleChanges}
              address={contributor?.otherAddress}
              getCountries={() => getCountries ? getCountries().then((countries) => countries ? countries?.filter((country: Country) => country.code === FRANCE) : []) : Promise.resolve([])}
              fieldStatus={stateFieldStatus}
              dispatch={dispatch}
              resetError={resetError}
              propName='otherAddress'
              subtitle='field_other_address_subtitle'
            />
        }
        {
          getLanguages && ((transaction.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value && contributorType === CONTRIBUTOR_HOLDERS.value) ||
          (transaction.subProcedureType === FRMI_TYPE_EXTENSION.value || transaction.subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value)) && (
            <OtherContributorFields
              transaction={transaction}
              options={optionsForm}
              contributor={contributor}
              updateContributor={updateContributor}
              handleChanges={handleChanges}
              resetError={resetError}
              fieldStatus={stateFieldStatus}
              getLanguages={getLanguages}
              isFirst={isFirst}
            />
          )
        }

        {
          actionWithAddressBook && addContributorToAddressBook &&
            <button
              className='btn btn-link text-primary mb-3'
              onClick={() => addContributorToAddressBook(contributor)}
            >
              <FormattedMessage id='contributor_add' />
            </button>
        }
        {isBO && !isRecord && (
          <CheckboxField
            inputId='isRecipient'
            className='mt-4'
            checked={contributor?.isRecipient}
            label={<FormattedMessage id='contributor_can_be_recipient_inscription' />}
            onChange={handleChanges}
            fieldStatus={fieldStatus}
          />
        )}
        {children}
        <div className='d-flex justify-content-end'>
          {text.buttons}
        </div>
      </div>
    </IntlProvider>
  )
}

export default ContributorForm
