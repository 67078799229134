import Message from './Message'
import {
  BUTTON_DELETE,
  FIELD_ARRIVAL_DATE,
  FIELD_BRAND_MODEL,
  FIELD_BRAND_TYPE_RECORD,
  FIELD_DEPOSITOR_SEARCHABLE_ENTITY,
  FIELD_INTERNAL_REFERENCE,
  FIELD_LAST_UPDATE,
  FIELD_NUMNAT,
  FIELD_PROCEDURE_TYPE,
  FIELD_SUB_PROCEDURE_TYPE,
  NOTIFICATION_STATUS_TO_ANSWER, NOTIFICATION_STATUS_TO_CLOSE_SENT,
  NOTIFICATION_STATUS_TO_CONSIDER,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_MIFR,
  PROCEDURE_NULLITY,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_OPPOSITION,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION,
  PROCEDURE_REVOCATION_STATEMENT,
  STATUS_DEPOSIT,
  STATUS_DEPOSIT_REVIEWING,
  STATUS_DIVISION,
  STATUS_DIVISION_REVIEWING,
  STATUS_FRMI,
  STATUS_FRMI_REVIEWING,
  STATUS_INSCRIPTION,
  STATUS_INSCRIPTION_REVIEWING,
  STATUS_MIFR,
  STATUS_MIFR_REVIEWING,
  STATUS_ND,
  STATUS_ND_REVIEWING,
  STATUS_OFFICIAL_DOCUMENT,
  STATUS_OFFICIAL_DOCUMENT_REVIEWING,
  STATUS_OPPOSITION,
  STATUS_OPPOSITION_REVIEWING,
  TABLE_TITLE_NOTIFS,
  STATUS_RENEWAL,
  STATUS_RENEWAL_REVIEWING,
  STATUS_REVOCATION_STATEMENT,
  STATUS_REVOCATION_STATEMENT_REVIEWING
} from '@inpi-marques/components'
import { BasketFilter, BasketInterface } from '../interfaces/BasketInterface'
import { FIELD_DEPOSIT_DATE_RECORD } from '@inpi-marques/components/src/constants/RecordListTableConstants'

export const BASKET_HEADER: BasketFilter[] = [
  {
    id: 'home',
    isHome: true,
    buttons: []
  },
  {
    id: 'deposit',
    procedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_DIVISION.value, PROCEDURE_MIFR.value],
    buttons: [
      {
        buttonText: Message.home_button_depot,
        buttonUrl: '/depots/nouveau'
      },
      {
        buttonText: Message.home_button_division,
        buttonUrl: '/divisions/nouveau'
      }
    ]
  },
  {
    id: 'opposition',
    procedures: [PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value],
    buttons: [{
      buttonText: Message.home_button_opposition,
      buttonUrl: '/oppositions/nouveau'
    }, {
      buttonText: Message.home_button_nullity,
      buttonUrl: '/nullites/nouveau'
    }, {
      buttonText: Message.home_button_revocation,
      buttonUrl: '/decheances/nouveau'
    }]
  },
  {
    id: 'inscription',
    procedures: [PROCEDURE_INSCRIPTION.value],
    buttons: [{
      buttonText: Message.home_button_inscription_act,
      buttonUrl: '/inscriptions/nouveau'
    }, {
      buttonText: Message.home_button_waiver,
      buttonUrl: '/renonciations/nouveau'
    }]
  },
  {
    id: 'frmi',
    procedures: [PROCEDURE_FRMI.value],
    buttons: [{
      buttonText: Message.home_button_frmi,
      buttonUrl: '/frmi/nouveau'
    },
    {
      buttonText: Message.home_button_frmi_post_operations,
      buttonUrl: '/operations-posterieures/nouveau'
    }]
  },
  {
    id: 'renewal',
    procedures: [PROCEDURE_RENEWAL.value],
    buttons: [{
      buttonText: Message.home_button_renewal,
      buttonUrl: '/renouvellement/nouveau'
    }]
  },
  {
    id: 'revocation_statement',
    procedures: [PROCEDURE_REVOCATION_STATEMENT.value],
    buttons: [{
      buttonText: Message.home_button_revocation_statement,
      buttonUrl: '/releves-de-decheance/nouveau'
    }]
  },
  {
    id: 'official_documents',
    procedures: [PROCEDURE_OFFICIAL_DOCUMENT.value],
    buttons: [{
      buttonText: Message.home_button_official_documents,
      buttonUrl: '/documents-officiels/nouveau'
    }]
  }
]

export const BASKET_HEADER_LOT_2: BasketFilter[] = [
  {
    id: 'home',
    isHome: true,
    buttons: []
  },
  {
    id: 'deposit',
    procedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_DIVISION.value, PROCEDURE_MIFR.value],
    buttons: [
      {
        buttonText: Message.home_button_depot,
        buttonUrl: '/depots/nouveau'
      },
      {
        buttonText: Message.home_button_division,
        buttonUrl: '/divisions/nouveau'
      }
    ]
  },
  {
    id: 'opposition',
    procedures: [PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value],
    buttons: [{
      buttonText: Message.home_button_opposition,
      buttonUrl: '/oppositions/nouveau'
    }, {
      buttonText: Message.home_button_nullity,
      buttonUrl: '/nullites/nouveau'
    }, {
      buttonText: Message.home_button_revocation,
      buttonUrl: '/decheances/nouveau'
    }]
  },
  {
    id: 'inscription',
    procedures: [PROCEDURE_INSCRIPTION.value],
    buttons: [{
      buttonText: Message.home_button_inscription_act,
      buttonUrl: '/inscriptions/nouveau'
    }, {
      buttonText: Message.home_button_waiver,
      buttonUrl: '/renonciations/nouveau'
    }]
  }
]

// Note sur les listes qui sont affichées quand on clic sur une corbeille :
// si on a dans le header une value :
// -  'numNat' on affiche le numnat (en 2ème)
// -  'toDelete' on affiche une poubelle pour supprimer une sauvegarde en brouillon (en dernier)

export const HEADER_TO_DELETE = {
  label: Message.title_delete_all_draft,
  className: 'w-10',
  value: BUTTON_DELETE
}

export const HEADER_DEPOSITOR_SEARCHABLE_ENTITY = {
  label: Message.contributor_label,
  className: '',
  value: FIELD_DEPOSITOR_SEARCHABLE_ENTITY
}

export const TABLE_TRANSACTION_DRAFT_LIST = [
  {
    label: Message.procedure_type_label,
    className: '',
    value: FIELD_PROCEDURE_TYPE
  },
  {
    label: Message.transaction_lastUpdate,
    className: '',
    value: FIELD_LAST_UPDATE
  },
  {
    label: Message.field_internal_reference_label,
    className: '',
    value: FIELD_INTERNAL_REFERENCE
  },
  HEADER_DEPOSITOR_SEARCHABLE_ENTITY,
  {
    label: Message.transaction_type,
    className: '',
    value: FIELD_SUB_PROCEDURE_TYPE
  },
  HEADER_TO_DELETE
]

export const TABLE_TRANSCTION_LIST_COMMON = [
  {
    label: Message.procedure_type_label,
    className: '',
    value: FIELD_PROCEDURE_TYPE
  },
  {
    label: Message.transaction_num,
    className: '',
    value: FIELD_NUMNAT
  },
  {
    label: Message.transaction_brand_model,
    className: '',
    value: FIELD_BRAND_MODEL
  },
  {
    label: Message.field_internal_reference_label,
    className: '',
    value: FIELD_INTERNAL_REFERENCE
  },
  HEADER_DEPOSITOR_SEARCHABLE_ENTITY
]

export const TABLE_TRANSACTION_LIST = [
  ...TABLE_TRANSCTION_LIST_COMMON,
  {
    label: Message.transaction_type,
    className: '',
    value: FIELD_SUB_PROCEDURE_TYPE
  },
  {
    label: Message.overview_request_date,
    className: '',
    value: FIELD_ARRIVAL_DATE
  }
]

export const TABLE_RECORD_LIST = [
  {
    label: Message.transaction_num,
    className: '',
    value: FIELD_NUMNAT
  },
  {
    label: Message.transaction_arrivalDate,
    className: '',
    value: FIELD_DEPOSIT_DATE_RECORD
  },
  HEADER_DEPOSITOR_SEARCHABLE_ENTITY,
  {
    label: Message.transaction_type,
    className: '',
    value: FIELD_BRAND_TYPE_RECORD
  }
]

export const BASKET_ID = {
  DRAFT: 'draft',
  UNDER_REVIEW: 'under_review',
  UNDER_REVIEW_OMPI: 'under_review_ompi',
  PUBLISH: 'publish',
  REJECTED: 'rejected',
  WITH_NOTIFICATION: 'with_notifications',
  MY_BRANDS: 'my_brands',
  PENDING_NOTIFICATION: 'pending_notifications',
  PENDING_NOTIFICATION_OMPI: 'pending_notifications_ompi',
  FINISH: 'finish',
  REGISTERING: 'registering',
  PROVIDED_DOCUMENTS: 'provided_documents',
  ARCHIVED: 'archived'
}

export const BASKET_TYPES: BasketInterface[] = [
  {
    id: BASKET_ID.DRAFT,
    urlParam: 'sauvegardes',
    statusFiltres: [STATUS_DEPOSIT.DRAFT, STATUS_OPPOSITION.DRAFT, STATUS_ND.DRAFT, STATUS_DIVISION.DRAFT, STATUS_INSCRIPTION.DRAFT, STATUS_INSCRIPTION.DRAFT_CLIENT, STATUS_REVOCATION_STATEMENT.DRAFT_CLIENT, STATUS_REVOCATION_STATEMENT.DRAFT,
      STATUS_FRMI.DRAFT_CLIENT, STATUS_FRMI.DRAFT, STATUS_DEPOSIT.WAITING_VIR_PAYMENT, STATUS_OPPOSITION.WAITING_VIR_PAYMENT, STATUS_ND.WAITING_VIR_PAYMENT, STATUS_REVOCATION_STATEMENT.WAITING_VIR_PAYMENT,
      STATUS_DIVISION.WAITING_VIR_PAYMENT, STATUS_INSCRIPTION.WAITING_VIR_PAYMENT, STATUS_FRMI.WAITING_VIR_PAYMENT, STATUS_RENEWAL.WAITING_VIR_PAYMENT, STATUS_RENEWAL.DRAFT_CLIENT, STATUS_OFFICIAL_DOCUMENT.DRAFT_CLIENT, STATUS_OFFICIAL_DOCUMENT.DRAFT, STATUS_OFFICIAL_DOCUMENT.WAITING_VIR_PAYMENT],
    headers: TABLE_TRANSACTION_DRAFT_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.UNDER_REVIEW,
    urlParam: 'en-cours-examen',
    statusFiltres: [...STATUS_DEPOSIT_REVIEWING, ...STATUS_OPPOSITION_REVIEWING, ...STATUS_ND_REVIEWING, ...STATUS_MIFR_REVIEWING, ...STATUS_DIVISION_REVIEWING, ...STATUS_INSCRIPTION_REVIEWING, ...STATUS_FRMI_REVIEWING, ...STATUS_OFFICIAL_DOCUMENT_REVIEWING, ...STATUS_RENEWAL_REVIEWING, ...STATUS_REVOCATION_STATEMENT_REVIEWING],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.UNDER_REVIEW_OMPI,
    urlParam: 'en-cours-examen-ompi',
    statusFiltres: [STATUS_FRMI.REVIEWING_OMPI],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_FRMI.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.PENDING_NOTIFICATION,
    urlParam: 'notifications',
    statusFiltres: [NOTIFICATION_STATUS_TO_ANSWER.value, NOTIFICATION_STATUS_TO_CONSIDER.value, NOTIFICATION_STATUS_TO_CLOSE_SENT.value],
    headers: TABLE_TITLE_NOTIFS,
    isNotif: true,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.PENDING_NOTIFICATION_OMPI,
    urlParam: 'notifications-ompi',
    statusFiltres: ['NOTIFICATIONS_OMPI'],
    searchParams: { aggregation: 'NOTIFICATIONS_OMPI' },
    headers: TABLE_TITLE_NOTIFS,
    isOther: true,
    forProcedures: [PROCEDURE_FRMI.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.REGISTERING,
    urlParam: 'registering',
    statusFiltres: [STATUS_DEPOSIT.REGISTERING],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.PUBLISH,
    urlParam: 'publies',
    statusFiltres: [STATUS_DEPOSIT.REGISTERED, STATUS_MIFR.REGISTERED, STATUS_DIVISION.PUBLISHED, STATUS_INSCRIPTION.PUBLISHED, STATUS_REVOCATION_STATEMENT.REGISTERED, STATUS_RENEWAL.PUBLISHED],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_RENEWAL.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.REJECTED,
    urlParam: 'rejetes',
    statusFiltres: [STATUS_DEPOSIT.REJECTED, STATUS_MIFR.REJECTED, STATUS_DIVISION.REJECTED, STATUS_DEPOSIT.TOTAL_WITHDRAWAL, STATUS_MIFR.TOTAL_WITHDRAWAL, STATUS_DIVISION.WITHDRAWN, STATUS_INSCRIPTION.REJECTED, STATUS_INSCRIPTION.WITHDRAWN, STATUS_FRMI.REJECTED, STATUS_FRMI.WITHDRAWN, STATUS_OFFICIAL_DOCUMENT.REJECTED, STATUS_REVOCATION_STATEMENT.REJECTED, STATUS_RENEWAL.WITHDRAWN, STATUS_RENEWAL.REJECTED, STATUS_RENEWAL.NOT_ACCEPTED],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.FINISH,
    urlParam: 'termines',
    statusFiltres: [STATUS_OPPOSITION.DONE, STATUS_ND.DONE, STATUS_FRMI.REGISTERED_OMPI, STATUS_OFFICIAL_DOCUMENT.PROCESSED],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value, PROCEDURE_FRMI.value, PROCEDURE_OFFICIAL_DOCUMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.MY_BRANDS,
    urlParam: 'mes-marques',
    statusFiltres: ['RELATED_RECORDS'],
    searchParams: { aggregation: 'RELATED_RECORDS' },
    headers: TABLE_RECORD_LIST,
    forProcedures: [],
    serviceType: 'records'
  }, {
    id: BASKET_ID.PROVIDED_DOCUMENTS,
    urlParam: 'documents-fournis',
    isOther: true,
    statusFiltres: ['AVAILABLE_OFFICIAL_DOCUMENTS'],
    searchParams: { aggregation: 'AVAILABLE_OFFICIAL_DOCUMENTS' },
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_OFFICIAL_DOCUMENT.value]
  }, {
    id: BASKET_ID.ARCHIVED,
    urlParam: 'archives',
    isOther: true,
    statusFiltres: ['ARCHIVED_OFFICIAL_DOCUMENTS'],
    searchParams: { aggregation: 'ARCHIVED_OFFICIAL_DOCUMENTS' },
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_OFFICIAL_DOCUMENT.value]
  }
]

export const BASKET_TYPES_LOT_2: BasketInterface[] = [
  {
    id: BASKET_ID.DRAFT,
    urlParam: 'sauvegardes',
    statusFiltres: [STATUS_DEPOSIT.DRAFT, STATUS_OPPOSITION.DRAFT, STATUS_ND.DRAFT, STATUS_DIVISION.DRAFT, STATUS_INSCRIPTION.DRAFT, STATUS_INSCRIPTION.DRAFT_CLIENT, STATUS_REVOCATION_STATEMENT.DRAFT_CLIENT, STATUS_REVOCATION_STATEMENT.DRAFT,
      STATUS_FRMI.DRAFT_CLIENT, STATUS_FRMI.DRAFT, STATUS_DEPOSIT.WAITING_VIR_PAYMENT, STATUS_OPPOSITION.WAITING_VIR_PAYMENT, STATUS_ND.WAITING_VIR_PAYMENT, STATUS_REVOCATION_STATEMENT.WAITING_VIR_PAYMENT,
      STATUS_DIVISION.WAITING_VIR_PAYMENT, STATUS_INSCRIPTION.WAITING_VIR_PAYMENT, STATUS_FRMI.WAITING_VIR_PAYMENT, STATUS_RENEWAL.WAITING_VIR_PAYMENT, STATUS_RENEWAL.DRAFT_CLIENT, STATUS_OFFICIAL_DOCUMENT.DRAFT_CLIENT, STATUS_OFFICIAL_DOCUMENT.DRAFT, STATUS_OFFICIAL_DOCUMENT.WAITING_VIR_PAYMENT],
    headers: TABLE_TRANSACTION_DRAFT_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.UNDER_REVIEW,
    urlParam: 'en-cours-examen',
    statusFiltres: [...STATUS_DEPOSIT_REVIEWING, ...STATUS_OPPOSITION_REVIEWING, ...STATUS_ND_REVIEWING, ...STATUS_MIFR_REVIEWING, ...STATUS_DIVISION_REVIEWING, ...STATUS_INSCRIPTION_REVIEWING, ...STATUS_FRMI_REVIEWING, ...STATUS_OFFICIAL_DOCUMENT_REVIEWING, ...STATUS_RENEWAL_REVIEWING, ...STATUS_REVOCATION_STATEMENT_REVIEWING],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.PENDING_NOTIFICATION,
    urlParam: 'notifications',
    statusFiltres: [NOTIFICATION_STATUS_TO_ANSWER.value, NOTIFICATION_STATUS_TO_CONSIDER.value, NOTIFICATION_STATUS_TO_CLOSE_SENT.value],
    headers: TABLE_TITLE_NOTIFS,
    isNotif: true,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.REGISTERING,
    urlParam: 'registering',
    statusFiltres: [STATUS_DEPOSIT.REGISTERING],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.PUBLISH,
    urlParam: 'publies',
    statusFiltres: [STATUS_DEPOSIT.REGISTERED, STATUS_MIFR.REGISTERED, STATUS_DIVISION.PUBLISHED, STATUS_INSCRIPTION.PUBLISHED, STATUS_REVOCATION_STATEMENT.REGISTERED, STATUS_RENEWAL.PUBLISHED],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_RENEWAL.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.REJECTED,
    urlParam: 'rejetes',
    statusFiltres: [STATUS_DEPOSIT.REJECTED, STATUS_MIFR.REJECTED, STATUS_DIVISION.REJECTED, STATUS_DEPOSIT.TOTAL_WITHDRAWAL, STATUS_MIFR.TOTAL_WITHDRAWAL, STATUS_DIVISION.WITHDRAWN, STATUS_INSCRIPTION.REJECTED, STATUS_FRMI.REJECTED, STATUS_FRMI.WITHDRAWN, STATUS_OFFICIAL_DOCUMENT.REJECTED, STATUS_REVOCATION_STATEMENT.REJECTED, STATUS_RENEWAL.WITHDRAWN, STATUS_RENEWAL.REJECTED],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_FRMI.value, PROCEDURE_RENEWAL.value, PROCEDURE_OFFICIAL_DOCUMENT.value, PROCEDURE_REVOCATION_STATEMENT.value],
    serviceType: 'transactions'
  }, {
    id: BASKET_ID.FINISH,
    urlParam: 'termines',
    statusFiltres: [STATUS_OPPOSITION.DONE, STATUS_ND.DONE],
    headers: TABLE_TRANSACTION_LIST,
    forProcedures: [PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value]
  }, {
    id: BASKET_ID.MY_BRANDS,
    urlParam: 'mes-marques',
    statusFiltres: ['RELATED_RECORDS'],
    searchParams: { aggregation: 'RELATED_RECORDS' },
    headers: TABLE_RECORD_LIST,
    forProcedures: [],
    serviceType: 'records'
  }
]
