import React, { FC, useEffect, useState } from 'react'
import { Contributor } from '../interfaces/contributors/ContributorsInterfaces'
import { Country, Language } from '../interfaces/ContentInterfaces'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { PERSONNE_PHYSIQUE } from '../constants/contributors/PersonConstants'
import Message from '../constants/Message'
import { ETS_ACCOUNT_STATE_LABEL, IStateLabel } from '../constants/ETSAccountConstants'
import {
  Address,
  CONTRIBUTOR_OLD_HOLDERS,
  DateUtils,
  isDepositDepositor,
  Permission,
  PROCEDURE_OFFICIAL_DOCUMENT
} from '@inpi-marques/components'
import { SubmitButton } from '../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import ContributorService from '../services/ContributorService'

interface OverviewContributorProps {
  contributor: Contributor,
  countries: Country[],
  status?: string,
  procedureType?: string,
  canBeBeneficiary?: boolean,
  handleIsBeneficiary?: () => Promise<void>,
  beneficiaries?: Contributor[],
  isBo?: boolean,
  fromFrmiExtension?: boolean,
  getLanguages?: () => Promise<Language[]>,
  permissionEdit?: string,
  isReadOnly?: boolean,
  isFirst?: boolean,
  isAdministration?: boolean,
  isRecord?:boolean,
  contributorType?: string
}

const OverviewContributor: FC<OverviewContributorProps> = ({
  contributor,
  countries,
  status,
  procedureType,
  canBeBeneficiary,
  handleIsBeneficiary,
  beneficiaries,
  isBo = false,
  fromFrmiExtension = false,
  getLanguages,
  permissionEdit,
  isReadOnly,
  isFirst,
  isAdministration,
  isRecord,
  contributorType
}) => {
  const address = contributor?.address || {}
  const [countryName, setCountryName] = useState<string>()
  const [ompiLanguage, setOmpiLanguage] = useState<string|undefined>()
  const [otherLanguage, setOtherLanguage] = useState<string|undefined>()
  const [nationalityName, setNationalityName] = useState<string>()

  useEffect(() => {
    if (countries) {
      const country = countries.find((item: Country) => item.code === address.country)
      if (country) {
        setCountryName(country.name)
      }
      if (contributor.nationality) {
        const nationality = countries.find((item: Country) => item.code === contributor.nationality)
        if (nationality) {
          setNationalityName(nationality.name)
        }
      }
    }
  }, [contributor, countries])

  useEffect(() => {
    if ((contributor.OMPILanguage || contributor.otherLanguage) && getLanguages) {
      getLanguages().then((response: Language[]) => {
        const language = response.find((item: Language) => item.code === contributor.OMPILanguage)
        if (language) {
          setOmpiLanguage(language.name)
        }
        const secondLanguage = response.find((item: Language) => item.code === contributor.otherLanguage)
        if (secondLanguage) {
          setOtherLanguage(secondLanguage.name)
        }
      })
    }
  }, [])

  /**
   * Recupère l'id de traduction pour le code d'un contributeur (ETSAccount)
   * @param code
   * @returns
   */
  const getEtsAccountTranslatedCode = (code?: string): string => {
    if (!code) {
      code = 'agent'
    }
    return `etsaccount_code_${code.toLowerCase()}_${procedureType?.toLowerCase()}_label`
  }

  /**
   * Affihce le nom, prénom de l'intervenant
   */
  const renderBasicInfos = () => (
    <div>
      {ContributorService.getCivilityCodeLabelWithSpace(contributor)}
      {contributor.lastname ? <b>{contributor.lastname} </b> : ''}
      {contributor.firstname ? `${contributor.firstname} ` : ''}
    </div>
  )

  /**
   * Affiche les informations de l'entreprise
   */
  const renderCompanyInfo = () => (
    <div className='d-flex flex-wrap'>
      <b className='mr-1' title={contributor.fullCompanyName || contributor.companyName}>
        {contributor.fullCompanyName ? truncateCompanyFullName(contributor) : contributor.companyName}
      </b>
      {contributor.legalForm
        ? isBo
          ? <p className='mb-0 mr-1'> - <span className='p-1 company-badge rounded font-weight-bold text-white'>{contributor.legalForm}</span></p>
          : ` - ${contributor.legalForm}`
        : ''}
      {contributor.siren
        ? isBo
          ? <p className='mb-0'> - <span className='p-1 siren-badge rounded font-weight-bold text-white'>{contributor.siren}</span></p>
          : ` - ${contributor.siren}`
        : ''}
      {contributor.siren && procedureType !== PROCEDURE_OFFICIAL_DOCUMENT.value &&
        <a
          className='px-2'
          target='_blank' rel='noopener noreferrer'
          href={`https://data.inpi.fr/entreprises/${contributor.siren}`}
        ><FormattedMessage id='rncs_link' />
        </a>}
      {contributor?.personFormation &&
        <span><FormattedMessage id='contributor_person_formation_start_acting' />
          <b className='font-italic'>{contributor.companyNameFormation}</b>,&nbsp;
          {(contributor?.descriptionFormation || contributor?.legalFormFormation)
            ? <span>{contributor?.descriptionFormation || contributor?.legalFormFormation}</span>
            : <FormattedMessage id='legal_status_formation_society_label' />}
          <FormattedMessage id='contributor_person_formation_end' />
        </span>}
    </div>
  )

  /**
   * @param intervenant
   */
  const truncateCompanyFullName = (intervenant: Contributor) => {
    if (intervenant?.fullCompanyName?.length > 90) {
      return `${intervenant.fullCompanyName?.slice(0, 90)}...`
    } else {
      return intervenant.fullCompanyName
    }
  }

  /**
   * Render les informations principales de l'intervenant
   */
  const renderPrimaryInfos = () => {
    if (contributor?.type === PERSONNE_PHYSIQUE.value) {
      if (contributor?.personFormation) {
        return (
          <div>
            {renderLegalStatusFormationForPP()}
          </div>
        )
      } else if (contributor?.companyName) {
        return (
          <div>
            {renderBasicInfos()}
            {renderCompanyInfo()}
          </div>)
      } else {
        return (
          <div>
            {renderBasicInfos()}
          </div>
        )
      }
    } else {
      return (
        <div>
          {renderBasicInfos()}
          {renderCompanyInfo()}
        </div>
      )
    }
  }

  const renderLegalStatusFormationForPP = () => {
    return (
      <div>
        {ContributorService.getCivilityCodeLabelWithSpace(contributor)}
        {contributor.lastname ? <b>{contributor.lastname} </b> : ''}
        {contributor.firstname ? <b>{contributor.firstname}</b> : ''}
        &nbsp;<FormattedMessage id='contributor_person_formation_start_acting' />
        <b className='font-italic'>{contributor.companyNameFormation}</b>,&nbsp;
        {(contributor?.descriptionFormation || contributor?.legalFormFormation)
          ? <span>{contributor?.descriptionFormation || contributor?.legalFormFormation}</span>
          : <FormattedMessage id='legal_status_formation_society_label' />}
        <FormattedMessage id='contributor_person_formation_end' />
      </div>
    )
  }

  const renderAddress = (address: Address, fromOtherAddress?: boolean) => {
    return (
      <>
        {address.building &&
          <div>
            {address.building}
          </div>}
        {address.label &&
          <div>
            {address.label ? address.label : ''}
          </div>}
        {address.state &&
          <div>
            {address.state ? address.state : ''}
          </div>}
        {address.complement &&
          <div>
            {address.complement}
          </div>}
        {(address.zipCode || address.city) &&
          <div>
            {address.zipCode ? `${address.zipCode} - ` : ''}{address.city ? ` ${address.city}` : ''}
          </div>}
        {contributor?.code !== CONTRIBUTOR_OLD_HOLDERS.value && fromOtherAddress
          ? <div><FormattedMessage id='contributor_frmi_other_address_france' /></div>
          : <>{contributor?.code !== CONTRIBUTOR_OLD_HOLDERS.value && countryName && <div>{countryName}</div>}</>}
      </>
    )
  }

  const renderContact = () => {
    return (
      <div>
        <div>{contributor.email && `${contributor.email}`}</div>
        <div>{contributor.phone && `${contributor.phone}`}</div>
      </div>
    )
  }

  const renderOtherInfos = () => {
    return (
      <div>
        {contributor.manageableQuality?.label &&
          <div className={`manageableQuality-underline ${status ? 'text-primary' : ''}`}>
            {contributor.manageableQuality?.label}
          </div>}
        {contributor.inscriptionNumber &&
          <div>

            <span>
              <FormattedMessage id='sso_account_inscription_number' />
            </span>

            {contributor.inscriptionNumber}
          </div>}
        {contributor.consentNotificationByEmail != null && (
          <span>
            <FormattedMessage id={contributor.consentNotificationByEmail ? 'contributor_email_consented' : 'contributor_email_not_consented'} />
          </span>
        )}
      </div>
    )
  }

  const renderBadge = () => {
    const stateLabel: IStateLabel | undefined = ETS_ACCOUNT_STATE_LABEL.find((stateLabel: IStateLabel) => stateLabel.state === status)
    return stateLabel && (
      <>
        <span className={`bg-origin-${contributor?.code?.toLowerCase()} badge badge-primary`}>
          <FormattedMessage id={getEtsAccountTranslatedCode(contributor?.code)} />
        </span>
        <span className={`${stateLabel.className} badge badge-primary`}>
          <FormattedMessage id={stateLabel.label} />
        </span>
      </>)
  }
  return !contributor ? <></> : (
    <IntlProvider locale='fr' messages={Message}>
      <div className={isBo ? 'row col-11' : 'row contributor-text'}>
        <div className={isBo ? 'col-8 pl-0' : 'col-12'}>
          <div>
            {renderPrimaryInfos()}
            {renderAddress(address, false)}
            {fromFrmiExtension && contributor.otherAddress && renderAddress(contributor.otherAddress, true)}
            {((fromFrmiExtension && contributor.type === PERSONNE_PHYSIQUE.value) || isDepositDepositor(procedureType, contributorType)) && contributor.nationality && <div><FormattedMessage id='contributor_frmi_nationality' /> : {nationalityName ?? contributor.nationality}</div>}
            {fromFrmiExtension && isFirst && contributor.OMPILanguage && <div><FormattedMessage id='contributor_frmi_ompi_language' /> : {ompiLanguage ?? contributor.OMPILanguage}</div>}
            {fromFrmiExtension && isFirst && contributor.otherLanguage && <div><FormattedMessage id='contributor_frmi_ompi_second_language' /> : {otherLanguage ?? contributor.otherLanguage}</div>}
            {renderContact()}
            {renderOtherInfos()}
            {contributor?.createdAt &&
              <span><FormattedMessage id='intervenant_created_at' />{DateUtils.formatDateFr(contributor.createdAt)}</span>}
            {isBo && isAdministration && isFirst &&
              <span className='font-weight-bold '><FormattedMessage id='contributor_is_administration' /></span>}
          </div>
          <div>
            {contributor?.isRecipient && !isRecord &&
              <span className='text-secondary'><FormattedMessage id='intervenant_warning_is_recipient' /></span>}
          </div>
        </div>
        {status &&
          <div>
            {renderBadge()}
          </div>}
        {
          canBeBeneficiary &&
            (!beneficiaries || beneficiaries?.filter(benef => benef.idFromBeneficiary === contributor.id).length === 0) && permissionEdit &&
              <Permission
                name={permissionEdit}
                guard={() => !isReadOnly}
                yes={() =>
                  <div className='col'>
                    <SubmitButton
                      className='btn-link-primary font-weight-bold'
                      onClick={() => {
                        return handleIsBeneficiary && handleIsBeneficiary()
                      }}
                    >
                      <FontAwesomeIcon icon={faStar} />
                      <FormattedMessage id='contributor_add_beneficiary' />
                    </SubmitButton>
                  </div>}
              />
        }
      </div>
    </IntlProvider>
  )
}

export default OverviewContributor
