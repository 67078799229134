const Message = {
  logo_alt: 'Logo Inpi',
  module_name: 'Front-office',
  module_service: 'Marques',

  required_field: 'Champ obligatoire',
  required_field_long: 'un champ obligatoire n\'est pas renseigné',
  required_area: '* Zone obligatoire',
  message_length_excess: 'Le nombre de caractères maximal pour ce champs (espaces inclus) est de ',
  error_email_format: 'L\'email renseigné n\'est pas au bon format',
  error_phone_format: 'Numéro de téléphone invalide',
  placeholder_select: 'Sélectionner ...',
  placeholder_select_multiple: 'Sélectionnez vos fichiers',
  placeholder_search: 'Rechercher par référence client, numéro national ou email du signataire ...',
  help_search_no_result: 'Vous avez la possibilité de rechercher en fonction des critères suivants : référence client, numéro national ou email du signataire.',
  download_label: 'Télécharger',
  max_file_limit_exceeded: 'Nombre de fichiers maximum : ',
  reset_button: 'Réinitialiser',
  intervenant_error_api_error: 'Service momentanément indisponible',

  common_add: 'Ajouter',
  common_validate: 'Valider',
  common_continue: 'Continuer',
  common_send: 'Envoyer',
  none_feminine: 'Aucune',
  none: 'Aucun',
  common_delete: 'Supprimer',
  field_email_consent_recap: 'Je consens à recevoir les notifications exclusivement par courrier électronique',
  common_error: 'Une erreur est survenue',
  common_yes: 'Oui',
  common_no: 'Non',
  common_select_all: 'Tout sélectionner',
  common_unselect_all: 'Tout désélectionner',
  // Login
  login_username_label: 'Email',
  login_username_placeholder: 'Email',
  login_password_label: 'Mot de passe',
  login_password_placeholder: 'Mot de passe',
  login_connexion_button_label: 'Connexion',
  login_invalid_couple: 'Le couple identifiant / mot de passe est inconnu',

  // Not Found
  page_not_found_title: '404',
  page_not_found_description: 'La page que vous recherchez est introuvable.',

  logout_hover: 'Déconnexion',
  help_hover: 'Aide',
  contact_hover: 'Envoyer un message à l\'INPI',
  user_hover: 'Mon compte',

  // Buttons
  button_cancel: 'Annuler',
  button_return: 'Retour',
  button_next_step: 'Étape suivante',
  button_previous_step: 'Étape précédente',
  button_confirm: 'Confirmer',
  button_edit: 'Modifier',
  button_example: 'Voir un exemple',
  button_select: 'Sélectionner',
  button_guide: 'Mode guidé',
  button_more_text: 'Voir plus',
  button_less_text: '- Voir moins d\'options',
  button_more: 'Voir plus',
  button_find_file: 'Choisir un fichier',
  label_button_switch_yes: 'OUI',
  label_button_switch_no: 'NON',
  button_save: 'Enregistrer',
  button_know_more: 'En savoir plus',
  button_more_information: 'Plus d\'informations',
  select_one_title: 'Sélection du titre',

  // mode guidé
  guided_mode_active: 'Vous êtes actuellement en mode guidé.',
  guided_mode_not_active: 'Vous êtes actuellement en mode expert.',
  switch_guided_mode_contributor: 'Modification du mode guidé',
  switch_guided_mode_contributor_text: 'Attention : la modification d\'un intervenant est en cours. Les données de cet intervenant seront supprimées. Souhaitez-vous continuer ?',

  // mode guidé messages bloquants
  guided_mode_depositors_multiple_types: 'Les déposants ajoutés sont des personnes morales et physiques',

  breadcrumb_home: 'Accueil',
  breadcrumb_new_depot: 'Déposer, diviser ou transformer une marque',
  breadcrumb_request_official_documents: 'Demande de documents officiels',
  breadcrumb_new_division: 'Diviser une marque',
  breadcrumb_addresses_book: 'Carnet d\'adresses',
  breadcrumb_modify_addresses_book: 'Modification d\'un contact',
  breadcrumb_add_addresses_book: 'Ajout d\'un contact',
  breadcrumb_attachment_new_agent: 'Nouveau compte',
  breadcrumb_attachment_new_holder: 'Nouveau titulaire',
  breadcrumb_attachment_request_update_agent_infos: 'Modifier mon compte',
  breadcrumb_attachment_request_remove_agent: 'Détachement compte',
  breadcrumb_attachment_request_attach_pm: 'Modification dossiers',
  breadcrumb_attachment_account_request_credentials: 'Codes d\'accès',
  breadcrumb_attachment_tmc_request_credentials: 'Titulaire d\'une marque contestée',

  // Listes
  procedure_type_label: 'Type de procédure',
  contributor_label: 'Nom / Raison sociale',
  basket_draft_subtitle: 'Seules les demandes mises à jour dans les {draftDisplayPeriodValue} derniers jours sont affichées.',
  button_export_transaction_list: 'Télécharger la liste',

  // Header des corbeilles
  home_filter_header_label_home: 'Accueil',
  home_filter_header_label_deposit: 'Dépôt, division',
  home_filter_header_label_opposition: 'Opposition, nullité, déchéance',
  home_filter_header_label_inscription: 'Inscription, renonciation',
  home_filter_header_label_frmi: 'Déposer et gérer des marques étendues à l\'international',
  home_filter_header_label_renewal: 'Renouvellement de marque',
  home_filter_header_label_official_documents: 'Documents officiels',
  home_filter_header_label_revocation_statement: 'Relevé de déchéance',

  // Titre de l'accueil
  home_title_home: 'Bienvenue sur le portail des Marques',
  home_title_deposit: 'Dépôt, division',
  home_title_opposition: 'Opposition, nullité, déchéance',
  home_title_inscription: 'Inscription, renonciation',
  home_title_frmi: 'Déposer et gérer des marques étendues à l\'international',
  home_title_renewal: 'Renouveler sa marque',
  home_title_official_documents: 'Documents officiels',
  home_title_revocation_statement: 'Relevé de déchéance',

  // Sous-titre de l'accueil
  home_sub_title_home: 'Déposer, renouveler ou inscrire vos marques et suivre l\'avancement de vos démarches',
  home_sub_title_deposit: 'Déposer, diviser vos marques et suivre l\'avancement de vos démarches',
  home_sub_title_opposition: 'Opposer vos marques et suivre l\'avancement de vos démarches',
  click_on_headers_to_search: 'Pour trier, cliquer sur les en-têtes de colonne.',
  home_sub_title_inscription: 'Inscrire, rectifier et renoncer',
  home_sub_title_frmi: 'Déposer et gérer des marques étendues à l\'international et suivre l\'avancement de vos démarches',
  home_sub_title_renewal: 'Renouveler vos marques et suivre l\'avancement de vos démarches',
  home_sub_title_official_documents: 'Demander des documents officiels et suivre l\'avancement de vos démarches',
  home_sub_title_revocation_statement: 'Relevé de déchéance',

  // Boutons de l'accueil
  home_button_depot: 'Dépôt de marque',
  home_button_division: 'Division',
  home_button_opposition: 'Opposition',
  home_button_observations: 'Déposer des observations',
  home_button_nullity: 'Nullité',
  home_button_revocation: 'Déchéance',
  home_button_official_documents: 'Demande de documents officiels',
  home_button_frmi: 'Extension à l\'international',
  home_button_frmi_post_operations: 'Opérations postérieures',
  home_button_renewal: 'Renouvellement de marque',
  home_button_revocation_statement: 'Relevé de déchéance',

  third_party_observation: 'Observation de tiers',
  observation_cg: 'Observations portant sur le règlement d’usage d’une marque collective ou de garantie',
  numnat_brand: 'Numéro de la marque',
  type_observations: 'Type d\'observations',
  request_add_observations_success: 'Les documents ont bien été envoyés',
  brand_not_exist: 'La marque saisie n\'existe pas',
  brand_depositors_same_as_user: 'Vous ne pouvez pas déposer une observation sur cette marque car vous en êtes le déposant ',
  home_button_inscription_act: 'Inscription',
  home_button_waiver: 'Renonciation',

  // Corbeilles
  basket_label_draft: 'Projets et sauvegardes',
  basket_label_under_review: 'Dossiers en cours d\'examen (y compris marques internationales)',
  basket_label_empty_under_review: 'Dossiers en cours d\'examen (y compris marques internationales désignant la France)',
  basket_label_publication_in_progress: 'Publications en cours',
  basket_label_publish: 'Demandes publiées',
  basket_label_suspended: 'Demandes suspendues',
  basket_label_rejected: 'Demandes rejetées, retirées ou irrecevables',
  basket_label_with_notifications: 'Boîte de réception',
  basket_label_my_brands: 'Mes titres (y compris marques internationales désignant la France)',
  basket_label_finish: 'Terminés ou enregistrés OMPI',
  basket_label_all: 'Résultats de recherche',
  basket_label_pending_notifications: 'Nouvelles notifications',
  basket_label_registering: 'Enregistrement en cours',
  basket_label_pending_notifications_ompi: 'Nouvelles notifications OMPI',
  basket_label_under_review_ompi: 'Dossiers en cours d\'examen OMPI',
  basket_label_provided_documents: 'Documents officiels fournis par l\'INPI ou l\'OMPI',
  basket_label_archived: 'Demandes archivées',
  popin_delete_draft_title: 'Êtes-vous sûr de vouloir supprimer cette demande ?',
  popin_delete_all_draft_title: 'Êtes-vous sûr de vouloir supprimer tous les projets et sauvegardes ?',
  button_delete_draft: 'Supprimer la demande',
  button_delete_all_draft: 'Supprimer les projets et sauvegardes',
  title_delete_all_draft: 'Tout supprimer',

  // Mon compte
  my_profile: 'Mon compte',
  my_profile_subtitle: 'Voir les informations de mon compte SSO et gérer mes adresses de correspondance.',
  my_profile_email_title: 'Mes adresses de correspondance et préférences de consentement (jusqu\'à 3 adresses)',
  my_profile_email_add: '+ Ajouter une nouvelle adresse',
  my_profile_confirm_emails: 'Envoyer une confirmation à ces mails',
  my_profile_confirm_emails_sent: 'Un mail de confirmation a bien été envoyé à vos adresses mails de correspondance.',
  my_profile_confirm_emails_error: 'Une erreur est survenue lors de l\'envoi des mails à vos adresses de correspondance',
  my_profile_consent_preferences: 'Je consens à ce que les notifications liées à mon compte soient par défaut envoyées par courrier électronique',
  my_profile_success: 'Adresses de correspondance mises à jour',
  my_profile_correspondance_address_save_error: 'Veuillez sauvegarder avant d\'envoyer le mail de confirmation',

  // Accès ets
  ets_account_request_ask_for_access: 'Demander ou modifier un accès',
  ets_account_request_credentials: 'J\'ai reçu un code d\'accès',
  ets_account_request_new_agent: 'Je suis nouveau mandataire',
  ets_account_request_new_holder: 'Je ne souhaite plus être représenté par un mandataire',
  ets_account_request_new_holder_ond: 'Pour les oppositions, nullités et déchéances, merci de cliquer <a>ici.</a>',
  ets_account_request_update_agent_infos: 'Je suis mandataire et mes informations ont changé',
  ets_account_request_remove_agent: 'Je ne suis plus mandataire',
  ets_account_request_attach_pm: 'Je modifie les dossiers attachés à une personne morale',
  ets_account_request_tmc: 'Je suis le titulaire d\'une marque contestée',

  ets_account_request_credentials_code: 'Code',
  ets_account_request_credentials_password: 'Mot de passe',

  ets_account_request_procedure_label: 'Type de procédure',
  procedure_type_mifr_ets: 'Enregistrement international',
  ets_account_request_contributor_type_label: 'J\'agis en qualité de',
  ets_account_request_code_label: 'Quelle partie représentez vous ?',
  ets_account_request_code_placeholder: 'Choisissez une valeur',
  ets_account_request_procedure_numNat_undefined: 'N° national / n° de publication',
  ets_account_request_procedure_numNat_deposit: 'N° national / n° de publication',
  ets_account_request_procedure_numNat_division: 'N° de gestion',
  ets_account_request_procedure_numNat_mifr: 'N° enregistrement international',
  ets_account_request_procedure_numNat_opposition: 'N° de gestion',
  ets_account_request_procedure_numNat_nullity: 'N° de gestion',
  ets_account_request_procedure_numNat_revocation: 'N° de gestion',
  ets_account_request_procedure_numNat_tmc: 'Numéro de la demande',
  ets_account_request_procedure_numNat_inscription: 'N° de gestion',
  ets_account_request_procedure_numNat_frmi: 'N° de gestion',
  ets_account_request_procedure_numNat_official_document: 'N° de gestion',
  ets_account_request_procedure_numNat_renewal: 'N° de gestion',
  ets_account_request_procedure_numNat_revocation_statement: 'N° de gestion',
  ets_account_request_procedure_contested_request_numNat: 'N° national de la marque contestée',
  ets_account_request_inscription_number: 'Numéro',
  ets_account_request_power_file: 'Bon pour pouvoir',
  ets_account_request_proof_file: 'Joindre un fichier',
  ets_account_request_is_recipient_text: 'Je serai le destinataire uniquement des correspondances afférentes à la procédure sélectionnée.{br}{br}',
  ets_account_request_is_recipient_info_text: 'Si je souhaite être destinataire des correspondances pour toute autre procédure dans la vie de le marque, je dois procéder à une inscription relative au mandataire (<a>suivre ce lien</a>).',
  ets_account_request_success_request: 'Votre demande de rattachement a bien été prise en compte.',
  ets_account_request_success_request_detach: 'Votre demande de détachement a bien été prise en compte.',

  ets_account_request_pm_current_account: 'Compte actuel',
  ets_account_request_pm_picker: 'Attacher les dossiers à une autre personne',
  ets_account_request_pm_picker_button: 'Assigner la sélection',
  ets_account_request_pm_new_account: 'Assigner au compte',
  ets_account_request_pm_no_account: 'A noter : une première connexion au portail Marques est nécessaire pour apparaître dans la liste.',

  // Erreurs WS
  error_transaction_create: 'Erreur lors de la création du dépôt',
  error_transaction_edit: 'Erreur lors de la modification du dépôt',
  error_transaction_document_create: 'Erreur lors de l\'enregistrement des fichiers, veuillez réessayer',
  error_transaction_document_add: 'Il existe déjà un document avec ce nom',
  error_transaction_document_delete: 'Erreur lors de la suppression de l\'un des fichiers',
  error_payment_create: 'Erreur lors du paiement',
  error_payment_already_exist: 'Un paiement validé est en cours de traitement pour cette transaction. La régularisation devrait intervenir automatiquement dans un délai de 2 heures. Si ce n\'était pas le cas, vous pouvez prendre contact avec INPI-Direct au +33(0)1 56 65 89 98',
  error_payment_notification_already_exist: 'Un paiement validé est en cours de traitement pour cette notification. La régularisation devrait intervenir automatiquement dans un délai de 2 heures. Si ce n\'était pas le cas, vous pouvez prendre contact avec INPI-Direct au +33(0)1 56 65 89 98',
  error_products_and_services_exemple_file: 'Erreur lors de la récupération du fichier d\'exemple',
  error_tmclass_validation: 'La validation TMClass n\'est pas disponible',
  error_transaction_document_too_large: 'L\'un de fichiers que vous essayez d\'enregistrer est trop lourd',

  // Documents officiels
  official_documents_selection_title: 'Documents officiels de Marques',
  official_documents_type_title: 'Type de document',
  select_official_documents_type_title: 'Sélection du document',
  official_documents_type_description: 'Cette rubrique vous permet de sélectionner les documents officiels que vous souhaitez obtenir.',
  official_documents_type_placeholder: 'Sélectionner ...',
  official_documents_type_postal_route: 'Envoyer par courrier postal',
  official_documents_type_pdf_portal_route: 'PDF certifié numériquement déposé sur mon portail',
  official_documents_sending_mode_title: 'Type d\'envoi',
  official_documents_type_das_route: 'Envoyer par DAS',
  official_documents_type_label: 'Type de document',
  official_documents_type_processing_delay_label: 'Le délai de traitement est de',
  official_documents_type_is_administration: 'Je suis une administration',
  official_documents_type_email_label: 'Adresse email',
  official_documents_type_secondary_email_label: 'Adresse email secondaire',
  official_documents_type_email_placeholder: 'Adresse email ...',
  official_documents_type_secondary_email_placeholder: 'Adresse email secondaire...',
  official_documents_type_file_label: 'Justificatif',
  official_documents_type_file_button_label: 'Choisir un fichier',
  official_documents_type_required_fields: '',
  official_documents_type_required_type: 'Le type de document est obligatoire',
  official_documents_type_required_send: 'Le type d\'envoi est obligatoire',
  official_documents_type_required_email: 'L\'adresse email est obligatoire',
  official_documents_type_invalid_email: 'L\'adresse email n\'est pas valide',
  official_documents_type_required_title: 'La marque est obligatoire',
  official_documents_type_required_documents: 'Un justificatif est obligatoire',
  deposit_type_official_document_national_number_label: 'N° national',
  deposit_type_official_document_deposit_date: 'Date de dépôt',

  // Marques:
  transaction_num: 'Numéro',
  transaction_lastUpdate: 'Date de modification',
  transaction_arrivalDate: 'Date de dépôt',
  transaction_type: 'Type',
  sub_procedure_deposit_type_national: 'Française',
  sub_procedure_deposit_type_guarantee: 'Garantie',
  sub_procedure_deposit_type_collective: 'Collective',
  sub_procedure_deposit_type_european: 'Union européenne',
  sub_procedure_deposit_type_international: 'International',
  sub_procedure_deposit_type_division: 'Division',

  sub_procedure_abbreviation_deposit_type_national: 'FR',
  sub_procedure_abbreviation_deposit_type_guarantee: 'MG',
  sub_procedure_abbreviation_deposit_type_collective: 'MC',
  sub_procedure_abbreviation_deposit_type_european: 'TMUE',
  sub_procedure_abbreviation_deposit_type_international: 'TMI',
  sub_procedure_abbreviation_deposit_type_division: 'DIV',
  transaction_brand_model: 'Marque',

  // Dépot Marques
  depot_step_list_title: 'Déposer, diviser ou transformer une marque',

  // Dépôt formulaire : types de dépot
  select_depot_type_title: 'Type de demande',
  deposit_type_title: 'Type de demande',
  deposit_type_description: 'Cette rubrique vous permet de sélectionner le type de demande de marque française que vous souhaitez effectuer.',
  disable_guided_mode_to_see_brand_types: 'Passez en mode expert pour voir tous les types de demandes.',

  // Division formulaire
  division_step_list_title: 'Diviser une marque',

  select_division_brand_title: 'Marque à diviser',
  division_brand_title: 'Marque à diviser',
  division_brand_description: 'Cette rubrique vous permet d\'identifier la marque à diviser.',

  // Inscription formulaire
  select_inscription_title: 'Inscription',
  inscription_type_title: 'Type d\'inscription',
  inscription_brand_title: 'Sélection des titres',
  inscription_type_description: 'Cette rubrique vous permet de sélectionner le type d\'inscription que vous souhaitez effectuer.',
  select_inscription_brand_title: 'Sélection des titres',
  inscription_brand_description: 'Cette rubrique vous permet d\'identifier la ou les marque(s) concernée(s) par la demande d\'inscription.',
  inscription_agent_attachment_title: 'Attachement de mandataire',
  inscription_agent_attachment_description: 'Vous devez choisir les transactions auxquelles vous souhaitez vous rattacher en tant que mandataire',
  inscription_attachment_table_title: 'Transactions auxquelles je souhaite me rattacher',

  // Formulaire Inscription : Selection des titres
  inscription_add_title: 'Ajouter un titre',
  button_modify_title: 'Modifier le titre',
  button_add_title: 'Ajouter le titre',
  titles_column_label_id: '#',
  titles_column_label_numNat: 'N° National ou d\'enregistrement',
  titles_column_label_international_numNat: 'N° International',
  titles_column_label_date: 'Date de dépôt ou d\'enregistrement',
  titles_column_label_text: 'Modèle de la marque',
  titles_column_label_type: 'Type de marque',
  titles_column_label_origin: 'Origine',
  titles_column_label_product_classes: 'Classes',
  inscription_import_titles: 'Charger une liste de titres',
  inscription_titles_identic_numnat_message: 'Des titres dans la liste possèdent le même numéro national ou d\'enregistrement',
  inscription_import_titles_explain: 'Charger une liste de titres. Le fichier à charger doit impérativement être au format csv. Le nombre de titres pour une demande électronique ne peut excéder 1000.',
  inscription_import_titles_label: 'Liste de titres',
  inscription_import_titles_success: 'La liste des titres a bien été ajoutée à l\'inscription',
  inscription_import_titles_error: 'Le fichier uploadé contient des erreurs',
  inscription_titles_example_file: 'Titres-exemple.csv',
  inscription_titles_download_csv: 'Télécharger la liste de titres',

  // Formulaire FRMI
  frmi_brand_title: 'Vos marques',
  select_frmi_brand_title: 'Vos marques',
  select_frmi_country_title: 'Pays',
  select_frmi_country_missing: 'Veuillez sélectionner au moins un pays',
  frmi_brand_description: 'Cette rubrique vous permet d\'identifier la ou les marque(s) concernée(s) par la demande d\'extension à l\'international',
  frmi_brand_description_op: 'Cette rubrique vous permet d\'identifier la ou les marque(s) concernée(s) par l\'inscription',
  frmi_brand_reproduction_title: 'Reproduction de la marque',
  select_frmi_brand_reproduction_title: 'La marque internationale',
  frmi_brand_reproduction_description: 'Cette rubrique vous permet de saisir la reproduction de la marque à l\'international',
  frmi_colors_claimed_title: 'Revendication de couleurs',
  select_frmi_colors_claimed_title: 'Couleurs revendiquées',
  frmi_colors_claimed_description: 'Cette rubrique vous permet de déterminer les couleurs à revendiquer de la marque',
  frmi_various_indications_title: 'Indications diverses',
  select_frmi_various_indications_title: 'La marque',
  frmi_various_indications_description: 'Cette rubrique vous permet de saisir des indications diverses sur la marque',
  frmi_claimed_seniorities_title: 'Anciennetés revendiquées',
  frmi_claimed_seniorities_description: 'Vous pouvez revendiquer une ou plusieurs ancienneté(s)',
  frmi_products_and_services: 'Classes, produits et services',
  frmi_products_and_services_title: 'Classes, produits et services',
  frmi_products_and_services_description: 'Veuillez valider les produits et services issus de la marque française de base pour lesquels la demande d’enregistrement de la marque internationale est demandée.',
  frmi_op_products_and_services_description: 'Veuillez valider les produits et services issus de la marque internationale pour lesquels l\'inscription est demandée.',
  frmi_frmi_type_nullification_products_and_services_description: 'Veuillez valider les produits et services issus de la marque internationale pour lesquels l\'invalidation judiciaire est demandée.',
  frmi_limited_products_and_services: 'Limitation produits et services',
  frmi_limited_products_and_services_title: 'Limitation produits et services',
  frmi_limited_products_and_services_description: 'Vous pouvez limiter les produits et services qui sont désignés dans votre (vos) marque(s) de base.',
  frmi_post_operation_limited_products_and_services_description: 'Vous pouvez limiter les produits et services qui sont désignés dans votre marque internationale.',
  frmi_fees_and_taxes_title: 'Emoluments et taxes',
  frmi_fees_and_taxes_block_title: 'Emoluments et taxes',
  frmi_payment_method_block_title: 'Mode de paiement',
  frmi_fees_and_taxes_description: 'Cette rubrique vous permet de connaître le montant des émoluments et taxes dus à l\'OMPI et de choisir le mode de paiement',
  international_number_label: 'Numéro de la demande internationale à modifier',
  frmi_international_number_label: 'Numéro de la marque internationale qui fera l’objet de l’inscription',

  // Formulaire FRMi: titulaires
  contributor_holders_frmi_subtitle: 'Il peut y avoir un ou plusieurs titulaires de la marque. Il s\'agit du ou des propriétaires de la marque à étendre à l\'international.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_holders_frmi: 'Titulaire(s)',
  contributor_holders_frmi_post_operation: 'Titulaire(s)',
  contributor_holders_frmi_post_operation_subtitle: 'Il peut y avoir un ou plusieurs titulaires de la marque.',
  contributor_old_holders_frmi_post_operation: 'Titulaire(s) actuel(s)',
  contributor_old_holders_frmi_post_operation_subtitle: 'Il peut y avoir un ou plusieurs titulaires actuels de la marque.',
  contributor_agent_frmi_post_operation: 'Mandataire',
  contributor_agent_frmi_post_operation_subtitle: 'Il ne peut y avoir qu\'un mandataire.',
  contributor_old_agents_frmi_post_operation: 'Mandataire(s) actuel(s)',
  contributor_old_agents_frmi_post_operation_subtitle: 'Il peut y avoir un ou plusieurs mandataires actuels.',
  contributor_licensee_frmi_post_operation: 'Preneur de licence',
  contributor_licensee_frmi_post_operation_subtitle: 'Il peut y avoir qu\'un preneur de licence.',
  contributor_old_licensees_frmi_post_operation: 'Preneur(s) de licence actuel(s)',
  contributor_old_licensees_frmi_post_operation_subtitle: 'Il peut y avoir un ou plusieurs preneurs de licence actuels.',
  // Formulaire FRMI : Selection des titres
  titles_column_label_deposit_date: 'Date de dépôt',
  titles_column_label_registration_date: 'Date d\'enregistrement',
  titles_column_label_expiration_date: 'Date d\'expiration',
  titles_column_label_classes: 'Classes',
  warning_title_already_extended_frmi: '<b>Attention: La marque a déjà fait l\'objet d\'une demande d\'extension à l\'international. Pour tout renseignement, contacter INPI DIRECT au <tel>01.56.65.89.98</tel></b>',
  warning_title_already_extended_renewal: '<b>Attention: La marque a déjà fait l\'objet d\'une demande de renouvellement. Pour tout renseignement, contacter INPI DIRECT au <tel>01.56.65.89.98</tel></b>',

  // Formulaire FRMI : Selection des pays
  frmi_column_label_country: 'Pays',
  frmi_country_description: 'Cette rubrique vous permet de désigner les pays dans lesquels vous souhaitez étendre votre marque.',
  frmi_op_country_description: 'Cette rubrique vous permet de choisir les pays pour lesquels vous souhaitez que l\'inscription prenne effet.',
  frmi_country_text: 'Liste des pays à sélectionner : ',
  frmi_country_search_bar_placeholder: 'Tapez ici le nom du pays...',
  frmi_title_complementary_information_modal: 'Informations complémentaires',

  // Formulaire FRMI : Reproduction de la marque
  reproduction_brand_has_color_label: 'La reproduction de la marque est en couleur',
  reproduction_brand_has_standard_characters_label: 'Je souhaite que la marque soit considérée comme une marque en caractères standard',
  reproduction_brand_has_only_colors_label: 'La marque consiste exclusivement en une couleur ou une combinaison de couleurs en tant que telles, sans aucun élément figuratif.',
  reproduction_brand_has_only_colors_example_label: 'Exemples : le carré d’orange, le rouge de Ferrari.',
  reproduction_brand_subtitle: 'Si le logo ne s’affiche pas, ou s’il n’est pas conforme à l’attendu, fournissez-le au format JPG',

  // Formulaire FRMI : Couleurs revendiquées
  colors_claimed_is_color_distinctive_label: 'Souhaitez vous revendiquer la couleur comme élément distinctif de la marque ?',
  colors_claimed_colors_description_label: 'Couleurs ou combinaison de couleurs revendiquée',
  colors_claimed_description_label: 'Indication pour chaque couleur (selon les exigences de certaines parties contractantes désignées) des parties principales de la marque qui sont dans cette couleur',
  colors_claimed_no_color: 'Vous avez déclaré que la reproduction de la marque est en noir et blanc vous ne pouvez donc pas revendiquer de couleur.',
  colors_claimed_description_help_hover: 'Veuillez indiquer la couleur ou la couleur associée au Pantone, mais en aucun cas un Pantone seul',

  // Formulaire FRMI : Priorités renvendiquées
  frmi_priorities_claimed_title: 'Priorités revendiquées',
  frmi_priorities_claimed_description: 'Vous pouvez revendiquer une ou plusieurs priorités',
  button_modify_priority_frmi: 'Modifier la priorité',
  button_add_priority_frmi: 'Ajouter la priorité',
  priority_claimed_delete_modal_title: 'Supprimer une priorité renvendiquée ?',
  priority_claimed_delete_modal_text: 'Souhaitez-vous supprimer la priorité revendiquée ?',
  priority_frmi_deposit_date_required: 'La date de dépôt est obligatoire',
  priority_frmi_deposit_date_wrong_format: 'Champ date sous la forme JJ/MM/AAAA',
  priority_frmi_deposit_date_too_late: 'La date ne peut être postérieure à la date du jour',
  priority_frmi_record_not_found: 'Aucune marque n\'a été trouvée pour ce numéro national. Vous pouvez saisir un autre numéro national ou les informations manuellement.',

  // Formulaire FRMI : Indications diverses
  various_indications_transliteration_label: 'Translittération',
  various_indications_is_sound_type_label: 'Marque sonore',
  various_indications_is_3d_type_label: 'Marque tridimensionnelle',
  various_indications_is_collective_type_label: 'Marque collective, marque de certification ou marque de garantie',
  various_indications_verbal_elements_label: 'Eléments verbaux de la marque',
  various_indications_unclaimed_elements_label: 'Le déposant déclare qu\'il souhaite ne pas revendiquer la protection à l\'égard des éléments suivants de la marque',
  various_indications_description_label: 'Description de la marque figurant dans la demande de base ou l\'enregistrement de base, le cas échéant (utilisez uniquement cet élément si le bureau d\'origine vous oblige à inclure cette description dans la demande internationale aux fins de certification)',
  various_indications_voluntary_description_label: 'Description volontaire de la marque (toute description de la marque par des mots, y compris la description contenue dans la demande de base ou l\'inscription, si vous n\'étiez pas tenu de fournir cette description à l\'élément)',
  various_indications_description_part_label: 'Description de la marque (selon certaines parties contractantes désignées, par exemple, les Etats-Unis d\'Amérique) :',
  various_indications_translation_part_label: 'Traductions',
  various_indications_has_translation_label: 'Les termes contenus dans la marque n\'ont pas de signification',
  various_indications_english_translation_label: 'Anglais',
  various_indications_spanish_translation_label: 'Espagnol',
  various_indications_french_translation_label: 'Français',
  various_indications_form_translation_text_error: 'Veuillez renseigner au moins un des trois champs ci-dessus.',

  // Formulaire FRMI : Revendication d'ancienneté
  seniority_label_number: 'Ancienneté n°{nb}',
  seniority_registered_date: 'Date d\'enregistrement',
  button_add_seniority: 'Ajouter l\'ancienneté',
  button_modify_seniority: 'Modifier l\'ancienneté',
  button_add_new_seniority: 'Ajouter une ancienneté',
  seniority_deposit_date_required: 'La date de dépôt est obligatoire',
  seniority_registered_date_required: 'La date d\'enregistrement est obligatoire',
  seniority_deposit_date_wrong_format: 'Champ date sous la forme JJ/MM/AAAA',
  seniority_deposit_date_too_late: 'La date ne peut être postérieure à la date du jour',
  transaction_frmi_french_registration_number_type_placeholder: 'Choisissez votre numéro de dépôt',
  seniority_claimed_delete_modal_text: 'Souhaitez-vous supprimer l\'ancienneté revendiquée ?',
  seniority_claimed_delete_modal_title: 'Supprimer une ancienneté renvendiquée',

  // Formulaire FRMI : Commentaires
  frmi_comments_title: 'Commentaires',
  frmi_comments_description: 'Commentaires à destination de l\'Institut National de la Propriété Industrielle (INPI)',
  frmi_comments_placeholder: 'Commentaires',

  // Formulaire FRMI: Limitation produits et services
  options_columns_label_classes_products_and_services: 'Classes, produits et services',
  limited_products_services_limitation_number: 'Limitation n°{nb}',
  limited_products_services_limitation_countries: 'Pays sur lesquels la limitation porte',
  limited_products_services_limitation_countries_placeholder: 'Sélectionnez un ou plusieurs pays',
  limited_products_services_add_limitation: 'Ajouter une limitation',
  limited_products_services_validate_limitation: 'Ajouter la limitation',
  limited_products_services_modify_limitation: 'Modifier la limitation',
  limited_products_services_missing_country: 'Veuillez sélectionner au moins un pays',

  // Formulaire FRMI : Emoluments et taxes
  fees_and_taxes_account_holder: 'Titulaire du compte',
  fees_and_taxes_account_number: 'Numéro du compte',
  fees_and_taxes_instructions_author: 'Identité de l\'auteur des instructions',
  fees_and_taxes_payment_author: 'Identité de l\'auteur du paiement',
  fees_and_taxes_ompi_receipt_number: 'Numéro de quittance de l\'OMPI',
  fees_and_taxes_payment_references: 'Références du paiement',
  fees_and_taxes_payment_transfer_date: 'Date du virement',
  fees_and_taxes_payment_receipt: 'Numéro de quittance de l\'OMPI',
  fees_and_taxes_payment_proof_description: 'Vous devrez téléverser la preuve du paiement des taxes sur le compte du Bureau International (copie du virement, quittance auprès de l’OMPI, ...).',
  fees_and_taxes_payment_ompi_page_description: '<a>Cliquez ici pour afficher une page du site de l\'OMPI qui contient leurs coordonnées bancaires.</a>',
  fees_and_taxes_payment_ompi_page_link: 'https://www.wipo.int/finance/fr/madrid.html',
  fees_and_taxes_payment_method_missing: 'Veuillez sélectionner au moins un mode de paiement',
  fees_and_taxes_total_amount: 'TOTAL (en Francs Suisses) : ',
  fees_and_taxes_total_amount_unit: 'CHF',
  fees_and_taxes_payment_proof_document_missing: 'Vous devez ajouter un justificatif de paiement à l\'OMPI',

  // Formulaire FRMI : Récap
  overview_frmi_seniorities_title: 'Ancienneté(s)',
  overview_frmi_intervenants_title: 'Intervenant(s)',
  overview_frmi_brand_reproduction_title: 'Reproduction de la marque',
  overview_frmi_countries_title: 'Pays',
  overview_frmi_priorities_title: 'Priorité(s)',
  overview_frmi_taxes_title: 'Emoluments et taxes',
  overview_frmi_colors_title: 'Couleur(s) revendiquée(s)',
  overview_frmi_various_indications_title: 'Indications diverses',
  overview_frmi_products_and_services_title: 'Classes, produits et services',
  overview_frmi_limit_products_and_services_title: 'Limitation produits et services',
  overview_frmi_comment_title: 'Commentaire',
  overview_frmi_volunteer_brand_description: 'Description volontaire de la marque',
  overview_frmi_color_indication: 'Indication pour chaque couleur',
  overview_frmi_english_translation: 'Traduction anglaise',
  overview_frmi_spanish_translation: 'Traduction espagnole',
  overview_frmi_french_translation: 'Traduction française',
  countries_column_label_id: '#',
  countries_column_label_code: 'Code',
  countries_column_label_name: 'Nom',
  countries_column_label_info: 'Informations',
  colors_column_label_value: 'Valeur',
  colors_column_label_name: 'Nom',
  colors_column_label_field: 'Champ',
  frmi_overview_standard_characters: 'Marque en caractères standards',
  frmi_overview_colors: 'Reproduction de la marque en couleurs',
  frmi_overview_color_combination: 'La marque consiste exclusivement en une couleur ou une combinaison de couleurs',
  frmi_overview_distinctive_colors: 'Couleurs distinctives',
  frmi_overview_claimed_colors_table_title: 'Couleurs revendiquées',
  frmi_overview_licence_type: 'Type de licence',
  frmi_overview_effect_date_application_in_suit: 'Demande en cause',
  various_indications_field_name: 'Propriété',
  various_indications_field_value: 'Valeur',
  various_indications_field_label_sound_type: 'Marque sonore',
  various_indications_field_3_dimensional: 'Marque tridimensionnelle',
  various_indications_field_collective_certification: 'Marque collective, de certification ou de garantie',
  various_indications_field_label_transliteration: 'Translittération',
  various_indications_field_verbal_elements: 'Elements verbaux',
  various_indications_field_unclaimed_elements: 'Elements non réclamés',
  various_indications_field_description: 'Description',
  various_indications_field_voluntary_description: 'Description volontaire',
  various_indications_field_translation: 'Traduction française',
  various_indications_field_english_translation: 'Traduction anglaise',
  various_indications_field_spanish_translation: 'Traduction espagnole',

  // Formulaire Opération postérieures FRMI
  frmi_post_operation_types_title: 'Opérations postérieures',
  frmi_post_operation_types_description: 'Veuillez choisir le type d\'opération postérieure',
  frmi_post_operation_types_frmi_type_post_designation_conversion_modal_text: 'Ce demande doit être présentée au Bureau international par l\'intermédiaire de l\'Office de l\'Union européenne : l\'Office du l\'Union européenne pour la propriété intellectuelle (EUIPO).',
  frmi_post_operation_types_frmi_type_continuation_request_modal_text: 'Le formulaire MM20 doit être soumis directement à l\'OMPI',
  frmi_licence_duration_label: 'Durée de la licence',
  frmi_designation_various_indications_title: 'Indications diverses',
  frmi_licence_various_indications_title: 'Indications diverses',

  frmi_title_title: 'L\'enregistrement international',
  frmi_titles_title: 'Les enregistrements internationaux',
  frmi_contributors_title: 'Intervenants',
  frmi_old_holders_title: 'Titulaires actuels',
  frmi_countries_title: 'Pays',
  frmi_recipient_title: 'Destinataire',
  frmi_signatory_title: 'Signataire',
  frmi_holders_title: 'Titulaire(s)',
  frmi_products_title: 'Produits et services',
  frmi_products_brand_title: 'Produits et/ou services auxquels s\'appliquera la marque',
  frmi_products_partial_title: 'Produits et/ou services concernés par l\'inscription',
  frmi_products_radiation_title: 'Produits et services auxquels s\'appliquera la marque',
  frmi_replacement_products_title: 'Produits et services concernés par le remplacement',
  frmi_designation_application_date_title: 'Date d\'effet de la désignation postérieure',
  frmi_designation_application_date_description: 'Veuillez renseigner les informations concernant la date d\'effet de votre demande',
  frmi_effect_date_informations_label: 'Indiquez la demande en cause',
  frmi_application_date_title: 'Date d\'effet',
  frmi_taxes_title: 'Emoluments et taxes',
  frmi_agent_title: 'Mandataire',
  frmi_licensees_title: 'Preneur de licence',
  frmi_licence_write_off_title: 'Licence à radier',
  frmi_licence_edition_title: 'Licence à modifier',
  frmi_facts_summary_title: 'Résumé des faits',
  frmi_facts_summary_description: 'Veuillez compléter le résumé en remplaçant les pointillés',
  frmi_additional_information_title: 'Informations complémentaires',
  frmi_decisions_title: 'Faits et décisions',
  frmi_decisions_title_text: 'Faits et décisions qui ont une incidence sur la demande de base, sur l\'enregistrement qui en est issu ou sur l\'enregistrement de base et date à partir de laquelle les faits et décisions indiqués produisent leurs effets :',
  frmi_decision_effect_date_label: 'Date à partir de laquelle des faits et décisions produisent leurs effets',
  frmi_ompi_effect_date_label: 'Date à laquelle l\'invalidation prend effet (le cas échéant)',
  frmi_replacement_titles_title: 'Informations concernant l\'enregistrement',
  frmi_replacement_titles_subtitle: 'Informations concernant l\'enregistrement national(aux) ou régional(aux) remplacé(s) par l’enregistrement international',
  frmi_facts_restriction_label: 'Résumé des faits principaux relatifs à la restriction',
  frmi_facts_deletion_label: 'Résumé des faits principaux relatifs à la main levée de la restriction',
  frmi_facts_deletion_start_text: 'Il convient d\'inscrire la main levée de nantissement concernant le nantissement',
  frmi_facts_date_text: 'en date du',
  frmi_facts_contributor_text: 'en faveur de',
  frmi_facts_deletion_registration_date_text: 'inscrit le',
  frmi_facts_restriction_start_text: 'Le droit du titulaire de disposer du ou des enregistrements internationaux susmentionnés a été restreint dans les parties contractantes indiquées. Ladite restriction résulte ',
  frmi_facts_restriction_act_text: 'd\'un acte de',
  frmi_ompi_reference_number_label: 'Numéro de référence OMPI',
  frmi_ompi_notification_number_label: 'Numéro de notification OMPI',
  frmi_correction_decription_label: 'Description de la rectification demandée',
  frmi_invalidation_authority_label: 'Autorité qui a prononcé l\'invalidation',
  frmi_effect_stop_office_name_label: 'Office qui envoie la notification',
  frmi_nullification_office_name_label: 'Nom de l\'office',
  frmi_notification_date_label: 'Date de notification de l\'enregistrement international',
  frmi_nullification_effect_date_label: 'Date à laquelle l\'invalidation prend effet (le cas échéant)',
  frmi_nullification_date_label: 'Date à laquelle l\'invalidation a été prononcée',
  frmi_replacement_office_name_label: 'Nom de l\'office (INPI)',
  frmi_deposit_number_label: 'Numéro de dépôt',
  frmi_deposit_date_label: 'Date de dépôt',
  frmi_registered_number_label: 'Numéro d\'enregistrement',
  frmi_registered_date_label: 'Date d\'enregistrement',
  frmi_priority_date_label: 'Date de priorité',
  frmi_other_right_text_label: 'Autre droit acquis du fait de l\'enregistrement national ou régional',
  frmi_replacement_titles_add_button: 'Ajouter un enregistrement',
  frmi_replacement_missing: 'Veuillez saisir au moins un enregistrement',
  frmi_radiation_description_label: 'Informations sur la licence à radier',
  frmi_licence_edition_label: 'Informations sur la licence à modifier',
  frmi_division_details_office_contracting_party_label: 'Partie contractante de l\'Office présentant la demande',
  frmi_division_details_date_label: 'Date à laquelle a eu lieu la division de la marque française',
  frmi_division_details_brand_numnat_label: 'N° de la marque française de base résultant de la division',
  frmi_division_details_brand_request_date_label: 'Date de la demande de la marque française de base résultant de la division',
  frmi_division_details_brand_registration_date_label: 'Date de l\'enregistrement de la marque française résultant de la division',
  frmi_division_details_text_label: 'Texte à compléter',
  frmi_division_details_text: 'L\'enregistrement international est maintenant fondé tant sur l\'enregistrement de la marque française n°....que sur l\'enregistrement de la marque française n°....',

  // Renouvellement Formulaire
  renewal_brand_titles: 'Votre marque',
  renewal_brand_titles_type_delay_expired_text: '<b>Votre demande est a priori irrecevable. En effet, la période de renouvellement est dépassée. Toutefois, certains cas particuliers (marque déposée avant 1992...) peuvent justifier de demander le renouvellement. Veuillez vous en assurer avant de poursuivre. </b>',
  renewal_brand_titles_type_delay_title: 'Attention',
  renewal_brand_titles_type_delay: 'Votre marque est expirée depuis moins de 6 mois ? Si oui, vous devez cocher la case et payer la redevance pour renouvellement tardif.',
  renewal_brand_titles_type_delay_warning: 'Vous avez décoché le paiement de la redevance de paiement tardif, cette redevance pourrait vous être réclamée lors de l’examen de votre déclaration. ',
  renewal_brand_titles_type_delay_too_early_warning: '<b>La période de renouvellement n’est pas atteinte. </b>',
  renewal_brand_titles_help: 'Vous pouvez rechercher le numéro de votre marque via notre base publique en cliquant  <a>ici</a>',
  renewal_fast_track_checkbox: 'Je souhaite un traitement rapide de ma demande',
  renewal_fast_track_warning: 'L\'ajout, la suppression ou la modification de titulaires rendra votre demande inéligible au traitement rapide.',
  renewal_demand_details: 'Options',
  renewal_demand_details_subtitle: 'Cette rubrique vous permet de sélectionner des options complémentaires sur votre demande de renouvellement',
  renewal_brand_description: 'Cette rubrique vous permet d\'identifier la marque concernée par la demande de renouvellement',
  deposit_type_renewal_national_number_label: 'Numéro national de la marque à renouveler',
  deposit_type_renewal_deposit_date: 'Date de dépôt',
  deposit_type_renewal_registered_description: 'Cette marque correspond-elle au titre à renouveler ? Si oui, ajoutez le titre en cliquant sur "AJOUTER LE TITRE". Sinon, veuillez modifier le numéro de la demande puis cliquez sur "Vérifier"',
  renewal_contested_registration_products_and_services_title: 'Produits et services que vous souhaitez renouveler et/ou reclassifier',
  renewal_contested_registration_products_and_services_subtitle: 'Cette rubrique vous permet de saisir les produits et services que vous souhaitez renouveler et/ou reclassifier',
  renewal_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets du renouvellement.',
  renewal_products_and_services_description: 'Énumération des Produits & Services',
  renewal_options_extension_title: 'Extensions de protections',
  renewal_options_extension_description: 'Si la marque à renouveler est protégée en Polynésie Française et que vous souhaitez maintenir cette protection ou si vous souhaitez que la marque soit protégée en Polynésie Française : cocher la case ci-dessous.',
  renewal_options_extensions_title_nb: 'Dépôt n°',
  renewal_type_products_and_services_label: 'Le renouvellement porte-t-il sur l\'intégralité des produits et services ? Si non, indiquez les produits et services à renouveler ci-dessous.',
  renewal_reclassify_products_and_services_label: 'Y a-t-il nécessité de reclassifier (dans ce cas, procédez à cette reclassification ci-dessous) ?',
  renewal_edit_products_and_services_required: 'Veuillez ajouter, modifier ou supprimer au moins un produit',
  renewal_no_products_and_services_required: 'Veuillez indiquer au moins une classe et un libellé de produit ou service',

  // Inscription Recap
  overview_inscription_titles: 'Titres',
  overview_renewal_titles: 'Titre',
  overview_inscription_international_extension_number: 'Numéro d\'enregistrement international',
  overview_inscription_international_extension_date: 'Date d\'enregistrement international',
  overview_inscription_linked_transaction_number: 'Numéro d\'ordre ou d\'inscription',

  // Formulaire Documents officiels
  official_document_add_request: 'Ajouter une demande',
  button_modify_request: 'Modifier la demande',
  button_add_request: 'Ajouter la demande',
  official_documents_requested_column_label_id: '#',
  official_documents_requested_column_label_type: 'Type de document officiel',
  official_documents_requested_column_label_numNat: 'Numéro de demande',
  official_documents_requested_column_label_arrival_date: 'Date de demande',
  official_documents_requested_column_label_numNat_transaction: 'Numéro',
  official_documents_requested_column_label_quantity: 'Quantité Papier',
  official_documents_requested_column_label_quantity_das: 'Quantité DAS',
  official_documents_requested_column_label_quantity_pdf: 'Quantité PDF',
  official_document_requested_required: 'Veuillez saisir au moins un document',
  official_document_requested_family_error: 'Vous ne pouvez pas faire cette demande de documents officiels car certains documents ne sont pas compatibles en une unique demande.',
  official_document_requested_quantity_required: 'La quantité doit être supérieure à 0',
  deposit_type_official_document_registered_description: 'Cliquez sur « ajouter la demande » pour obtenir le document officiel correspondant à cette marque. Sinon, modifiez le numéro du titre puis cliquez sur "Vérifier".',
  official_documents_brand_copy_warning: '<b>Il n\'existe pas de marque publiée sous ce numéro. </b>',

  // Documents officiels : Recap
  overview_official_document_documents: 'Documents demandés',
  overview_official_document_intervenants_title: 'Intervenant(s)',
  official_documents_types_table_type_label: 'Type de document',
  official_documents_types_table_numnat_label: 'N° d\'enregistrement',
  official_documents_types_table_deposit_date_label: 'Date de dépôt',
  official_documents_types_table_brand_name_label: 'Dénomination',
  official_documents_types_table_quantity_label: 'Quantité',
  official_documents_types_table_send_type_label: 'Envoi',
  official_documents_types_table_sending_by_das_label: 'DAS',
  official_documents_types_table_sending_by_mail_label: 'Courrier postal',
  official_documents_types_table_sending_by_pdf_label: 'Pdf déposé sur mon portail',
  official_documents_types_table_download_file_label: 'Télécharger',
  official_document_is_administration: 'Je suis une administration',
  official_document_justificatif_file: 'Pièce justificative',

  field_internal_reference_label: 'Référence client',
  field_internal_reference_label_placeholder: 'Votre référence client',

  deposit_type_guarantee_description: 'Une marque de garantie est une marque ainsi désignée lors de son dépôt et propre à distinguer les produits ou les services pour lesquels la matière, le mode de fabrication ou de prestation, la qualité, la précision ou d’autres caractéristiques sont garantis.{br}' +
      'Peut déposer une marque de garantie toute personne physique ou morale y compris une personne morale de droit public, sous réserve que cette personne n’exerce pas une activité ayant trait à la fourniture de produits ou de services du même type que ceux qui sont garantis.{br}' +
      'Le dépôt d’une demande d’enregistrement de marque de garantie est accompagné d’une règlement d’usage dont le contenu est défini par voir réglementaire.{br}{br}' +
      'Vérifiez que vous remplissez les conditions ci-dessus et notamment que vous êtes en mesure d\'adresser un Règlement d\'Usage de votre marque de garantie. Si ces conditions ne sont pas réunies et si un Règlement d\'Usage n’est pas fourni, votre marque sera refusée à l\'enregistrement, aucun remboursement n\'étant possible.',
  deposit_type_guarantee_form_checkbox_label: "J'ai bien lu les informations et je continue la démarche.",
  deposit_type_guarantee_form_file_label: "Règlement d'usage de la marque de garantie",
  deposit_type_guarantee_consent_required: 'Veuillez cocher cette case',

  // Types de dépots:
  deposit_type_national: 'Demander l\'enregistrement d\'une marque française (protection sur le territoire national français)',
  deposit_type_guarantee: 'Demander l\'enregistrement d\'une marque de garantie',
  deposit_type_collective: 'Demander l\'enregistrement d\'une marque collective',
  deposit_type_european: 'Transformer une marque de l\'Union européenne en demande de marque française',
  deposit_type_international: 'Transformer un enregistrement international en demande de marque française',
  deposit_type_division: 'Procéder à la division d\'une demande',
  deposit_type_division_registered: 'Procéder à la division d\'une demande déjà enregistrée',
  deposit_type_required: 'Sélectionnez un type de dépôt',

  form_file_placeholder: 'Sélectionner votre fichier',
  form_file_csv_placeholder: 'Sélectionner votre fichier (au format CSV)',

  // Type de dépot: Union Européenne
  deposit_type_european_number_label: 'Numéro de la marque de l\'Union européenne',
  deposit_type_european_date_label: 'Date de dépôt de la marque de l\'Union européenne',
  deposit_type_european_request_translation: 'Traduction en français de la requête en transformation',
  deposit_type_european_attachment_request_translation: 'Traduction en français des pièces jointes à la requête en transformation',
  deposit_type_european_number_required: 'Le numéro de la marque européenne est obligatoire',
  deposit_type_date_required: 'La date de dépôt de la marque est obligatoire',
  deposit_type_european_date_format_error: 'La date n\'est pas au bon format (JJ/MM/AAAA)',
  deposit_file_size_error: 'Le fichier uploadé ne doit pas dépasser les 100 Mo',
  deposit_type_file_missing: 'Le fichier est obligatoire',
  deposit_type_numnat_missing: 'Le numéro de la demande française est obligatoire',

  // Type de dépôt: Collective
  deposit_type_collective_description: 'Une marque collective est une marque ainsi désignée lors de son dépôt et propre à distinguer les produits ou les services des personnes autorisées à l’utiliser en vertu de son règlement d’usage.{br}' +
      'Peut déposer une marque collective, toute association ou tout groupement doté de la personnalité morale représentant des fabricants, des producteurs, des prestataires de services ou des commerçants, ainsi que toute personne morale de droit public.{br}' +
      'Le dépôt d’une demande d’enregistrement de marque collective est accompagné d’un règlement d’usage dont le contenu est défini par voie réglementaire.{br}{br}' +
      'Vérifiez que vous remplissez les conditions ci-dessus et notamment que vous êtes en mesure d\'adresser un Règlement d\'Usage de votre marque collective. Si ces conditions ne sont pas réunies et si un Règlement d\'Usage n’est pas fourni, votre marque sera refusée à l\'enregistrement, aucun remboursement n\'étant possible.',
  deposit_type_collective_form_checkbox_label: "J'ai bien lu les informations et je continue la démarche.",
  deposit_type_collective_form_file_label: "Règlement d'usage de la marque collective",
  deposit_type_collective_consent_required: 'Vous devez consentir pour continuer',

  // Type de dépôt: Division
  deposit_type_division_national_number_label: 'Numéro national de la marque française à diviser',
  deposit_type_division_national_date_label: 'Date de dépôt de la demande française à diviser',
  deposit_type_division_national_type_label: 'Type de dépôt de la demande française à diviser',
  deposit_type_division_product_label: 'Produits et services de la marque divisée',
  deposit_type_division_description: 'La division d’une marque non enregistrée consiste à procéder au dépôt d’une demande d’enregistrement de marque dont le signe est identique à une demande d’enregistrement de marque en cours d’examen et reprenant une partie des produits et/ou services de celle-ci.',
  deposit_type_division_national_number_missing: 'Le numéro de la marque est obligatoire',
  deposit_type_division_national_number_not_verified: 'Le numéro de la marque n\'a pas été vérifié',
  deposit_type_division_national_type_error: 'Le type de la marque est obligatoire',
  deposit_type_division_national_text_error: 'Le nom de la marque est obligatoire',

  // Type de dépôt: Division enregistrée
  deposit_type_division_registered_request_nullity_decheance: 'La marque à diviser fait l\'objet d\'une demande en nullité ou déchéance',
  deposit_type_division_registered_justificatif: 'Justificatif de la demande en nullité ou déchéance',
  deposit_type_division_registered_description: 'Cette marque correspond-elle à la demande à diviser ? Si oui, passez à l\'étape suivante. Sinon, veuillez modifier le numéro de la demande à diviser puis cliquez sur "Vérifier"',
  deposit_type_division_not_found: 'Aucune marque n\'a été trouvée pour ce numéro national. Vous pouvez saisir un autre numéro national ou les informations manuellement. Attention, le millésime ne doit être indiqué que pour les marques déposées entre le 28/12/1991 et le 31/12/1999.',
  deposit_type_frmi_not_found: 'Aucune marque n\'a été trouvée pour ce numéro international. Vous pouvez saisir un autre numéro international.',
  deposit_type_division_registered_marque: 'Modèle de marque',
  deposit_type_division_registered_type: 'Type de marque',
  deposit_type_division_registered_deposit_date: 'Date de dépôt',
  deposit_type_division_registered_date: 'Date d\'enregistrement',
  deposit_type_division_is_registered: 'La marque est-elle enregistrée ?',

  // Type de dépot: Union Européenne
  deposit_type_international_number_label: 'Numéro de l\'enregistrement international',
  deposit_type_international_date_label: 'Date de l\'enregistrement international',
  deposit_type_international_deposit_file: 'Copie de l\'enregistrement international',
  deposit_type_international_deposit_tranlastion_file: 'Traduction de l\'enregistrement international',
  deposit_type_international_cancellation_certificate: 'Certificat de la radiation ou la lettre de l’OMPI faisant état de la radiation et précisant sa date de prise en compte',
  deposit_type_international_number_required: 'Le numéro international est obligatoire',

  // Types d'inscription:
  inscription_type_correction: 'Inscription de rectification',
  inscription_type_agent: 'Inscription relative au mandataire',
  inscription_type_act_title: 'Inscription d\'un acte affectant la propriété ou la jouissance d\'un titre',
  inscription_type_reward: 'Inscription d’une récompense ou d’un palmarès',
  inscription_type_required: 'Sélectionnez un type d\'inscription',
  inscription_type_renunciation: 'Renonciation',

  // Inscription formulaire: Marque
  deposit_type_inscription_national_number_label: 'Numéro national de la marque à modifier',
  deposit_type_inscription_registered_description: 'Cette marque correspond-elle au titre à modifier ? Si oui, ajoutez le titre. Sinon, veuillez modifier le numéro du titre à modifier puis cliquez sur "Vérifier"',
  deposit_type_inscription_registered_marque: 'Dénomination',
  deposit_type_inscription_deposit_date: 'Date de dépôt ou d\'enregistrement',
  deposit_type_inscription_origin: 'Origine',
  inscription_form_title_label: 'Modèle de marque',
  inscription_title_required: 'Veuillez saisir au moins un titre',
  inscription_title_deposit_date_required: 'La date de dépôt est obligatoire',
  inscription_title_brand_type_required: 'Le type de marque est obligatoire',
  inscription_title_origin_required: 'L\'origine du titre est obligatoire',
  inscription_title_numnat_required: 'Le numéro national est obligatoire',
  inscription_title_brand_text_required: 'La dénomination de la marque est obligatoire',

  // FRMI Formulaire : Marque
  deposit_type_frmi_registered_date: 'Date d\'enregistrement',
  deposit_type_frmi_expiration_date: 'Date d\'expiration',
  deposit_type_frmi_deposit_date: 'Date de dépôt',
  deposit_type_frmi_national_number_label: 'Numéro de la demande française à étendre à l\'international',
  deposit_type_frmi_registered_description: 'Cette marque correspond-elle au titre à étendre à l\'international ? Si oui, ajoutez le titre. Sinon, veuillez modifier le numéro de la demande puis cliquez sur "Vérifier"',
  deposit_type_frmi_op_registered_description: 'Cette marque correspond-elle au titre international concerné ? Si oui, ajoutez le titre. Si non, veuillez modifier le numéro de la marque internationale puis cliquez sur "Vérifier"',

  // Dépôt formulaire: Type de marque
  brand_type_title_isListing: 'Type de marque',
  disable_guided_mode_to_see_more_brand_types: 'Passez en mode expert pour voir tous les types de marques',
  brand_type_description_isListing: 'Cette rubrique vous permet de déterminer le type de marque que vous souhaitez déposer. Pour en savoir plus, consulter l\'aide en ligne.',
  brand_type_word_title: 'Marque verbale',
  brand_type_word_description: 'Elle est constituée de chiffres, de lettres, de mots en caractères noirs.',
  brand_type_figurative_title: 'Marque figurative',
  brand_type_figurative_description: 'Elle est constituée d\'une combinaison d\'éléments verbaux, dessins et couleurs ou d\'un dessin, sans lettres, chiffres ou mots.',
  brand_type_color_title: 'Marque de couleur',
  brand_type_color_description: 'Il s\'agit exclusivement d’une couleur ou d\'une combinaison de couleurs.',
  brand_type_hologram_title: 'Marque hologramme',
  brand_type_hologram_description: 'Il s\'agit d’un visuel en relief obtenu par un procédé holographique.',
  brand_type_shape_title: 'Marque de forme',
  brand_type_shape_description: 'Elle est constituée de la représentation d\'une forme de produit ou de son conditionnement en 3D.',
  brand_type_sound_title: 'Marque sonore',
  brand_type_sound_description: 'Elle est constituée d\'un son ou d\'une combinaison de sons.',
  brand_type_position_title: 'Marque de position',
  brand_type_position_description: 'Elle est caractérisée par la façon spécifique dont elle est placée ou apposée sur le produit.',
  brand_type_pattern_title: 'Marque de motif',
  brand_type_pattern_description: 'Elle est constituée d\'un ensemble d’éléments répétés de façon régulière.',
  brand_type_movement_title: 'Marque de mouvement',
  brand_type_movement_description: 'Elle est constituée par un mouvement ou changement de position des éléments de la marque.',
  brand_type_multimedia_title: 'Marque multimedia',
  brand_type_multimedia_description: 'Elle est constituée d\'une combinaison d\'images et de sons.',
  brand_type_other_title: 'Autre',
  brand_type_other_description: 'Attention : Cette rubrique est exclusivement réservée aux signes ne correspondant à aucun des types de marque présentés dans le menu.',
  brand_type_semi_figurative_title: 'Marque semi-figurative',
  brand_type_olfactory_title: 'Marque olfactif',
  brand_type_3d_title: 'Marque tridimensionnelle',
  brand_type_example_text: 'Exemple',
  brand_type_examples_text: 'Exemples',
  brand_type_required: 'Veuillez saisir le nom de la marque',
  brand_type_text_required: 'Veuillez saisir votre marque',
  brand_type_translation_required: 'Veuillez saisir la traduction de votre marque',
  brand_file_required: 'Veuillez ajouter votre modèle de marque',
  transaction_brand_preview_error: 'Le fichier de la marque n\'a pas pu être téléchargé',
  brand_colors_required: 'Veuillez renseigner les couleurs présentes',
  brand_description_size_error: 'La description ne doit pas dépasser 2000 caractères',
  brand_text_not_latin: 'Seuls les caractères latins usuels, chiffres et signes de ponctuation sont autorisés dans les marques verbales. Pour les marques comportant des signes chinois, grecs ou cyrilliques par exemple, choisissez le type marque figurative.',
  text_not_latin: 'Seuls les caractères latins usuels, chiffres et signes de ponctuation sont autorisés.',

  brand_type_word_text_label: 'Saisir votre marque',
  brand_type_has_translation_label: 'Souhaitez-vous indiquer la traduction de votre marque ? Celle-ci doit uniquement être la traduction d\'une langue étrangère en langue française.',
  brand_type_word_text_translation_label: 'Vous pouvez indiquer la traduction française de la marque',
  brand_type_description_label: 'Brève description du signe',
  brand_type_description_label_info: 'Cette rubrique est optionnelle et sert à décrire les éléments représentés dans votre signe.{linebreak}ATTENTION: les précisions apportées ne peuvent porter que sur des éléments représentés effectivement dans le signe. Il ne peut pas par exemple s\'agir de variantes envisagées, de renseignements concernant l\'exploitation qui sera faite du signe ou encore d\'informations quant à l\'activité du déposant.',
  brand_type_figurative_file_label: 'Ajouter la représentation de votre marque',
  brand_type_has_color_label: 'Souhaitez-vous indiquer les couleurs de la représentation jointe de votre marque ?',
  brand_type_colors_text_label: 'Couleurs',
  brand_type_color_text_label: 'Couleur',
  brand_type_proof_form_file_label: 'Vous pouvez ajouter des preuves d\'usage',

  form_date_placeholder: 'Date',
  form_number_placeholder: 'Numéro',

  // Auto-diagnostic
  brand_type_link_isDiagnosis: 'Etape recommandée : faire son autodiagnostic',
  brand_type_title_isDiagnosis: 'Auto - Diagnostic',
  brand_type_description_isDiagnosis: 'AVANT D\'ALLER PLUS LOIN... {linebreak} Une fois le dépôt de marque effectué, l\'INPI vérifie si votre marque répond aux exigences légales et peut être enregistrée. Si tel n\'est pas le cas, la marque peut être rejetée pour une partie des produits et services ou en totalité, aucun remboursement n\'étant possible en cas de refus : pour être valable, une marque doit être <a>disponible</a>, <a>distinctive</a> et <a>licite</a>. Les questions ci-dessous prennent en compte les cas de refus les plus fréquents. Attention, il ne s\'agit pas d\'une vérification de la validité de votre marque. Un refus est possible en dehors des cas de figure ci-dessous. En cas de rejet de votre demande, aucun remboursement ne sera effectué.',

  // Formulaire de dépôt : Intervenants
  contributor_title: 'Intervenants',
  contributor_form_title: 'Identification des intervenants',
  contributor_form_subtitle_DEPOSIT: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets déposant, destinataire et signataire devront être remplis.',
  contributor_form_subtitle_OPPOSITION: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets opposant, destinataire et signataire devront être remplis.',
  contributor_form_subtitle_NULLITY: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets demandeur, destinataire, titulaire de la marque contestée et signataire devront être remplis.',
  contributor_form_subtitle_REVOCATION: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets demandeur, destinataire, titulaire de la marque contestée et signataire devront être remplis.',
  contributor_form_subtitle_DIVISION: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets déclarants, destinataire et signataire devront être remplis.',
  contributor_form_subtitle_INSCRIPTION: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets demandeur(s), destinataire et signataire devront être remplis.',
  contributor_form_subtitle_INSCRIPTION_TYPE_ACT_TITLE: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets demandeur, autre(s) partie(s) à l’acte, destinataire et signataire devront être remplis.',
  contributor_form_subtitle_FRMI: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets titulaire(s), destinataire et signataire devront être remplis.',
  contributor_form_subtitle_FRMI_OP: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande.',
  contributor_form_subtitle_RENEWAL: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets déclarant(s), destinataire et signataire devront être remplis.',
  contributor_form_subtitle_OFFICIAL_DOCUMENT: 'Cette rubrique vous permet d\'identifier le demandeur de votre demande.',

  contributor_depositors: 'Déposant(s)',
  contributor_depositors_nullity: 'Demandeur(s)',
  contributor_depositors_revocation: 'Demandeur(s)',
  contributor_depositors_division: 'Déclarant(s)',
  contributor_depositors_inscription: 'Demandeur(s)',
  contributor_depositors_renunciation: 'Déclarant(s)',
  contributor_depositors_renewal: 'Déclarant(s)',
  contributor_depositors_frmi: 'Titulaire(s)',
  contributor_depositors_official_documents: 'Demandeur',
  contributor_depositors_revocation_statement: 'Demandeur',
  contributor_depositor_official_documents: 'Demandeur',
  contributor_agent: 'Mandataire',
  contributor_recipient: 'Destinataire',
  contributor_recipient_opposant: 'Destinataire de la marque contestée',
  contributor_holdersContestedMark: 'Titulaire(s) de la marque contestée',
  contributor_signatory: 'Signataire',
  contributor_holders: 'Titulaire(s)',
  contributor_old_agents: 'Mandataire(s) actuel(s)',
  contributor_old_holders: 'Titulaire(s) actuel(s)',
  contributor_licensee: 'Preneur de licence',
  contributor_old_licensees: 'Preneur(s) de licence actuel(s)',
  contributor_old_licensees_frmi_type_licence_edition: 'Preneur de licence actuel',
  contributor_otherApplicants: 'Autre(s) partie(s) à l\'acte',
  contributor_organisers: 'Organisateur(s)',

  contributor_mode_guide: 'Les  questions essentielles à se poser avant d’aller plus loin ...',
  contributor_choice_mode_guided_required: 'Veuillez sélectionner une option ou passer en mode expert',
  contributor_depositors_subtitle: 'Il peut y avoir un ou plusieurs déposants. Il s\'agit du ou des propriétaires des marques.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_frmi_subtitle: 'Tel qu\'il est connu sur le registre de l\'OMPI.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_official_documents_subtitle_official_document: 'Il ne peut y avoir qu\'un seul demandeur. Il s\'agit de la personne qui forme une demande pour des documents officiels.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_division_subtitle: 'Il peut y avoir un ou plusieurs déclarants. Il s\'agit du ou des propriétaires de la marque.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_renewal_subtitle: 'Il peut y avoir un ou plusieurs déclarants. Il s\'agit du ou des propriétaires de la marque.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_nullity_subtitle: 'Il peut y avoir un ou plusieurs demandeurs. Il s\'agit des personnes qui forment une demande en nullité contre une marque enregistrée. {linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_revocation_subtitle: 'Il peut y avoir un ou plusieurs demandeurs. Il s\'agit des personnes qui forment une demande en déchéance contre une marque enregistrée. .{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_inscription_type_correction_subtitle: 'Il peut y avoir un ou plusieurs demandeurs. Il s\'agit du titulaire du compte SSO ou du dernier titulaire connu à jour de la/des modification(s) demandée(s). {linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_inscription_type_act_title_subtitle: 'Il peut y avoir un ou plusieurs demandeurs. Il s\'agit du titulaire du compte SSO ou d\'une des parties à l\'acte. {linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_inscription_subtitle: 'Il peut y avoir un ou plusieurs demandeurs. Il s\'agit du titulaire du compte SSO ou du dernier titulaire connu. {linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_renunciation_subtitle: 'Il peut y avoir un ou plusieurs déclarants. Il s\'agit du titulaire du compte SSO ou du dernier titulaire connu. {linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_depositors_revocation_statement_subtitle_revocation_statement: 'Il ne peut y avoir qu\'un seul demandeur. {linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_deposit: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_division: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_opposition: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_revocation: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_nullity: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_inscription: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_frmi: 'Il s\'agit de la personne à qui l\'INPI et/ou l\'OMPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_renewal: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_recipient_subtitle_official_document: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_deposit: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_division: 'Il s\'agit de la personne chargée de représenter le ou les déclarants devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_opposition: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_nullity: 'Il s\'agit de la personne chargée de représenter le ou les demandeurs devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_revocation: 'Il s\'agit de la personne chargée de représenter le ou les demandeurs devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_inscription: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_frmi: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI et/ou l\'OMPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_agent_subtitle_renewal: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire.{linebreak}Pour savoir dans quels cas et qui peut être mandataire, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_holdersContestedMark_subtitle: 'Il peut y avoir un ou plusieurs titulaires de la marque contestée. Il s\'agit du ou des propriétaires de la marque contestée.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer le dépôt.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_deposit: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer le dépôt.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_division: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_opposition: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_nullity: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_revocation: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_inscription: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_frmi: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_renewal: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_holders_subtitle: 'Il peut y avoir un ou plusieurs titulaires de la marque. Il s\'agit du ou des propriétaires de la marque à diviser.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_otherApplicants_subtitle: 'Il peut y avoir une ou plusieurs parties à l\'acte. Il s\'agit des intervenants supplémentaires pour les inscriptions d\'actes affectant la propriété ou la jouissance d\'un titre.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_organisers_subtitle: 'Il peut y avoir un ou plusieurs organisateurs. Il s\'agit des intervenants organisant le concours.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_add_depositors: 'Ajouter un déposant',
  contributor_save_depositors: 'Enregistrer le déposant',
  holdersContestedMark: 'Titulaire(s) de la marque contestée',
  contributor_add_holdersContestedMark: 'Ajouter un titulaire de la marque contestée',
  contributor_add_holders_frmi: 'Ajouter un titulaire de la marque à étendre',
  contributor_add_holders: 'Ajouter un nouveau titulaire',
  contributor_add_old_holders: 'Ajouter un titulaire actuel',
  contributor_add_old_agents: 'Ajouter un mandataire actuel',
  contributor_add_old_licensees: 'Ajouter un preneur de licence actuel',
  contributor_edit_old_licensees: 'Modifier le preneur de licence actuel',
  contributor_add_licensee: 'Ajout un preneur de licence',
  contributor_duplicate_old_holders: 'Modifier les informations du titulaire actuel',
  contributor_duplicate_old_licensees: 'Modifier les informations du preneur de licence',
  contributor_old_licensees_edited_label: 'Preneur de licence mis à jour',
  contributor_old_licensees_added_label: 'Preneur de licence ajouté',
  contributor_old_holders_edited_label: 'Titulaire(s) mis à jour',
  contributor_old_holders_added_label: 'Titulaire(s) ajoutés',
  contributor_save_holdersContestedMark: 'Enregistrer le titulaire de la marque contestée',
  contributor_save_holders_frmi: 'Enregistrer le titulaire de la marque à étendre',
  contributor_save_holders: 'Enregistrer le titulaire',
  contributor_save_old_holders: 'Enregistrer le titulaire actuel',
  contributor_save_agent: 'Enregistrer le mandataire',
  contributor_save_old_agents: 'Enregistrer le mandataire actuel',
  contributor_save_licensee: 'Enregistrer le preneur de licence',
  contributor_save_old_licensees: 'Enregistrer le preneur de licence actuel',
  contributor_add_depositors_nullity: 'Ajouter un demandeur',
  contributor_add_depositors_inscription: 'Ajouter un demandeur',
  contributor_add_depositors_renunciation: 'Ajouter un déclarant',
  contributor_add_depositors_revocation_statement: 'Ajouter un demandeur',
  contributor_save_depositors_nullity: 'Enregistrer le demandeur',
  contributor_save_depositors_inscription: 'Enregistrer le demandeur',
  contributor_save_depositors_revocation_statement: 'Enregistrer le demandeur',
  contributor_add_depositors_revocation: 'Ajouter un demandeur',
  contributor_save_depositors_revocation: 'Enregistrer le demandeur',
  contributor_add_depositors_division: 'Ajouter un déclarant',
  contributor_add_depositors_renewal: 'Ajouter un déclarant',
  contributor_save_depositors_division: 'Enregistrer le déclarant',
  contributor_save_depositors_renewal: 'Enregistrer le déclarant',
  contributor_add_depositors_official_documents: 'Ajouter un demandeur',
  contributor_save_depositors_official_documents: 'Enregistrer le demandeur',
  contributor_save_depositors_renunciation: 'Enregistrer le déclarant',
  contributor_add_agent: 'Ajouter un mandataire',
  contributor_duplicate_agent: 'Modifier les informations du mandataire actuel',
  contributor_remove_agent: 'Supprimer le mandataire',
  contributor_add_otherApplicants: 'Ajouter une partie à l\'acte',
  contributor_remove_otherApplicants: 'Supprimer la partie à l\'acte',
  contributor_save_otherApplicants: 'Enregistrer la partie à l\'acte',
  contributor_add_organisers: 'Ajouter un organisateur',
  contributor_remove_organisers: 'Supprimer l\'organisateur',
  contributor_save_organisers: 'Enregistrer l\'organisateur',
  original_contributor_label: 'Mis à jour',
  duplicated_contributor_label: 'Ajouté(s)',

  contributor_depositors_required: 'Veuillez renseigner au moins un déposant',
  contributor_depositors_required_inscription: 'Veuillez renseigner au moins un demandeur',
  contributor_depositors_wrong_type: 'Le ou les déposants suivants ont des types incompatibles avec vos choix du mode guidé: $1. Veuillez passer en mode expert ou changer leur type.',
  contributor_depositors_nullity_required: 'Veuillez renseigner au moins un demandeur',
  contributor_depositors_revocation_required: 'Veuillez renseigner au moins un demandeur',
  contributor_depositors_division_required: 'Veuillez renseigner au moins un déclarant',
  contributor_depositors_renewal_required: 'Veuillez renseigner au moins un déclarant',
  contributor_holders_frmi_required: 'Veuillez renseigner au moins un nouveau titulaire',
  contributor_old_licensees_frmi_update_required: 'Veuillez modifier au moins un preneur de licence',
  contributor_old_holders_frmi_update_required: 'Veuillez modifier au moins un titulaire',
  contributor_old_holders_frmi_required: 'Veuillez renseigner au moins un titulaire actuel',
  contributor_depositors_inscription_required: 'Veuillez renseigner au moins un demandeur',
  contributor_depositors_renunciation_required: 'Veuillez renseigner au moins un déclarant',
  contributor_depositors_revocation_statement_required: 'Veuillez renseigner au moins un demandeur',
  contributor_otherApplicants_required: 'Veuillez renseigner au moins une partie à l\'acte',
  contributor_organisers_required: 'Veuillez renseigner au moins un organisateur',
  contributor_holders_frmi_missing_field: 'Des champs sont manquants, veuillez les renseigner',
  contributor_depositors_official_documents_required: 'Veuillez renseigner au moins un demandeur',
  contributor_licensee_frmi_missing_field: 'Un nouveau licencier est requis',
  contributor_agent_frmi_missing_field: 'Le nouveau mandataire est requis',

  // Questions en mode guidé de l'identification des intervenants
  question_guided_pp: 'Effectuez-vous le dépôt pour votre seul compte, en tant que personne physique ?',
  question_guided_pm: 'Effectuez-vous le dépôt pour le compte d\'une personne morale (société, association...) ?',
  question_guided_entity_formation: 'Effectuez-vous le dépôt au nom d\'une entité en cours de formation (société, association...) ?',
  question_guided_various_accounts: 'Effectuez-vous le dépôt pour votre compte et le compte de plusieurs personnes physiques ?',
  information_guided_pm: 'Nous attirons votre attention sur le fait qu\'une entreprise individuelle ne bénéficie pas de la personnalité morale. Si vous êtes dans ce cas de figure, il convient d\'effectuer un dépôt en tant que personne physique.',
  information_guided_entity_formation: 'Nous attirons votre attention sur le fait qu\'une entité en cours de formation ne bénéficie pas de la personnalité morale. Si vous êtes dans ce cas de figure, il convient d\'effectuer un dépôt en tant que personne physique et de préciser que vous agissez au nom et pour le compte d\'une entité en cours de formation.',

  // Dépôt formulaire: Produits & services
  products_and_services_title: 'Produits et Services',
  products_and_services_title_renunciation: 'Classes et produits et/ou services auxquels s\'appliquera la marque après la renonciation',
  products_and_services_tab_overview: 'Aperçu de la classification',
  products_and_services_tab_advanced_search: 'Recherche avancée d\'un terme',
  products_and_services_tab_free_input: 'Saisie libre',
  products_and_services_tab_import_by_file: 'Import par fichier',
  products_and_services_tab_your_basket: 'Votre panier',
  products_and_services_list_filter_placeholder: 'Filtrer',
  products_and_services_list_filter_ko: 'Afficher les termes en erreur',
  products_and_services_tmclass_search_placeholder: '3 lettres minimum...',
  products_and_services_by_class: 'Affichage par classe',
  products_and_services_by_list: 'Affichage par liste',
  validation_tmclass: 'Contrôler les produits',

  products_and_services_class: 'Classe',
  deposit_products_and_services_description: 'Énumération des Produits & Services',
  products_and_services_description: 'Énumération des Produits & Services',
  products_and_services_description_subtitle: 'Pour la classe indiquée, saisissez ici les produits et/ou services destinés à être identifiés par la marque.',
  products_and_services_in_modal_description_subtitle: 'Pour ajouter une classe de produits et services, veuillez sélectionner une classe, saisir ci-dessous les produits ou services de la classe puis cliquer sur le bouton Ajouter.{linebreak}Une fois que vous avez ajouté la/les classe(s) de produits et services, veuillez cliquer sur le bouton Valider l\'ajout de produits et services.',
  undefined_products_and_services_description: 'Énumération des Produits & Services',
  opposition_products_and_services_description: 'Énumération des Produits & Services',
  opposition_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets de l’opposition.',
  nullity_products_and_services_description: 'Énumération des Produits & Services',
  nullity_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets de la nullité.',
  revocation_products_and_services_description: 'Énumération des Produits & Services',
  revocation_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets de la déchéance.',
  products_and_services_search_length: 'Vous devez renseigner au moins 3 caractères',
  products_and_services_description_missing: 'La description est obligatoire',
  products_and_services_file_missing: 'Vous devez renseigner un fichier',
  products_and_services_file_upload_success: 'Les produits et services ont bien été ajoutés à votre panier',
  product_and_services_file_label: 'Fichier',
  product_and_services_help: 'Pour information, votre fichier doit contenir deux colonnes : une avec les numéros des classes et l\'autre avec des noms de ces dernières',
  product_and_services_help_link: 'Télécharger un fichier d\'exemple',
  product_and_services_example_file: 'Produits-et-Services-exemple.csv',
  product_and_service_status_deleted: 'Supprimé',
  product_and_service_status_edited: 'Modifié',
  products_and_services_select_all: 'Tout sélectionner',
  products_and_services_select_class: 'Sélectionner une classe',

  product_and_services_type_nice: 'Nice',
  product_and_services_type_tmclass: 'TMClass',
  product_and_services_type_import: 'Importé',
  product_and_services_type_free: 'Saisie libre',

  product_and_services_table_label: 'Libellés',
  product_and_services_table_origin: 'Origine',
  product_and_services_table_status: 'État',
  product_and_services_table_edit: 'Modifier',
  product_and_services_table_delete: 'Supprimer',
  product_and_services_table_validate: 'Validé',
  product_and_services_table_products: 'Produits',
  product_and_services_table_edit_products: 'Produits modifiés',
  product_and_services_missing: 'Vous devez avoir au moins un produit ou un service pour continuer',
  product_and_services_import_file_error: 'Le fichier doit être au format CSV (Voir le fichier d\'exemple)',
  product_and_services_success_edit: 'La demande de retrait a bien été prise en compte',
  product_and_services_success_edit_regularization: 'La demande de régularisation a bien été prise en compte',
  product_and_services_error_edit_regularization: 'Vous ne pouvez pas supprimer l\'intégralité des produits et services.',
  product_and_services_regularization_title: 'Régularisation de produits et services',
  product_and_services_has_pending_versions: 'Une version est en attente de validation. La version affichée est la dernière version validée par l\'INPI.',

  // Dépôt formulaire: Récapitulatif
  overview_title: 'Récapitulatif',
  overview_deposit_type_title: 'Informations générales',
  overview_deposit_options_title: 'Options',
  overview_deposit_options_priorities: 'Priorités',
  overview_deposit_intervenants_title: 'Intervenants',
  overview_deposit_protection_extensions_title: 'Extensions de protections',
  overview_deposit_inscriptions_link_title: 'Inscriptions antérieures et/ou simultanées',
  overview_deposit_international_extension_title: 'Extension de la marque à l\'international',
  overview_deposit_brand_title: 'Marque',
  overview_deposit_brand_mother_title: 'Marque divisée (mère)',
  overview_deposit_products_title: 'Produits et Services',
  overview_deposit_products_title_renewal: 'Classes / Produits et Services',
  overview_deposit_products_revocation_title: 'Retrait de produits et services',
  overview_deposit_products_listing_mode_title: 'Retour en arrière',
  overview_amount_title: 'Montant des redevances',
  overview_deposit_type_national: 'Marque française',
  overview_deposit_type_guarantee: 'Marque de garantie',
  overview_deposit_type_collective: 'Marque collective',
  overview_deposit_type_european: 'Transformation d\'une marque de l\'Union européenne',
  overview_deposit_type_international: 'Transformation d\'un enregistrement international',
  overview_deposit_type_division: 'Division d\'une demande',
  overview_deposit_type_division_registered: 'Division d\'une demande enregistrée',
  overview_brand_description: 'Description',
  overview_brand_text: 'Texte de la marque',
  overview_brand_deposit_type: 'Type de la marque',
  overview_brand_deposit_type_deposit_type_european: 'Type de la marque de l\'Union européenne',
  overview_brand_number_label_deposit_type_european: 'Numéro de la marque de l\'Union européenne',
  overview_brand_deposit_type_deposit_type_international: 'Type de l\'enregistrement international',
  overview_brand_number_label_deposit_type_international: 'Numéro de l\'enregistrement international',
  division_overview_brand_text: 'Modèle de marque',
  overview_brand_translation: 'Traduction',
  overview_file_title: 'Documents',
  overview_payment_label: 'Prestation',
  overview_payment_amount: 'Tarif',
  overview_payment_quantity: 'Quantité',
  overview_payment_line_amount: 'Total',
  overview_payment_total_amount: 'Total à payer',
  overview_consent_missing: 'Veuillez cocher cette case',
  overview_nature_code_missing: 'La nature de l\'inscription est manquante, veuillez la renseigner à l\'étape ',
  overview_consent_form_checkbox_label: 'Je confirme que les données ci-dessus sont conformes à mes attentes',
  overview_deposit_type_label: 'Type de dépôt',
  overview_inscription_type_label: 'Type d\'inscription',
  overview_deposit_date_label: 'Date de dépôt',
  overview_deposit_registered_date_bopi: 'Date d\'enregistrement',
  overview_brand_number_label: 'Numéro de la marque',
  overview_internal_reference_label: 'Référence client',
  overview_request_date: 'Date de la demande',
  overview_fax: 'Régularisation par fax',
  overview_erratum: 'Demande d\'erratum',
  overview_correction: 'Demande de correction d\'erreur matérielle',
  overview_products_revocation: 'Accéder au retrait de produits et services',
  overview_documents: 'Accéder aux documents',
  overview_opposition_foundations_title: 'Fondement(s)',
  overview_nullity_foundations_title: 'Motif(s) relatif(s)',
  overview_nullity_motives_title: 'Motif(s) absolu(s)',
  overview_revocation_motives_title: 'Motif(s)',
  overview_division_suite: 'Position dans la suite de divisions',
  overview_inscription_suite: 'Position dans la suite d\'incriptions',
  overview_etape_workflow: 'Etape du dossier',
  overview_deadline_date: "Date d'échéance",

  warning_restricted_qualities_signatory: '<b>Vérifiez que cette qualité est bien conforme au regard du déposant ou mandataire. </b>',
  recommended_qualities_signatory_message: '<a>Voir les qualités conseillées</a>',
  recommended_qualities_signatory_link: 'https://www.inpi.fr/sites/default/files/Procedure%20enregistrement%20marques%20janvier%202022.pdf',
  correction_file: 'Fichier concernant la correction d\'erreur matérielle',
  correction_without_payment: 'Attention : une demande de correction d\'erreur matérielle doit être payée pour pouvoir être traitée.',
  erratum_modal_description: 'Si vous avez commis une erreur lors de votre demande d’enregistrement de marque, vous pouvez demander une correction d\'erreur matérielle.' +
  'Votre demande de correction d\'erreur matérielle fera l\'objet d\'un examen par l\'Institut.' +
  'Si cette demande de correction d\'erreur matérielle est considérée comme non justifiée (pièces justificatives absentes ou insuffisantes) ou si elle est de nature à modifier la portée de la marque (modification des produits et services ou du modèle de marque), elle sera refusée.' +
  'Ce refus ne vous donnera pas droit à un remboursement de la redevance correspondante.',

  // Paiement:
  payment_title: 'Paiement',
  payment_bank_card: 'Carte bancaire',
  payment_not_payer: 'Vous n\'êtes pas le payeur ?',
  payment_access_button: 'Payer',
  popup_back_message: 'En poursuivant cette action, vous reviendrez sur la page d\'accueil du portail, êtes-vous sûr de vouloir continuer ?',
  payment_form_account_title: 'Compte professionnel INPI',
  payment_form_account: 'N° de compte professionnel',
  payment_manageable_memory: 'Mémoire administratif',
  payment_manageable_memory_info: 'Ce mode de paiement est réservé aux collectivités territoriales et aux établissements publics',
  payment_create_payer: 'Création d\'un payeur',
  payment_payer: 'Payeur',
  payment_confirm_free_deposit: 'En validant la page, vous consentez à la demande de dépôt. Il sera ensuite impossible de l\'abandonner ou de la modifier.',
  payment_confirm_free_inscription: 'En validant la page, vous consentez à la demande d\'inscription. Il sera ensuite impossible de la modifier.',
  payment_confirm_free_frmi: 'En validant la page, vous consentez à la demande d\'opération postérieure. Il sera ensuite impossible de l\'abandonner ou de la modifier.',
  payment_confirm_free_official_document: 'En validant la page, vous consentez à la demande de documents officiels. Il sera ensuite impossible de l\'abandonner ou de la modifier.',
  payment_confirm_free_revocation_statement: 'Si vous n’acquittez pas la redevance de relevé de déchéance, vous pourrez recevoir une notification vous demandant de la régler sous peine d’irrecevabilité.',
  payment_confirm_free_renewal: 'En validant la page, vous consentez à la demande de renouvellement. Il sera ensuite impossible de l\'abandonner ou de la modifier.',
  payment_prompt: 'Le paiement est toujours en cours de validation, êtes-vous sûr de vouloir quitter la page?',
  payment_waiting: 'En attente de la validation du paiement',
  payment_redirection: 'Aller sur le dossier',
  payment_redirection_multi: 'Consulter les demandes sur le portail',
  payment_redirect_division_suite: 'Continuer la suite de division',
  payment_redirect_inscription_suite: 'Continuer la suite d\'inscription',
  payment_not_redirection: 'Réessayer',

  payment_deposit_success: 'Le paiement de votre dépôt a été réalisé avec succès',
  payment_deposit_text_success: 'Un email va vous être envoyé contenant le numéro national de votre dépôt. Vous pouvez consulter votre dossier.',
  payment_opposition_success: 'Le paiement de votre demande d\'opposition a été réalisé avec succès',
  payment_opposition_text_success: 'Un email va vous être envoyé contenant le numéro de votre opposition. Vous pouvez consulter votre dossier.',
  payment_nullity_success: 'Le paiement de votre demande en nullité a été réalisé avec succès',
  payment_nullity_text_success: 'Un email va vous être envoyé contenant le numéro de votre nullité. Vous pouvez consulter votre dossier.',
  payment_revocation_success: 'Le paiement de votre demande de déchéance a été réalisé avec succès',
  payment_revocation_text_success: 'Un email va vous être envoyé contenant le numéro de votre déchéance. Vous pouvez consulter votre dossier.',
  payment_correction_success: 'Le paiement de votre demande a été réalisé avec succès',
  payment_correction_text_success: 'Un email va vous être envoyé pour confirmer votre demande. Vous pouvez consulter votre dossier.',
  payment_frmi_success: 'Le paiement de votre demande a été réalisé avec succès',
  payment_frmi_post_operation_success: 'Le paiement de votre demande a été réalisé avec succès',
  payment_frmi_text_success: 'Un email va vous être envoyé contenant le numéro de votre demande. Vous pouvez consulter votre dossier.',
  payment_frmi_post_operation_text_success: 'Un email va vous être envoyé contenant le numéro de votre demande. Vous pouvez consulter votre dossier.',
  payment_official_document_success: 'Le paiement de votre demande a été réalisé avec succès',
  payment_official_document_text_success: 'Un email va vous être envoyé contenant le numéro de votre demande. Vous pouvez consulter votre dossier.',
  payment_revocation_statement_text_success: 'Un email va vous être envoyé contenant le numéro de votre demande. Vous pouvez consulter votre dossier.',
  payment_revocation_statement_success: 'Le paiement de votre demande a été réalisé avec succès',
  payment_renewal_text_success: 'Un email va vous être envoyé contenant le numéro de votre demande. Vous pouvez consulter votre dossier.',
  payment_renewal_success: 'Le paiement de votre demande de renouvellement a été réalisé avec succès',
  payment_annulation: 'Vous avez annulé le paiement de votre demande',
  payment_text_annulation: 'Vous êtes intervenu lors de la validation de votre paiement. N\'hésitez pas à réessayer ulterieurement.',
  payment_error: 'Erreur lors de la réalisation du paiement de votre demande',
  payment_text_error: 'Une erreur est survenue lors de la validation de votre paiement. N\'hésitez pas à réessayer ulterieurement.',
  payment_download: 'Télécharger le récapitulatif',

  // Formulaire de dépôt : Options
  options_label: 'Options',
  options_title: 'Option : revendication de priorité',
  options_description: 'Cette rubrique est réservée aux personnes ayant préalablement déposé une demande d\'enregistrement de marque dans un autre pays membre de l\'Union de Paris ou de l\'organisation mondiale du commerce (OMC) depuis moins de six mois et souhaitant étendre leur protection en France. Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',

  options_add_priority: 'Ajouter une priorité',
  options_extension_title: 'Option : extensions de protection ',
  priority_label_number: 'Priorité n°{nb}',
  form_office_label: 'Pays ou organisme',
  form_office_placeholder: 'Sélectionner un pays ou organisme',
  form_deposit_number: 'Numéro du dépôt d\'origine ',
  form_deposit_number_select: 'Numéro du dépôt d\'origine',
  form_deposit_number_select_placeholder: 'Choisissez votre numéro de dépôt',
  form_depositor_name: 'Nom du déposant d\'origine',
  form_deposit_date: 'Date du dépôt d\'origine ',
  form_priority_file: 'Documents de priorité',
  form_priority_file_copy_request: 'Copie officielle de la demande antérieure',
  form_priority_file_copy_request_traduction: 'Traduction de la copie officielle de la demande antérieure',
  form_priority_file_justification: 'Justification du droit de revendiquer la priorité',
  form_priority_file_justification_traduction: 'Traduction de la justification du droit de revendiquer la priorité',
  options_extension_description: 'La demande de protection est faite sur le territoire français à l\'exception de la Polynésie française, ce territoire disposant d\'une compétence propre en matière de propriété industrielle. Si vous le souhaitez, vous pouvez étendre la protection de votre demande de marque à la Polynésie française. Cette désignation doit être faite au moment du dépôt, elle ne pourra pas être ajoutée ensuite.' +
      ' Attention, pour les types de marque suivants : marque hologramme (en format MP4), marque sonore (en format MP3), marque de position, marque de motif, marque de mouvement (en format MP4) et marque multimédia (en format MP4), il vous appartient de vous assurer que le type de marque que vous avez sélectionné est admis sur le territoire de la Polynésie française au jour du dépôt.',
  options_collective_brand: 'Extensions Polynésie Française',
  button_add_priority: 'Ajouter la priorité',
  button_modify_priority: 'Modifier la priorité',
  priority_deposit_date_required: 'La date de dépôt est obligatoire',
  priority_deposit_date_wrong_format: 'Champ date sous la forme JJ/MM/AAAA',
  priority_deposit_date_too_late: 'La date ne peut être postérieure à la date du jour',
  options_columns_label_id: '#',
  options_columns_label_number: 'Numéro',
  options_columns_label_date: 'Date de dépôt',
  options_columns_label_country: 'Pays',
  priority_file_extension_error: 'Le format du fichier n\'est pas autorisé. Seuls les fichiers au format .pdf sont acceptés.',
  priority_error: 'Veuillez valider la priorité avant de continuer',
  priority_missing_field_error: 'Toutes les informations d\'une priorité doivent être renseignées',
  MM18_missing: 'Veuillez ajouter le document MM18',

  // Carnet d'adresses
  addresses_book_title: 'Carnet d\'adresses',
  addresses_book_description: 'Le carnet d\'adresses vous permet de répertorier l\'ensemble de vos contacts pour vous aider à préremplir les informations des intervenants lors de vos démarches (limité à 200 contacts).',
  button_add_contributor: 'Ajouter un intervenant',
  address_book_siren: 'Siren',
  address_book_name: 'Nom',
  address_book_address: 'Adresse',
  create_contributor_title: 'Ajout d\'un contact dans le carnet d\'adresses',
  edit_contributor_title: 'Modification d\'un contact dans le carnet d\'adresses',
  popin_delete_contact_title: 'Êtes-vous sûr de vouloir supprimer cet intervenant ?',
  button_delete_contact: 'Supprimer l\'intervenant',
  button_replace_one_contact: 'Remplacer l\'intervenant',
  button_add_one_contact: 'Ajouter l\'intervenant',
  button_replace_all_contact: 'Remplacer l\'ensemble des contacts existants',
  button_add_all_contact: 'Ajouter les intervenants',
  popin_multiple_already_exist_contact: 'Des intervenants ont déjà été enregistrés',
  popin_import_from_address_book: 'Sélectionner un contact depuis le carnet d\'adresses',
  button_import_address_book: 'Importer un carnet d\'adresses',
  button_export_address_book: 'Télécharger le carnet d\'adresses',
  add_contact_error: 'Erreur lors de l\'ajout de l\'intervenant',
  delete_contact_error: 'Erreur lors de la suppression de l\'intervenant',
  import_contact_error: 'Erreur lors de l\'import du carnet d\'adresses',
  modify_contact_error: 'Erreur lors de la modification de l\'intervenant',
  error_get_siren_from_denomination: 'Une erreur est survenue, veuillez réessayer plus tard',
  error_siren_not_found: 'Le siren X n\'a pas été trouvé',
  error_contact_max_number: 'Votre carnet d\'adresses est trop rempli pour pouvoir ajouter ce document. Il peut contenir jusqu\'à 200 adresses.',
  error_contact_already_exist: 'L\'intervenant a déjà été enregistré, que souhaitez-vous faire ?',
  success_import_contact: 'Le carnet d\'adresses a bien été importé',
  success_add_contact: 'L\'intervenant a bien été ajouté au carnet d\'adresses',
  success_modify_contact: 'L\'intervenant a bien été modifié dans le carnet d\'adresses',
  // Recherche
  header_search_placeholder: 'Rechercher dans mes dossiers',

  // Footer
  footer_personal_datas: 'Données personnelles',
  footer_cookies: 'Cookies',
  footer_legal_notice: 'Mentions légales',
  footer_cgu: 'CGU',
  footer_cpu: 'CPU',
  footer_accessibility: 'Accessibilité',
  footer_site_map: 'Plan du site',
  footer_contact_us: 'Contactez-nous',

  fo_version_api: 'API :',
  fo_version_front: 'FO :',

  // MessageToInpi
  contact_firstname_label: 'Prénom',
  contact_lastname_label: 'Nom',
  contact_phone_number_label: 'N° de téléphone',
  contact_email_label: 'Email',
  contact_secondary_email_label: 'Email secondaire',
  contact_object_label: 'Objet',
  contact_message_label: 'Message',
  contact_title: 'Envoyer un message',
  contact_send_button: 'Envoyer à l\'INPI',
  contact_choose_object: 'Veuillez sélectionner votre type de demande',
  contact_success_message: 'Votre demande a bien été envoyée.',

  // Bloc éditorial
  rgpd_title: 'Données personnelles',
  common_start_form: 'Débuter le formulaire',

  // Page détail
  deposit_title: 'Demande ',
  division_title: 'Division ',
  record_title: 'Marque ',
  opposition_title: 'Opposition ',
  nullity_title: 'Nullité ',
  revocation_title: 'Déchéance ',
  inscription_title: 'Inscription ',
  frmi_title: 'FRMI ',
  official_document_title: 'Documents officiels ',
  renewal_title: 'Renouvellement ',
  request_go_edit: 'Poursuivre ma demande',
  other_actions: 'Autres actions possibles',
  revocation_statement_title: 'Relevé de déchéance ',

  // Dépôt : Ajout de documents FrontOffice
  request_add_document_button: 'Ajouter des documents',
  request_document_title: 'Documents ajoutés',
  request_add_document_label: 'Document à ajouter',
  request_add_document_type_label: 'Type du document à ajouter',
  request_add_document_communicability_label: 'Communicable',
  request_add_document_table_name: 'Nom du fichier',
  request_add_document_table_type: 'Type de document',
  request_add_document_table_non_communicable: 'Non communicable',
  request_add_document_no_file: 'Veuillez ajouter au moins un fichier',
  request_add_document_success: 'Vos documents ont bien été enregistrés',
  request_add_document_error: 'Un ou plusieurs documents n\'ont pas pu être enregistrés',
  request_add_document_receipt: 'Télécharger la liste des pièces déposées',
  additional_documents_title: 'Pièces complémentaires',
  request_download_receipt_async: 'La génération du récépissé est en cours, un email vous sera envoyé lorsque le document sera disponible sur le portail',

  // Dépôt par fax
  fax_date: 'Date du fax',
  placeholder_fax_select: 'Sélectionner le fax',
  fax_title: 'Fax',
  request_add_fax_success: 'Votre demande par fax a bien été enregistrée',
  fax_description: 'Si en raison d\'un problème technique vous avez effectué une demande par fax, veuillez renseigner la date d\'envoi et transmettre le document faxé.',

  // Demande d'erratum
  erratum_button: "Demande d'Erratum",
  request_add_erratum_success: 'Votre demande d\'erratum a bien été enregistrée',
  erratum_description: 'Un erratum est une erreur de publication commise par l\'INPI. Vous pouvez en signaler en déposant un pdf d\'erratum qui sera transmis à l\'INPI.',

  // Notifications
  overview_transaction_card_notification_title: 'Notifications',
  overview_transaction_notification_title_label: 'Titre',
  overview_transaction_notification_status_label: 'Statut',
  overview_transaction_notification_content_label: 'Contenu de la notification',
  overview_transaction_notification_received: 'Emis le',
  overview_transaction_notification_receipt_date: 'Réception confirmée le',
  overview_transaction_notification_due_to: 'Echéance',
  overview_transaction_notification_open: 'Lire la notification',
  overview_transaction_notification_close: 'Fermer',
  overview_transaction_notification_answer: 'Réponse à la notification',
  overview_transaction_notification_answer_receipt: 'Réception confirmée le ',
  overview_transaction_notification_answer_button: 'Envoyer la réponse',
  overview_transaction_notification_more_options: 'Autres types de réponse',
  overview_transaction_notification_supporting_document_label: 'Document(s) justificatif(s)',
  overview_transaction_notification_ompi_documents_label: 'Document(s) fourni(s)',
  overview_transaction_notification_ellipsis: 'Veuillez télécharger le PDF pour voir l\'intégralité de la notification',
  overview_transaction_notification_error_document_format: 'Les fichiers doivent être au format PDF.',
  overview_transaction_notification_success_answer: 'Votre réponse à la notification a été envoyée',
  overview_transaction_notification_payment: 'Paiement de la notification',
  overview_transaction_notification_amount_to_pay: 'Montant à payer',
  overview_transaction_notification_already_paid: 'Cette notification a été payée',
  overview_transaction_notification_payment_button: 'Accéder au paiement',
  overview_transaction_notification_agreement_title: 'Bon pour accord',
  overview_transaction_notification_confirm_response_title: 'Confirmer réception',
  overview_transaction_notification_send_title: 'Répondre à la notification',
  overview_transaction_notification_modal_send_message: 'Vous êtes sur le point de répondre à une notification',
  overview_transaction_notification_one_payment: 'Paiement d\'une notification',
  overview_transaction_notification_regularisation: 'Régulariser les produits et services',
  overview_transaction_notification_regularisation_done: 'Régularisation enregistrée sur les produits et services',
  overview_transaction_notification_documents: 'Ajouter des documents',
  overview_transaction_notification_send_inpi: 'Le texte suivant sera transmis aux services de l’INPI :',
  overview_transaction_notification_answer_description: 'Madame, Monsieur, {linebreak}{linebreak}Je fais suite à votre notification et vous donne mon accord pour procéder aux modifications requises qui permettront la régularisation de ma demande.{linebreak}{linebreak}Cordialement',
  or: 'ou',
  at: ' le ',
  overview_transaction_notification_send_answer_info: 'N\'oubliez pas, pour valider définitivement votre réponse auprès de l\'INPI, de cliquer sur le bouton "ENVOYER LA REPONSE"',
  overview_transaction_notification_answer_comment: 'Commentaire',

  // Notifications OMPI
  overview_transaction_card_notification_ompi_title: 'Notifications OMPI',
  overview_transaction_notification_ompi_content_label: 'Résumé de l\'avis d\'irrégularités',
  overview_transaction_notification_ompi_sending_date_label: 'Envoyé le',
  overview_transaction_notification_ompi_answer_before_date_label: 'Répondre avant',
  overview_transaction_notification_ompi_ref_ompi_label: 'Réf OMPI',
  overview_transaction_notification_ompi_ref_inpi_label: 'Réf INPI',
  overview_transaction_notification_ompi_ref_depositor_label: 'Réf déposant',
  overview_transaction_notification_ompi_mobile_label: 'Téléphone',
  overview_transaction_notification_ompi_email_label: 'Email',
  overview_transaction_notification_ompi_examinator_label: 'Examinateur',
  overview_transaction_notification_ompi_irregularities_notices_label: 'Liste des avis d\'irrégularités',
  overview_transaction_notification_ompi_fees_and_taxes_label: 'Emoluments et taxes',
  overview_transaction_notification_ompi_comment_title: 'Commentaire de l\'INPI',
  overview_transaction_notification_ompi_comment_label: 'Commentaire',
  overview_transaction_notification_ompi_answer_label: 'Réponse',

  // Paiement notification
  payment_notification_success: 'Le paiement de votre notification a été réalisé avec succès',
  payment_notification_text_success: 'Vous pouvez consulter votre dossier.',
  payment_notification_error: 'Erreur lors de la réalisation du paiement de votre notification',
  payment_notification_text_error: 'Une erreur est survenue lors de la validation de votre notification N\'hésitez pas à réessayer ulterieurement.',
  payment_notification_annulation: 'Vous avez annulé le paiement de votre notification',
  payment_notification_text_annulation: 'Vous êtes intervenu lors de la validation de votre notification. N\'hésitez pas à réessayer ulterieurement.',

  // Paiement regularisation
  payment_regularization_success: 'Le paiement de la régularisation a été réalisé avec succès',
  payment_regularization_text_success: 'Vous pouvez consulter votre dossier.',
  payment_regularization_error: 'Erreur lors de la réalisation du paiement la régularisation',
  payment_regularization_text_error: 'Une erreur est survenue lors de la validation de la régularisation. N\'hésitez pas à réessayer ulterieurement.',
  payment_regularization_annulation: 'Vous avez annulé le paiement de la régularisation',
  payment_regularization_text_annulation: 'Vous êtes intervenu lors de la validation de la régularisation. N\'hésitez pas à réessayer ulterieurement.',

  // Paiement regularisation
  payment_division_success: 'Le paiement de la demande de division a été réalisé avec succès',
  payment_division_text_success: 'Vous pouvez consulter votre dossier.',
  payment_division_error: 'Erreur lors de la réalisation du paiement la division',
  payment_division_text_error: 'Une erreur est survenue lors de la validation de la division. N\'hésitez pas à réessayer ulterieurement.',
  payment_division_annulation: 'Vous avez annulé le paiement de la demande de division',
  payment_division_text_annulation: 'Vous pouvez réessayer ultérieurement.',

  // Paiement inscription
  payment_inscription_success: 'Le paiement de la demande d\'inscription a été réalisé avec succès',
  payment_inscription_text_success: 'N\'hésitez pas à consulter votre dossier.',
  payment_inscription_error: 'Erreur lors de la réalisation du paiement de l\'inscription',
  payment_inscription_text_error: 'Une erreur est survenue lors de la validation de l\'inscription. N\'hésitez pas à réessayer ulterieurement.',
  payment_inscription_annulation: 'Vous avez annulé le paiement de l\'inscription',
  payment_inscription_text_annulation: 'Vous êtes intervenu lors de la validation de l\'inscription. N\'hésitez pas à réessayer ulterieurement.',
  payment_inscription_ta: 'Je souhaite un traitement accéléré de ma demande',
  request_transform_tn_ta_inscription: 'Passer la demande en traitement accéléré',
  request_transform_tn_ta_inscription_modal_title: 'Passer en traitement accéléré',
  request_transform_tn_ta_inscription_modal_content: 'Êtes-vous sûr de vouloir passer la demande en traitement accéléré ?',

  // Paiement renonciation
  payment_renunciation_success: 'Le paiement de la demande de la renonciation a été réalisé avec succès',
  payment_renunciation_text_success: 'N\'hésitez pas à consulter votre dossier.',
  payment_renunciation_error: 'Erreur lors de la réalisation du paiement de la renonciation',
  payment_renunciation_text_error: 'Une erreur est survenue lors de la validation de la renonciation. N\'hésitez pas à réessayer ulterieurement.',
  payment_renunciation_annulation: 'Vous avez annulé le paiement de la renonciation',
  payment_renunciation_text_annulation: 'Vous êtes intervenu lors de la validation de la renonciation. N\'hésitez pas à réessayer ulterieurement.',

  // Paiement transformation inscription TN en TA
  payment_transformation_tn_ta_success: 'Le paiement de votre passage en traitement accéléré a été réalisé avec succès',
  payment_transformation_tn_ta_text_success: 'N\'hésitez pas à consulter vos dossiers.',
  payment_transformation_tn_ta_error: 'Erreur lors de la réalisation du paiement de votre passage en traitement accéléré',
  payment_transformation_tn_ta_text_error: 'Une erreur est survenue lors de la validation de votre passage en traitement accéléré. N\'hésitez pas à réessayer ulterieurement.',
  payment_transformation_tn_ta_annulation: 'Vous avez annulé le paiement de votre passage en traitement accéléré',
  payment_transformation_tn_ta_text_annulation: 'Vous êtes intervenu lors de la validation de votre passage en traitement accéléré. N\'hésitez pas à réessayer ulterieurement.',
  overview_inscription_tn_origin_label: 'Numéro du traitement normal à partir duquel ce traitement a été accéléré',
  overview_inscription_ta_replace_label: 'Numéro du traitement accéléré par lequel a été remplacé ce traitement normal',

  // Statuts (seulement visibles FO) des notifs
  overview_transaction_notification_status_TOANSWER: 'En attente de réponse',
  overview_transaction_notification_status_TOANSWEROMPI: 'En attente de réponse',
  overview_transaction_notification_status_ANSWERED: 'Répondue',
  overview_transaction_notification_status_CLOSEDANSWERED: 'Fermée répondue',
  overview_transaction_notification_status_CLOSEDNOTANSWERED: 'Fermée non répondue',
  overview_transaction_notification_status_CLOSED_CONSIDERED: 'Fermée lu',
  overview_transaction_notification_status_CLOSED_NOTCONSIDERED: 'Fermée non lu',
  overview_transaction_notification_status_TOCONSIDER: 'A lire',
  overview_transaction_notification_status_CONSIDERED_FO: 'Lu',
  overview_transaction_notification_status_CONSIDERED: 'Lu',
  overview_transaction_notification_status_NOT_CONSIDERED: 'Non lu',
  overview_transaction_notification_status_EXPIRED: 'Expiré',
  overview_transaction_notification_status_TO_CLOSE_SENT: 'A lire',
  overview_transaction_notification_status_CLOSED_SEND: 'Fermée',

  // Opposition
  breadcrumb_new_opposition: 'Former une demande d\'opposition',

  // Opposition
  breadcrumb_new_nullity: 'Former une demande de nullité',

  // Déchéance
  breadcrumb_new_revocation: 'Former une demande de déchéance',

  // FRMI
  breadcrumb_new_frmi: 'Déposer et gérer des marques étendues à l\'international',

  // Renouvellement
  breadcrumb_new_renewal: 'Renouveler sa marque',

  // Relevé de déchéance
  breadcrumb_new_revocation_statement: 'Former un relevé de déchéance',

  // Etape intervenants
  opposition_contributors_label: 'Intervenants',

  // Opposant
  contributors_opponent_label: 'Opposant',
  contributor_opponents: 'Opposant(s)',
  contributor_opponents_subtitle: 'Il peut y avoir un ou plusieurs opposants. Il s\'agit du ou des demandeur de la procédure d\'opposition.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_opponent_required: 'Veuillez renseigner au moins un opposant',
  contributor_add_opponents: 'Ajouter un opposant',
  contributor_save_opponents: 'Enregistrer l\'opposant',

  // Etape Demande d'enregistrement contestée
  opposition_registration_request_contested_label: 'Demande d\'enregistrement contestée',
  nullity_registration_request_contested_label: 'Marque contestée',
  revocation_registration_request_contested_label: 'Marque contestée',

  registration_request_contested_revocation_label: 'Marque contestée',
  registration_request_contested_title: 'Identification de la demande d\'enregistrement contestée',
  registration_request_contested_revocation_title: 'Identification de la marque contestée',
  registration_request_contested_description: 'Cette rubrique vous permet d’identifier la demande d’enregistrement contestée à laquelle vous vous opposez. Vous devez indiquer si cette dernière est une marque française ou une marque internationale ayant un effet en France. Vous ne pouvez vous opposer qu\'à une seule marque par acte d\'opposition.',
  nullity_registration_request_contested_revocation_description: 'Cette rubrique vous permet d\'identifier la marque pour laquelle vous demandez la nullité. Vous devez indiquer si cette dernière est une marque française ou une marque internationale ayant un effet en France. Vous ne pouvez contester qu’une seule marque par demande.',
  revocation_registration_request_contested_revocation_description: 'Cette rubrique vous permet d\'identifier la marque pour laquelle vous demandez la déchéance. Vous devez indiquer si cette dernière est une marque française ou une marque internationale ayant un effet en France. Vous ne pouvez contester qu\'une seule marque par demande.',

  // Edition de l'identification de la demande
  request_identification_title_edition: 'Identification de la marque contestée',
  request_identification_subtitle: 'Attention : le numéro d\'une demande d\'enregistrement française doit comporter 7 chiffres et débuter par 4.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  request_identification_nd_subtitle: 'Attention : le numéro d\'une marque française doit comporter 7 chiffres. Son millésime ne doit pas être renseigné',
  request_identification_numnat: 'Numéro national',
  request_identification_numnat_international: 'Numéro d\'enregistrement international',
  request_identification_contested_brand_name: 'Désignation de la marque contestée',
  request_identification_contested_brand_name_international: 'Libellé de la marque contestée',
  request_identification_deposit_date: 'Date de dépôt',
  request_identification_brand_type_label: 'Type de la marque contestée',
  request_identification_date_international: 'Date d\'enregistrement international',
  request_identification_add_international_extension: 'Ajouter une extension internationale',
  request_identification_bopi_number: 'Numéro de publication BOPI',
  request_identification_ompi_number: 'Numéro de publication gazette OMPI',
  request_identification_registration_date: 'Date d\'enregistrement',
  request_identification_grant_protection_date_overview: 'Date d\'octroi de la protection',
  verify_numnat_error: 'Le numéro que vous avez renseigné n’a pas été trouvé',
  modal_data_record_title: 'Informations de la marque',

  // Récapitulatif de la demande d'enregistrement contestée
  overview_brand_type: 'Marque',
  overview_brand_type_international: 'internationale',
  overview_brand_type_french: 'française',
  overview_priority_claimed: 'Priorité revendiquée',
  opposition_overview_total_product_and_services: 'La demande d\'opposition porte sur l\'intégralité des produits et services.',
  nullity_overview_total_product_and_services: 'La demande en nullité porte sur l\'intégralité des produits et services.',
  revocation_overview_total_product_and_services: 'La demande en déchéance est formée contre la totalité de la marque contestée.',

  // Tableaux de records
  records_numnat: 'Numnat',
  records_origin: 'Origine',
  records_deposit_date: 'Date de dépôt',
  records_brand_model: 'Modèle de marque',
  records_classes: 'Classes',
  records_holders: 'Titulaires',

  // Produits et services de la demande d'enregistrement
  opposition_contested_registration_products_and_services_title: 'Produits et services de la demande d’enregistrement contestée pour lesquels l’opposition est formée',
  opposition_overview_contested_registration_products_and_services_title: 'Produits et services de la demande d’enregistrement contestée pour lesquels l’opposition est formée',
  nullity_contested_registration_products_and_services_title: 'Produits et services de la marque contestée pour lesquels la demande en nullité est formée',
  nullity_overview_contested_registration_products_and_services_title: 'La demande en nullité est formée contre une partie de la marque contestée',
  revocation_contested_registration_products_and_services_title: 'Produits et services de la marque contestée pour lesquels la demande en déchéance est formée',
  revocation_overview_contested_registration_products_and_services_title: 'La demande en déchéance est formée contre une partie de la marque contestée.',
  opposition_contested_registration_products_and_services_subtitle: 'Cette rubrique vous permet de saisir des Produits et Services de la demande d’enregistrement contestée pour lesquels l’opposition est formée.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  nullity_contested_registration_products_and_services_subtitle: 'Cette rubrique vous permet de saisir des Produits et Services de la marque contestée pour lesquels la demande en nullité est formée.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  revocation_contested_registration_products_and_services_subtitle: 'Cette rubrique vous permet de saisir des Produits et Services de la marque contestée pour lesquels la demande en déchéance est formée.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contested_registration_products_and_services_add_ps: 'Ajouter une classe',
  contested_registration_products_and_services_less: 'Voir moins',

  // Produits et service d'un fondement
  foundation_products_and_services_title: 'Produits et services de la marque antérieure servant de base à l\'opposition',
  foundation_products_and_services_subtitle: 'Cette rubrique vous permet de saisir les Produits et Services de la marque antérieure servant de base à l\'opposition.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',

  // Etape Fondement(s) de l'opposition
  opposition_foundations_label: 'Fondement(s) de l\'opposition',
  opposition_foundations_title: 'Fondement(s) de l\'opposition',
  opposition_foundations_description: 'Cette rubrique vous permet d\'identifier le ou les droits antérieurs servant de base à l\'opposition. A minima, un droit antérieur doit être identifié. Pour en savoir plus, consultez consulter l\'aide en ligne.',

  // Etape Exposé des moyens
  statement_of_means_label: 'Exposé des moyens',
  statement_of_means_title: 'Exposé des moyens',
  opposition_statement_of_means_description: 'Cette rubrique vous permet de joindre votre exposé des moyens. L’exposé des moyens est un argumentaire permettant d’expliquer en quoi la demande d’enregistrement contestée porte atteinte à votre ou à vos droit(s) antérieur(s).',
  nullity_statement_of_means_description: 'Cette rubrique vous permet de joindre votre exposé des moyens. L\'exposé des moyens est un argumentaire par lequel le demandeur développe le ou les motif(s) invoqué(s).',
  revocation_statement_of_means_description: 'Cette rubrique vous permet de joindre votre exposé des moyens. L\'exposé des moyens est un argumentaire par lequel le demandeur développe le ou les motif(s) invoqué(s).',

  statement_of_means_help: 'Un seul document au format PDF peut être joint.',
  statement_of_means_file: 'Fichier',
  statement_of_means_validate: 'Valider',
  statement_of_means_file_size_error: 'Le fichier ne doit pas dépasser les 100 Mo',
  statement_of_means_file_size_success: 'Le fichier a bien été envoyé',

  // Etape Annexes
  annexes_label: 'Annexes',
  annexes_title: 'Annexes',
  annexes_description: 'Pour en savoir plus, consultez consulter l\'aide en ligne.',

  // Etape documents
  documents_label: 'Documents',

  // Opposition
  foundations_form_title: 'Fondement(s) de l’opposition',
  foundations_form_subtitle: 'Cette rubrique vous permet d\'identifier le ou les droits antérieurs servant de base à l\'opposition. A minima, un droit antérieur doit être identifié. Pour en savoir plus, consultez consulter l\'aide en ligne.',
  foundation_list_number: 'Numéro',
  foundation_list_brand_name: 'Nom',

  foundation_type_brand_label: 'Marque',
  foundation_type_renowned_label: 'Marque de renommée',
  foundation_type_collectivity_label: 'Atteinte au nom, à l’image ou à la renommée d’une Collectivité territoriale ou d\'un EPCI',
  foundation_type_institution_label: 'Atteinte au nom d’une entité publique',
  foundation_type_igcraft_label: 'Indication géographique (au sens de l\'article L. 722-1 b)',
  foundation_type_aoig_label: 'Indication géographique (au sens de l\'article L. 722-1 a et c)',
  foundation_type_unauthorized_label: 'Dépôt non autorisé par l\'agent ou le représentant du titulaire de la marque',
  foundation_type_denomination_label: 'Dénomination ou raison sociale',
  foundation_type_trade_name_label: 'Nom commercial ou enseigne',
  foundation_type_domain_name_label: 'Nom de domaine',

  opposition_foundation_brand_origin_label: 'L\'opposant est titulaire d\'une',
  nullity_foundation_brand_origin_label: 'Le demandeur est titulaire d\'une',
  foundation_brand_origin_placeholder: 'Choisissez l\'origine de la marque',
  foundation_brand_name_label: 'Nom de la marque',
  foundation_sign_name_label: 'Désignation du signe',
  foundation_igcraft_name_label: 'Nom de l\'indication géographique',
  foundation_brand_number_label: 'N° de dépôt',
  foundation_brand_created_date_label: 'Date de dépôt',
  foundation_international_brand_number_label: 'N° d\'enregistrement',
  foundation_international_brand_created_date_label: 'Date d\'enregistrement',
  foundation_brand_international_register_date_label_frmi: 'Date d\'inscription au registre international de l\'extension à la France de l\'enregistrement international (le cas échéant)',
  foundation_brand_international_register_date_label_uemi: 'Date d\'inscription au registre international de l\'extension à l\'Union européenne de l\'enregistrement international (le cas échéant)',
  foundation_brand_international_register_date_label: 'Date d\'inscription au registre international de l\'extension à la France ou à l\'Union européenne de l\'enregistrement international (le cas échéant)',
  opposition_foundation_opponent_quality_label: 'Qualité de l\'opposant',
  nullity_foundation_opponent_quality_label: 'Qualité du demandeur',
  opposition_foundation_opponent_quality_placeholder: 'Choisissez la qualité de l\'opposant',
  nullity_foundation_opponent_quality_placeholder: 'Choisissez la qualité du demandeur',
  opposition_foundation_opponent_right_file_label: 'Document justifiant que l\'opposant est autorisé à exercer les droits découlant du droit antérieur',
  nullity_foundation_opponent_right_file_label: 'Document justifiant que le demandeur est autorisé à exercer les droits découlant du droit antérieur',

  foundation_brand_claimed_priority_label: 'Priorité revendiquée (le cas échéant)',
  foundation_brand_claimed_priority_country_label: 'Pays',
  foundation_brand_claimed_priority_country_placeholder: 'Choisissez un pays',
  foundation_brand_claimed_priority_date_label: 'Date',
  foundation_brand_claimed_renewal_request_date_label: 'Date de la demande de renouvellement (le cas échéant)',
  foundation_brand_claimed_renewal_publication_date_label: 'Date de publication du renouvellement (le cas échéant)',
  foundation_brand_object_label: 'La marque antérieure a-t-elle fait l’objet d’une',
  foundation_brand_object_partial_assignment_label: 'Cession partielle ?',
  foundation_brand_object_limitation_label: 'Limitation ?',
  foundation_brand_object_renunciation_label: 'Renonciation ?',
  foundation_brand_copy_label: 'Copie de la marque antérieure',
  foundation_sign_file_label: 'Représentation du signe antérieur',
  foundation_right_file_label: 'Document(s) justifiant de l\'existence et de la portée du droit antérieur',
  foundation_proof_file_label: 'Document propre à justifier en quoi ce signe identifie la collectivité territoriale ou l\'EPCI',
  foundation_opponent_licence_fee_label: 'Si nécessaire, un document (justificatif de la transmission ou droit de licence) peut être joint',
  foundation_products_label: 'Identifiez si les produits et service sont',
  foundation_similar_label: 'Similaires',
  foundation_identical_label: 'Identiques',
  foundation_sign_label: 'Identifiez si les signes sont',
  foundation_form_activity_label: 'Indiquez quelles sont les missions, attributions, ou domaines d\'intervention invoqués à l\'appui de l\'opposition',
  foundation_form_activity_label_short: 'Identifiez les missions/attributions',
  foundation_form_activity_label_igcraft: 'Indiquez quel est le produit bénéficiant de l\'indication géographique invoqué à l\'appui de l\'opposition',
  foundation_form_activity_label_aoig: 'Indiquez quel est le produit bénéficiant de l\'AO ou de l\'IG invoqué à l\'appui de l\'opposition',

  foundation_bopi_label: 'BOPI de la décision d\'homologation',
  foundation_entity_name_label: 'Désignation de l\'entité publique',
  foundation_entity_igcraft_name_label: 'Nom de l\'indication géographique',
  foundation_entity_igcraft_number_label: 'N° de la demande ou N° national',

  foundation_bopi_number_label: 'Numéro',
  foundation_bopi_date_label: 'Date',
  foundation_homologation_file_label: 'Document d\'homologation',

  foundation_form_brand_part_label: 'Identifiez la marque antérieure',
  opposition_foundation_form_opponent_part_label: 'Opposant',
  nullity_foundation_form_opponent_part_label: 'Demandeur',
  foundation_form_products_part_label: 'Produits et Services',
  foundation_form_sign_part_label: 'Signes :',
  foundation_form_collectivity_sign_part_label: 'Indiquez le signe identifiant la collectivité territoriale ou l\'EPCI',
  foundation_form_activity_part_label: 'Identifiez les missions, attributions ou domaines d\'interventions',
  foundation_form_activity_part_label_short: 'Missions et attributions',

  foundation_aoig_name_label: 'Nom de l\'AO ou de l\'IG',
  foundation_aoig_type_label: 'Type d\'AO ou d\'IG',
  foundation_aoig_protecting_act_label: 'Acte(s) donnant droit à la protection',
  foundation_form_activities_part_label: 'Activités',
  foundation_form_domain_name_activity_label: 'Indiquez quelles sont les activités qui servent de base à l\'opposition',
  opposition_foundation_trade_name_type_label: 'L\'opposant est titulaire d\'un(e)',
  nullity_foundation_trade_name_type_label: 'Le demandeur est titulaire d\'un(e)',
  foundation_denomination_name_label: 'Désignation de la dénomination ou raison sociale',
  foundation_denomination_number_label: 'Numéro d\'immatriculation',
  foundation_denomination_matriculation_date_label: 'Date d\'immatriculation',
  foundation_form_activity_label_denomination: 'Indiquez quelles sont les activités qui servent de base à l\'opposition',
  foundation_territory_label: 'Territoire',
  foundation_unauthorized_register_date_label: 'Date d\'inscription au registre international de l\'extension de l\'enregistrement international (le cas échéant)',
  foundation_subscription_number_label: 'N° d\'inscription',
  foundation_subscription_date_label: 'Date',

  motives_relative_expand_title: 'Motif(s) relatif(s)',
  motives_nullity_expand_title: 'Motif(s) absolu(s)',
  motives_revocation_expand_title: 'Motif(s)',
  motives_title: 'Motif(s) de la demande',
  motives_nullity_form_subtitle: 'Cette rubrique permet d\'identifier le ou les motif(s) servant de base à la demande en nullité',
  motives_revocation_form_subtitle: 'Cette rubrique vous permet d’identifier le ou les motif(s) servant de base à la demande en déchéance',

  // Opposition fondation
  opposition_foundations_add_button: 'Ajouter un fondement',
  nullity_foundations_add_button: 'Ajouter un motif',

  opposition_foundation_list_name: 'Fondement',
  nullity_foundation_list_name: 'Motif',

  opposition_fondation_type_label: 'Création d’un fondement de ...',
  nullity_fondation_type_label: 'Création d’un motif de ...',

  opposition_fondation_type_placeholder: 'Choisissez le type de fondement',
  nullity_fondation_type_placeholder: 'Choisissez le type de motif',

  opposition_foundation_trade_name_type_placeholder: 'Choisissez le type de fondement',
  nullity_foundation_trade_name_type_placeholder: 'Choisissez le type de motif',

  opposition_foundations_missing: 'Au moins un fondement est requis',
  opposition_foundations_required: 'Veuillez saisir au moins un fondement',
  nullity_foundations_missing: 'Au moins un motif est requis',

  opposition_foundation_delete_modal_title: 'Supprimer un fondement',
  nullity_foundation_delete_modal_title: 'Supprimer un motif',

  opposition_foundation_delete_modal_text: 'Êtes-vous sûr(e) de vouloir supprimer ce fondement ?',
  nullity_foundation_delete_modal_text: 'Êtes-vous sûr(e) de vouloir supprimer ce motif ?',

  foundations_missing: 'Au moins un fondement est requis',
  foundation_delete_modal_title: 'Supprimer un fondement',
  foundation_delete_modal_text: 'Êtes-vous sûr(e) de vouloir supprimer ce fondement ?',

  // Produit et Service d'une division
  division_parent_product_version_title: 'Marque divisée (mère)',
  division_product_version_title: 'Marque divisionnaire (fille)',
  division_add_product_class_button_label: 'Ajouter une classe',
  division_parent_version_error: 'Au moins une classe doit appartenir à la marque divisée (mère)',
  division_products_text_placeholder: 'Les classes restées vides seront supprimées de la marque divisionnaire (fille)',
  parent_product_and_services_missing: 'Au moins un libellé et une classe doivent être conservés pour la marque divisée (mère)',
  child_product_and_services_missing: 'Au moins un libellé et une classe doivent être indiqués sur la marque divisionnaire (fille)',
  popin_product_and_services_detail: 'Êtes-vous sur de vouloir supprimer cette classe ?',
  popin_product_and_services_title: 'Suppression d\'une classe',

  // Option d'une division
  options_international_extension_title: 'Option : extension internationale',
  international_extension_title: 'Extensions internationales',
  options_international_extensions_title: 'Option : extensions internationales',
  options_international_extension_text_warning: '{numNat} est enregistrée sous le numéro {numNatInternational} le {arrivalDateInternational}. En application de la règle 22 ou 23 du protocole, l’INPI va déclarer la {nature} à l’OMPI lorsque votre demande sera validée.',

  // Suite
  deposit_suite_checkbox_label: 'Cette demande fait partie d\'une suite de divisions',
  division_suite_checkbox_label: 'Cette demande fait partie d\'une suite de divisions',
  inscription_suite_checkbox_label: 'Cette demande fait partie d\'une suite d\'inscription',
  suite_input_label: 'Veuillez indiquer sa position',
  division_suite_list_title: 'Divisions précédentes :',
  inscription_suite_list_title: 'Inscriptions précédentes :',
  suite_position_required: 'La position doit être renseignée',
  suite_position_still_exists: 'Cette position a déjà été choisie',

  // Etapes client / workflow
  workflow_wait_notification: 'En attente de notification',
  workflow_under_investigation: 'En cours d\'instruction',
  workflow_wait_decision: 'En attente de décision',
  workflow_suspended: 'Suspendu',
  workflow_completed: 'Dossier terminé',
  record_create_division: 'Diviser la marque',
  record_create_inscription: 'Faire une inscription',
  record_create_frmi_extension: 'Etendre à l\'international',
  record_create_frmi_operation: 'Demander une opération postérieure',
  record_create_renewal: 'Renouveler sa marque',
  record_create_revocation_statement_request: 'Relevé de déchéance',
  record_create_official_documents_request: 'Demande de documents officiels',

  // Retrait produits et services
  product_and_services_validate_total_withdrawal_button: 'Retrait total',
  product_and_services_validate_partial_withdrawal_button: 'Retrait partiel',
  product_and_services_validate_partial_withdrawal: 'Demande de retrait partiel',
  product_and_services_validate_regularization_button: 'Enregistrer les modifications',
  product_and_services_validate_regularization_title: 'Régularisation de produits et services',
  product_and_services_revocation_title: 'Retrait de produits et services',
  product_and_services_total_revocation_text: 'Attention, cette action est définitive, une fois validé, le retrait total implique la suppression totale de votre marque.',
  product_and_services_partial_revocation_text: 'Attention, le retrait constitue une renonciation définitive aux produits et services supprimés.',
  product_and_services_added_modal_title: 'Ajout de classe(s)',
  product_and_services_add_button: 'Ajouter une classe',
  product_and_services_added_modal_validate_label: 'Valider l\'ajout des produits et services',
  product_and_services_revocation_button_label: 'Retirer les produits et services',
  withdraw_overview_withdraw_version_label: 'Votre demande de retrait',
  withdraw_overview_inpi_version_label: 'Dernière version validée par l\'INPI',

  // Page d'aide
  help_breadcrumb_title: 'Page d\'aide',

  // Etape de rectification
  correction_title: 'Rectification',
  correction_description: 'Nature de la rectification à inscrire',
  correction_nature_code_block_title: 'Nature de la rectification',
  correction_statement_block_title: 'Enoncé de la rectification',
  correction_documents_block_title: 'Pièce(s) produite(s)',
  correction_replaced_text_label: 'Remplacer ce texte',
  correction_replacement_text_label: 'Par ce texte',
  correction_comment_label: 'Commentaire',
  correction_form_replace_text_error: 'Veuillez renseigner au moins un des deux champs ci-dessus.',
  correction_form_missing_nature_codes: 'Veuillez choisir au moins un code',
  correction_form_too_much_nature_codes: 'Veuillez choisir 1 à 3 natures',
  correction_form_rectify_content_required: 'Veuillez choisir au moins un type',
  inscription_mandatory_power_files_label: 'S\'il y a lieu, le pouvoir du mandataire ou copie du pouvoir permanent (sauf conseil en propriété industrielle ou avocat)',
  inscription_other_files_label: 'Autre(s) pièce(s) (précisez la nature de celle(s)-ci)',
  correction_rectify_block_title: 'Pièce(s) à rectifier',
  correction_rectify_block_label: 'Cocher la ou les cases désignant la nature des pièces à rectifier',
  correction_rectify_part_is_deposit_label: 'Pièce(s) du dépôt',
  correction_rectify_part_is_act_label: 'Acte inscrit au registre',
  correction_rectify_part_act_inscription_number_label: 'Numéro d\'inscription',
  correction_rectify_part_act_renewal_inscription_number_label: 'Numéro de la déclaration',
  correction_rectify_part_act_inscription_number_placeholder: 'Saisissez le numéro d\'inscription',
  correction_rectify_part_act_inscription_date_label: 'Date d\'inscription',
  correction_rectify_part_act_renewal_inscription_date_label: 'Date de la déclaration',
  correction_rectify_part_is_renewal_label: 'Déclaration de renouvellement d\'une marque',
  correction_rectify_part_renewal_number_label: 'Numéro de gestion du renouvellement',
  correction_rectify_part_renewal_number_placeholder: 'Saisissez le numéro de gestion du renouvellement',
  correction_rectify_part_division_number_label: 'Numéro de la marque divisionnaire',
  correction_rectify_part_division_number_placeholder: 'Saisissez le numéro de la division',
  correction_rectify_part_division_date_label: 'Date de la division',
  correction_rectify_part_renewal_date_label: 'Date de déclaration ',
  correction_rectify_part_is_division_label: 'Déclaration de division de marque enregistrée',
  correction_documents_communicability_error: 'Au moins un document au statut "Communicable" est obligatoire',
  correction_documents_communicability_act_error: 'Au moins un document au statut "Communicable" autre que pouvoir du mandataire ou copie du pouvoir permanent est obligatoire',
  documents_communicability_warning_text: 'Les documents au statut "Non-communicable" ne pourront pas être inscrits au registre.',
  correction_documents_communicability_warning_text: 'Les documents "Non-communicable" ne pourront pas être inscrits au registre.',

  // Formulaire renonciation
  renunciation_form_title: 'Renonciation',
  renunciation_title: 'Renonciation',
  renunciation_type_block_label: 'Type de renonciation',
  renunciation_title_block_label: 'Titre concerné par la renonciation',
  renunciation_description: 'Renseignez le titre concerné par la renonciation',
  inscription_contested_registration_products_and_services_title: 'Classes et produits et/ou services auxquels s\'appliquera la marque après la renonciation',
  inscription_contested_registration_products_and_services_subtitle: 'Cette rubrique vous permet de saisir les classes et produits et/ou services auxquels s\'appliquera la marque après la renonciation.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  inscription_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services auxquels s\'appliquera la marque après la renonciation.',
  inscription_products_and_services_description: 'Énumération des Produits et/ou Services',
  renunciation_form_is_with_drawable_message: 'Le titre renseigné n\'est pas encore enregistré.{linebreak}Vous ne pouvez donc pas effectuer une renonciation sur celui-ci.{linebreak}Néanmoins, vous pouvez effectuer un retrait en cliquant sur le lien ci-dessous:{linebreak} <a>Vers le retrait</a>',
  renunciation_form_is_with_drawable_attachment_message: 'Le titre renseigné n\'est pas encore enregistré, et vous n\'y êtes pas rattaché.{linebreak}Vous ne pouvez donc pas effectuer une renonciation sur celui-ci. {linebreak}Néanmoins, vous pouvez effectuer une demande de rattachement en cliquant sur le lien ci-dessous:{linebreak} <a>Vers la demande de rattachement</a>',
  renunciation_form_is_with_drawable_not_found_message: 'Le titre renseigné n\'est pas encore enregistré, et n\'existe pas dans notre base. Merci de contacter l\'INPI.',
  overview_renunciation_type_label: 'Type de renonciation',
  overview_inscription_renunciation_title: 'Renonciation',
  renunciation_options_title: 'Options',
  renunciation_options_description: 'Renseignez les différentes extensions internationales ou inscriptions antérieures / simultanées.',
  // Inscription etape d'acte
  act_title: 'Acte',
  act_form_description: 'Nature de l\'acte à inscrire',
  act_title_form_is_appeal_revocation_label: 'Cette demande d\'inscription accompagne-t-elle un recours en restauration ou une demande de relevé de déchéance ?',
  act_title_form_is_renewal_label: 'Cette demande d\'inscription accompagne-t-elle une demande de renouvellement ?',
  inscription_act_property_change_files_label: 'Copie ou extrait de l\'acte constatant la modification de la propriété ou de la jouissance',
  inscription_act_material_impossibility_files_label: 'Le cas échéant, justification de l\'impossibilité matérielle de produire une copie de l\'acte dont résulte l\'opération',
  act_title_nature_code_block_title: 'Sélectionner la nature de l\'opération',

  // Inscription: Etape concours
  contest_title: 'Concours',
  contest_description: 'Description du concours',
  contest_form_type_label: 'Type de récompense',
  contest_form_type_placeholder: 'Sélectionner le type de récompense',
  contest_form_title_label: 'Titre',
  contest_form_organizer_label: 'Organisateur',
  contest_form_place_label: 'Lieu du concours (Ville)',
  contest_form_start_date_label: 'Date de début du concours',
  contest_form_are_same_date_label: 'La date de fin est-elle la même que la date de début ?',
  contest_form_end_date_label: 'Date de fin du concours',
  contest_form_reward_kind_label: 'Récompenses industrielles, saisir la nature de la récompense reçue',
  contest_form_end_date_error: 'La date de fin doit être ultérieure à la date de début',
  contest_inscriptions_title: 'Inscriptions antérieures',
  renewal_options_files_title: 'Documents',
  inscription_ranking_files_label: 'Le palmarès du concours ou un exemplaire du diplôme ou du certificat',
  documents_ranking_missing: 'Au moins un document palmarès du concours ou exemplaire du diplôme / certificat doit être téléversé',
  overview_contest_reward_industrial_label: 'Récompense industrielle',
  overview_contest_reward_ranking_label: 'Palmarès',
  contest_form_date_label: 'Date du concours',
  contest_title_label: 'Titre',
  contest_inscriptions_label: 'Inscriptions antérieures',

  // Inscription relative au mandataire
  mandatary_old_agent_label: 'Ancien mandataire',
  mandatary_new_agent_label: 'Nouveau mandataire',
  mandatary_new_agent_cm_label: 'Mandataire à ajouter',
  mandatary_old_agent_mm_label: 'Données à modifier',
  mandatary_new_agent_mm_label: 'Données à jour',
  mandatary_old_agent_rm_label: 'Mandataire à supprimer',
  mandatary_nature_code_block_title: 'Nature de la demande',
  mandatary_block_title: 'Enoncé de la rectification',
  mandatary_title: 'Nature de l\'inscription',

  // Rattachement des mandataires
  inscription_agent_attachment_select_all: 'Tout sélectionner',

  // Récapitulatif
  overview_nature_codes_label: 'Nature de la demande',
  overview_statement_label: 'Enoncé',
  overview_correction_replaced_text_label: 'Remplacer ce texte',
  overview_correction_replacement_text_label: 'Par ce texte',
  overview_correction_comment_label: 'Commentaire',
  overview_attachments_label: 'Transactions auxquelles vous souhaitez vous rattacher',

  // Tableau Inscription
  sub_procedure_inscription_type_correction: 'Inscription de rectification',
  sub_procedure_inscription_type_agent: 'Inscription relative au mandataire',
  sub_procedure_inscription_type_act_title: 'Inscription d\'un acte affectant la propriété ou la jouissance d\'un titre',
  sub_procedure_inscription_type_reward: 'Inscription d’une récompense ou d’un palmarès',
  sub_procedure_inscription_type_renunciation: 'Renonciation',

  // Type FRMI
  frmi_type_extension_title: 'Demande d\'extension à l\'international ',

  // Opérations postérieures d'une demande de marque internationale
  frmi_type_post_designation_register_title: 'Désignation postérieure à l\'enregistrement International (MM4) ',
  frmi_type_holder_change_title: 'Demande d\'inscription de changement de titulaire (MM5) ',
  frmi_type_products_limit_title: 'Limitation de la liste des produits et services (MM6) ',
  frmi_type_renunciation_title: 'Renonciation (MM7) ',
  frmi_type_radiation_title: 'Radiation (MM8) ',
  frmi_type_holder_edition_title: 'Modification du nom ou de l’adresse du titulaire, ou de sa forme juridique (MM9) ',
  frmi_type_agent_edition_title: 'Modification du nom ou de l’adresse du mandataire (MM10) ',
  frmi_type_renew_title: 'Renouvellement de l\'enregistrement international (MM11) ',
  frmi_type_agent_constitute_title: 'Constitution d’un mandataire (MM12) ',
  frmi_type_licence_inscription_title: 'Licence (MM13) ',
  frmi_type_licence_edition_title: 'Modification de l’inscription d’une Licence (MM14) ',
  frmi_type_licence_radiation_title: 'Radiation d’une Licence (MM15) ',
  frmi_type_post_designation_conversion_title: 'Désignation postérieure issue d’une conversion (MM16) ',
  frmi_type_holder_right_restriction_title: 'Restriction du droit du titulaire (MM19) ',
  frmi_type_holder_right_delete_title: 'Main Levée d’une restriction du droit du titulaire (MM19) ',
  frmi_type_continuation_request_title: 'Requête en poursuite de la procédure (MM20) ',
  frmi_type_inscription_correction_title: 'Rectification d’une inscription (MM21) ',
  frmi_type_division_international_title: 'Division d’un enregistrement International (MM22) ',
  frmi_type_division_national_title: 'Division d’un enregistrement National (MM22- bis) ',
  frmi_type_fusion_from_holder_edition_title: 'Fusion d\'enregistrements internationaux (MM23) issus de l\'inscription d\'un changement partiel de titulaire ',
  frmi_type_fusion_from_division_inscription_title: 'Fusion d’un enregistrement international issu de l’inscription d’une division(MM24) ',
  frmi_type_effect_stop_title: 'Cessation des effets de l’enregistrement international dû à la cessation des effets de la demande de base (R22) ',
  frmi_type_nullification_title: 'Invalidation (IJ) ',
  frmi_type_national_replacement_title: 'Remplacement d’un enregistrement national ou régional par un enregistrement international ',
  frmi_holder_change_extension_title: 'Etendue du changement de titulaire',
  frmi_extension_overview_title: 'Type de changement',
  frmi_holder_change_products_extension_type_label: 'Type de changement',

  frmi_radiation_extension_title: 'Etendue de la radiation',
  frmi_type_radiation_products_extension_title: 'Etendue de la radiation',
  frmi_type_radiation_products_extension_type_label: 'Type de radiation',
  frmi_type_radiation_products_extension_type_total_label: 'Radiation totale',
  frmi_type_radiation_products_extension_type_partial_label: 'Radiation partielle',

  frmi_nullification_extension_title: 'Etendue de l\'invalidation',
  frmi_type_nullification_products_extension_title: 'Etendue de l\'invalidation',
  frmi_type_nullification_products_extension_type_label: 'Type d\'invalidation',
  frmi_type_nullification_products_extension_type_total_label: 'Invalidation totale',
  frmi_type_nullification_products_extension_type_partial_label: 'Invalidation partielle',
  frmi_type_nullification_products_extension_type_partial_concerning_products_label: 'Invalidation partielle qui concerne les produits et services',
  frmi_type_nullification_products_extension_type_partial_not_concerning_products_label: 'Invalidation partielle qui ne concerne pas les produits et services',

  frmi_replacement_extension_title: 'Etendue du remplacement',
  frmi_type_national_replacement_products_extension_title: 'Etendue du remplacement',
  frmi_type_national_replacement_products_extension_type_label: 'Type de remplacement',
  frmi_type_national_replacement_products_extension_type_partial_label: 'Remplacement partiel',
  frmi_type_national_replacement_products_extension_type_total_label: 'Remplacement total',

  frmi_licence_inscription_change_extension_title: 'Etendue de la licence',
  frmi_type_licence_inscription_products_extension_title: 'Etendue de la licence',
  frmi_type_licence_inscription_products_extension_type_label: 'Etendue de la licence sur les produits et services',
  frmi_type_licence_inscription_countries_extension_type_label: 'Etendue de la licence sur les pays',
  frmi_type_licence_inscription_products_extension_type_partial_label: 'Licence partielle',
  frmi_type_licence_inscription_products_extension_type_total_label: 'Licence totale',
  frmi_type_licence_inscription_countries_extension_type_partial_label: 'Licence partielle',
  frmi_type_licence_inscription_countries_extension_type_total_label: 'Licence totale',

  frmi_effect_stop_extension_title: 'Etendue de la cessation',
  frmi_type_effect_stop_products_extension_title: 'Etendue de la cessation',
  frmi_type_effect_stop_products_extension_type_label: 'Type de radiation',
  frmi_type_effect_stop_products_extension_type_partial_label: 'Radiation partielle',
  frmi_type_effect_stop_products_extension_type_total_label: 'Radiation totale',
  frmi_type_effect_stop_products_extension_sub_type_without_impact_label: 'Les faits et décisions indiqués n\'ont pas d\'incidence à l\'égard des produits et services figurant dans l\'enregistrement international',
  frmi_type_effect_stop_products_extension_sub_type_with_impact_label: 'Les faits et décisions indiqués ont une incidence uniquement à l\'égard des produits et services figurant dans l\'enregistrement international',

  frmi_renew_extension_title: 'Etendue du renouvellement',
  frmi_type_renew_products_extension_title: 'Etendue du renouvellement',
  frmi_type_renew_countries_extension_type_label: 'Type de renouvellement',
  frmi_type_renew_countries_extension_type_partial_label: 'Renouvellement partiel',
  frmi_type_renew_countries_extension_type_total_label: 'Renouvellement total',

  frmi_holder_right_restriction_extension_title: 'Etendue de la restriction',
  frmi_type_holder_right_restriction_products_extension_title: 'Etendue de la restriction',
  frmi_type_holder_right_restriction_countries_extension_type_label: 'Type de restriction',
  frmi_type_holder_right_restriction_countries_extension_type_partial_label: 'Restriction partielle',
  frmi_type_holder_right_restriction_countries_extension_type_total_label: 'Restriction totale',
  frmi_regional_limit_label: 'Si la licence fait l\'objet d\'une restriction territoriale dans l\'une des parties contractantes, veuillez énumérer chaque partie contractante désignée concernée et veuillez préciser la partie du territoire concerné',
  frmi_regional_limit_oveview_label: 'Restriction territoriale',

  frmi_extension_title: 'Etendue du changement de titulaire',

  // Formulaire des relevés de déchéance
  revocation_statement_brand_description: 'Cette rubrique vous permet d\'identifier la ou les marque(s) concernée(s) par la demande de relevé de déchéance',
  deposit_type_revocation_statement_national_number_label: 'Numéro de dépôt du titre',
  deposit_type_revocation_statement_registered_description: 'Cette marque correspond-elle au titre à ajouter ? Si oui, ajoutez le. Sinon, veuillez modifier le numéro du titre puis cliquez sur "Vérifier"',
  deposit_type_revocation_statement_deposit_date: 'Date de dépôt',
  revocation_statement_titles: 'Sélection des titres',
  contributor_form_subtitle_REVOCATION_STATEMENT: 'Cette rubrique vous permet d\'identifier les différents intervenants de votre demande. A minima les onglets demandeurs(s), destinataire et signataire devront être remplis.',
  contributor_recipient_subtitle_revocation_statement: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure.{linebreak}Pour en savoir plus, consulter l\'aide en ligne accessible dans la rubrique « ? Aide » du Portail Marques',
  contributor_signatory_subtitle_revocation_statement: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer la demande.',
  revocation_statement_request_title: 'Demande de relevé de déchéance',
  revocation_statement_has_pending_inscription_label: 'Inscription au RNM en cours',
  revocation_statement_inscription_number_label: 'N° d\'ordre de l\'inscription',
  revocation_statement_request_text_title: 'Veuillez saisir votre demande OU ajouter un document',
  revocation_statement_request_text_label: 'Saisir la demande de relevé de déchéance',
  revocation_statement_request_file_label: 'Dépôt d\'un document',
  revocation_statement_request_act_files_title: 'L\'acte à accomplir',
  revocation_statement_request_supporting_files_title: 'Pièces justificatives',
  revocation_statement_document_block_file_title: 'Fichier',
  revocation_statement_document_block_type_title: 'Type de document',
  revocation_statement_document_block_communicability_title: 'Communicabilité',
  revocation_statement_document_type_placeholder: 'Veuillez choisir un type de document',
  revocation_statement_request_needed: 'Vous devez remplir l\'un des deux champs ci-dessus',
  payment_confirm_free_revocation_statement_asking_free_label: 'Je demande un relevé de déchéance gracieux',
  overview_revocation_statement_request_title: 'Demande de relevé de déchéance',
  revocation_statement_request_document_overview_label: 'Documents',
  revocation_statement_act_document_overview_label: 'Actes à accomplir',
  revocation_statement_supporting_document_overview_label: 'Pièces justificatives',
  revocation_statement_request_inscription_rnm_overview_label: 'N° d\'ordre de l\'inscription RNM en cours',
  revocation_statement_documents_count_overview_label: 'fichier(s) joint(s)',
  revocation_statement_request_text_overview_label: 'Demande',

  delete_transaction_impossible: 'Suppression impossible : un paiement est en cours de validation',

  // Type de Renouvellement
  renewal_type: 'Portée du renouvellement',
  renewal_type_total: 'Renouvellement intégral',
  renewal_type_partial: 'Renouvellement partiel',

  // Marques associées
  options_renewal_associated_brands_title: 'Marques associées',
  options_renewal_simultaneous_inscription_title: 'Inscription simultanée',
  options_renewal_simultaneous_inscriptions_label: 'Si vous avez effectué une demande d\'inscription simultanée (à la même date), cochez cette case',
  overview_renewal_associated_brands_title: 'Marques associées',
  overview_renewal_associated_brand_title: 'Numéro d\'enregistrement',
  overview_renewal_simultaneous_inscription_title: 'Inscription simultanée',
  overview_renewal_simultaneous_inscription_yes: 'Une demande d\'inscription simultanée a été effectuée',
  overview_renewal_simultaneous_inscription_no: 'Aucune demande d\'inscription simultanée n\'a été effectuée',

  // Dépôt de marque par XML
  drop_deposit_file: 'Glissez-déposez ou cliquez pour importer un dépôt de marque',
  deposit_file_modal_title: 'Dépôt de marque par fichier',
  transaction_import_deposit: 'Dépôt de marque par fichier',
  deposit_file_modal_error: 'Il n’a pas été possible de charger votre fichier de demande de marque car celui-ci ne respecte pas la norme NF X 50-276.',
  deposit_file_modal_success_with_error: 'Le fichier a bien été chargé mais contient certaines erreurs.',
  deposit_file_modal_success_with_warning: 'Le fichier a bien été chargé mais certaines informations sont manquantes et devront être complétées via le formulaire.',
  deposit_import_error: 'Le fichier uploadé contient des erreurs',
  deposit_import_success_with_error: 'Le dépôt a été pré-rempli avec des erreurs',
  deposit_import_success: 'Le dépôt a bien été pré-rempli',
  deposit_import_file_explain: 'Importer un dépôt de marque. Le fichier à charger doit impérativement être au format XML ou zip.',
  deposit_import_file_label: 'Fichier pour le dépôt de marque'

}

export default Message
