import {
  BRAND_ORIGIN_INTERNATIONAL,
  CheckboxField,
  Country,
  ErrorField,
  EventType,
  FieldStatus,
  FRMI_TYPE_EXTENSION,
  HelpBlock,
  ModalComponent,
  NumNatVerifier,
  PROCEDURE_FRMI,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_RENEWAL,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductClass,
  ProductClassVersion,
  ProductService,
  Record,
  SubmitButton,
  Title,
  Transaction,
  TransactionService,
  TYPE_DELAY_AFTER_GRACE,
  TYPE_DELAY_BEFORE_RENEWAL,
  TYPE_DELAY_GRACE
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import RecordService from '../../../../services/opposition/RecordService'
import ContentService from '../../../../services/content/ContentService'
import TitleForm from './TitleForm'
import TitleService from '../../../../services/inscription/TitleService'
import TitleRegisteredOverview from './TitleRegisteredOverview'
import FRMIService from '../../../../services/frmi/FRMIService'
import ContestedProductsAndServices from '../../../opposition/form/registrationRequest/ContestedProductsAndServices'
import RenewalService from '../../../../services/renewal/RenewalService'

interface TitleValidateContentProps {
    // titre actuel à modifier
    title?: Title,
    // fonction pour modifier le titre
    setTitle: (newTitle: Title) => void,
    // informations concernant les erreurs sur le formulaire
    fieldStatus?: FieldStatus,
    isEditingMode?: boolean,
    validate?: () => void,
    cancel?: () => void
    className?: string,
    onlyVerifier?: boolean,
    origin?: string[],
    transaction: Transaction,
    showProductsAndServices?: boolean,
    fromMadrid?: boolean,
    setIdRecordTmp?: (idRecord?: string) => void,
    editIndex?: number,
    showOverview?: boolean,
    canRequestRecord?: (record: Record) => boolean,
    showBrandCopyWarning?: boolean,
    setShowBrandCopyWarning?: (show: boolean) => void,
    renewalType?: boolean,
    renewalReclassify?: boolean,
    setRenewalType?: (value: boolean) => void,
    setRenewalReclassify?: (value: boolean) => void,
    showBrandLabel?: boolean
}

/**
 * Composant permettant de rechercher et d'ajouter un titre
 */
const TitleValidateContent: FC<TitleValidateContentProps> = ({
  title,
  setTitle,
  fieldStatus,
  isEditingMode = false,
  validate,
  cancel,
  className,
  onlyVerifier = false,
  origin = ['FR', 'WO'],
  transaction,
  showProductsAndServices = false,
  fromMadrid = false,
  setIdRecordTmp,
  editIndex,
  showOverview = false,
  canRequestRecord,
  showBrandCopyWarning,
  setShowBrandCopyWarning,
  renewalType,
  renewalReclassify,
  setRenewalReclassify,
  setRenewalType,
  showBrandLabel
}) => {
  const dispatch = useDispatch()
  const [nationalNumber, setNationalNumber] = useState<string>(title?.numNat ?? '')
  const [country, setCountry] = useState<Country>()
  const [record, setRecord] = useState<Record>()
  const [notExist, setNotExist] = useState<boolean>(false)
  const [titleIsAlreadyExtended, setTitleIsAlreadyExtended] = useState<boolean>(false)
  const [showDelayTypeWarning, setShowDelayTypeWarning] = useState<boolean>(false)
  const [showWarningModal, setShowWarningModal] = useState<string[]>([])

  useEffect(() => {
    if (title?.numNat) {
      setNationalNumber(title?.numNat)
    }
  }, [title?.numNat])

  useEffect(() => {
    ContentService.getCountry('fr').then(response => setCountry(response))
  }, [])

  /**
     * Récupère les informations de la marque via la base publique.
     */
  const verifyNumNat = async (): Promise<Record[]> => {
    // On reset uniquement les erreurs en rapport liées au composant
    dispatch(storeTransactionFieldStatusUpdate({
      ...fieldStatus,
      titleNumNat: undefined,
      titleOrigin: undefined,
      titleDepositDate: undefined,
      titleBrandType: undefined,
      titleBrandText: undefined
    }))

    if (nationalNumber) {
      let response: Record[] = []
      if (fromMadrid) {
        response = await RecordService.verifyNumnatFromMadrid({ numNat: nationalNumber, origin: origin }, true)
      } else {
        response = await RecordService.verifyNumnat({ numNat: nationalNumber, origin: origin, isRenewal: TransactionService.isRenewal(transaction) }, true)
      }
      if (transaction.procedureType === PROCEDURE_FRMI.value && transaction.subProcedureType === FRMI_TYPE_EXTENSION.value) {
        const relatedFRMIList: Transaction[] = await FRMIService.getRelatedFRMIByNumnat(nationalNumber)
        setTitleIsAlreadyExtended(relatedFRMIList.length > 0)
      }
      if (transaction.procedureType === PROCEDURE_RENEWAL.value) {
        const relatedRenewalList: Transaction[] = await RenewalService.getRelatedRenewalByNumnat(nationalNumber)
        setTitleIsAlreadyExtended(relatedRenewalList.length > 0)
      }
      if (response.length) {
        if (transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value) {
          response = response.filter(record => canRequestRecord && canRequestRecord(record))
          if (response.length > 0) {
            return response
          } else {
            setShowBrandCopyWarning && setShowBrandCopyWarning(true)
            setRecord(undefined)
          }
        } else {
          return response
        }
      } else {
        setNotExist(true)
        setRecord(undefined)
        let newTitle: Title = { numNat: nationalNumber, details: undefined }
        if (transaction.procedureType === PROCEDURE_FRMI.value) {
          newTitle = { ...newTitle, origin: 'FR' }
        }
        setTitle(newTitle)
      }
    }
  }

  /**
   * Mise à jour des produits et service pour la procédure de Renouvellement
   *
   * @param productClasses
   */
  const handleProductsAndServicesChange = (productClasses : ProductClass[]) => {
    const currentVersion: ProductClassVersion| undefined = ProductService.getCurrentVersion(title?.productsAndServicesVersions)
    setTitle({ ...title, unmodified: false, productsAndServicesVersions: currentVersion ? [{ ...currentVersion, productClasses }] : [ProductService.createProductsAndServicesVersion(PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION, PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, productClasses)] })
  }

  /**
   * Génère un titre à partir de la marque récupérée
   * @param record
   */
  const onRecordSelected = async (record: Record) => {
    let canCreateTitle = true
    if (transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value) {
      if (!(canRequestRecord && canRequestRecord(record))) {
        setShowBrandCopyWarning && setShowBrandCopyWarning(true)
        setRecord(undefined)
        canCreateTitle = false
      }
    }

    if (canCreateTitle) {
      setShowBrandCopyWarning && setShowBrandCopyWarning(false)
      setRecord(record)
      setNotExist(false)

      const createdTitle: Title = await TitleService.getTitleFromRecord(record, transaction.procedureType)
      setTitle(createdTitle)
      if (TransactionService.isRenewal(transaction)) {
        let warningModal: string[] = []
        if (titleIsAlreadyExtended) {
          warningModal = [`warning_title_already_extended_${transaction.procedureType?.toLowerCase()}`]
        }
        if (createdTitle?.typeDelay === TYPE_DELAY_BEFORE_RENEWAL) {
          warningModal = [...warningModal, 'renewal_brand_titles_type_delay_too_early_warning']
        } else if (createdTitle?.typeDelay === TYPE_DELAY_AFTER_GRACE) {
          warningModal = [...warningModal, 'renewal_brand_titles_type_delay_expired_text']
        }
        warningModal.length > 0 && setShowWarningModal(warningModal)
      }

      // Stocker l'id du record associé au premier titre de la liste
      // pour récupérer le fichier de la marque si elle vient du référentiel
      if (editIndex === 0) {
        setIdRecordTmp && setIdRecordTmp(record.id)
      }
    }
  }

  /**
   * Changement du type de délai
   * @param value
   */
  const onTypeDelayChanged = async (value: boolean) => {
    const isTooLate = title?.typeDelay === TYPE_DELAY_GRACE || title?.typeDelay === TYPE_DELAY_AFTER_GRACE
    setShowDelayTypeWarning(isTooLate && !value)
    // Si on coche la case "délai dépassé", c'est qu'on est apres le délai de grace
    let typeDelay: string|undefined = TYPE_DELAY_AFTER_GRACE
    // Si on la décoche, on remet le type de délai calculé via la date de fin de validité du record
    if (!value) {
      typeDelay = await RenewalService.getTypeDelay(record?.validityEndDate)
      // Si le type de délai calculé est "après le délai de grace", on force le décochage de la case.
      if (isTooLate) {
        typeDelay = undefined
      }
    }
    setTitle({
      ...title,
      unmodified: false,
      typeDelay
    })
  }

  /**
   * Récupérer le contenu d'une alerte
   */
  const getWarningContent = (idLabel: string) => (
    <HelpBlock className='auto-size mt-3' image='/img/picto-warning.png'>
      <div className='small'>
        <FormattedMessage
          id={idLabel}
          values={{
            b: (message : ((string) | '')) => <strong>{message}</strong>,
            tel: (message: ((string) | '')) => <a href={`tel:${message}`}>{message}</a>
          }}
        />
      </div>
    </HelpBlock>
  )

  /**
   * Contenu de la modale (concaténation des messages)
   */
  const getWarningDelayModal = () => (
    <div>
      {showWarningModal.map(value => {
        return getWarningContent(value)
      })}
    </div>
  )

  return (
    <div className='inscription-content'>
      {
        transaction.procedureType === PROCEDURE_RENEWAL.value &&
          <div className='d-flex mb-3'>
            <FormattedMessage
              id='renewal_brand_titles_help'
              values={{
                a: (...chunks: ((string) []) | []) => (
                  <a href='https://data.inpi.fr' target='_blank' rel='noopener noreferrer'>
                    &nbsp;{chunks}
                  </a>
                )
              }}
            />
          </div>
      }
      <div className={`d-flex flex-wrap ${className}`}>
        <div className='national-number__wrapper col-12 p-0 mb-3'>
          <NumNatVerifier
            inputId='numNat'
            className='col-4 p-0'
            labelClassname='mr-2'
            value={nationalNumber}
            fieldStatus={fieldStatus}
            dispatch={dispatch}
            setValue={setNationalNumber}
            countries={country ? [country] : []}
            verifyNumNat={verifyNumNat}
            label={<FormattedMessage id={origin.every((item: string) => BRAND_ORIGIN_INTERNATIONAL.value === item) ? `${TransactionService.isFrmiOP(transaction) ? 'frmi_' : ''}international_number_label` : `deposit_type_${transaction.procedureType?.toLowerCase()}_national_number_label`} />}
            onRecordSelected={verifyNumNat ? (record: Record) => {
              onRecordSelected(record)
            } : undefined}
            nameFieldStatus='titleNumNat'
            setFieldStatus={storeTransactionFieldStatusUpdate}
            disabled={false}
          />
        </div>
      </div>

      {!onlyVerifier &&
        <>
          {(notExist || isEditingMode) && (
            <TitleForm
              fieldStatus={fieldStatus}
              title={title}
              setTitle={setTitle}
              isEditingMode={isEditingMode}
              showProductsAndServices={showProductsAndServices}
              procedureType={transaction.procedureType}
              recordId={record?.id}
              transaction={transaction}
              showBrandLabel={showBrandLabel}
            />)}
          {
            title && (record || showOverview) && !isEditingMode && (
              <TitleRegisteredOverview
                title={title}
                setTitle={setTitle}
                procedureType={transaction.procedureType}
                transaction={transaction}
                fieldStatus={fieldStatus}
                recordId={record?.id}
              />)
          }
          {titleIsAlreadyExtended && getWarningContent(`warning_title_already_extended_${transaction.procedureType?.toLowerCase()}`)}
          {
            transaction.procedureType === PROCEDURE_RENEWAL.value && title &&
              <>
                <CheckboxField
                  className='col-12 mt-4'
                  inputId='typeDelay'
                  label={<FormattedMessage id='renewal_brand_titles_type_delay' />}
                  labelClassName='font-weight-bold'
                  checked={(title.typeDelay === TYPE_DELAY_GRACE || title.typeDelay === TYPE_DELAY_AFTER_GRACE)}
                  onChange={(event: EventType) => onTypeDelayChanged(event.target.value)}
                />
                {showDelayTypeWarning && <ErrorField message={<FormattedMessage id='renewal_brand_titles_type_delay_warning' />} />}
                {title.typeDelay === TYPE_DELAY_BEFORE_RENEWAL && getWarningContent('renewal_brand_titles_type_delay_too_early_warning')}
                <ContestedProductsAndServices
                  productClasses={ProductService.getCurrentVersion(title?.productsAndServicesVersions)?.productClasses ?? []}
                  handleProductsAndServicesChange={handleProductsAndServicesChange}
                  fieldStatus={fieldStatus}
                  procedureType={transaction.procedureType}
                  renewalType={renewalType}
                  renewalReclassify={renewalReclassify}
                  setRenewalReclassify={setRenewalReclassify}
                  setRenewalType={setRenewalType}
                />
              </>
          }
          {
            transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value && showBrandCopyWarning &&
              <HelpBlock className='auto-size mt-3' image='/img/picto-warning.png'>
                <div className='small'>
                  <FormattedMessage
                    id='official_documents_brand_copy_warning'
                    values={{
                      b: (message : ((string) | '')) => <strong>{message}</strong>
                    }}
                  />
                </div>
              </HelpBlock>
          }
          {(cancel || validate) && title &&
            <div className='row mt-4 justify-content-end'>
              {cancel &&
                <SubmitButton className='btn btn-link text-primary mr-5' onClick={cancel}>
                  <FormattedMessage id='button_cancel' />
                </SubmitButton>}
              {validate &&
                <SubmitButton className='bg-primary' onClick={validate}>
                  <FormattedMessage
                    id={`${(isEditingMode ? 'button_modify_title' : 'button_add_title')}`}
                  />
                </SubmitButton>}
            </div>}
        </>}
      <ModalComponent
        title={<FormattedMessage id='renewal_brand_titles_type_delay_title' />}
        show={showWarningModal && showWarningModal.length > 0}
        customContent={() => getWarningDelayModal()}
        handleClose={() => setShowWarningModal([])}
        onClick={() => setShowWarningModal([])}
        customButtonId={title?.typeDelay === TYPE_DELAY_AFTER_GRACE ? 'common_continue' : 'common_validate'}
      />
    </div>
  )
}

export default TitleValidateContent
