import {
  DateUtils,
  RENEWAL_DELAY_LABEL_END_GRACE,
  RENEWAL_DELAY_LABEL_START_RENEWAL,
  Transaction,
  TYPE_DELAY_AFTER_GRACE,
  TYPE_DELAY_BEFORE_RENEWAL,
  TYPE_DELAY_GRACE,
  TYPE_DELAY_RENEWAL
} from '@inpi-marques/components'
import { Payment } from '@inpi-marques/components/src/interfaces/deposit/Deposit'
import http from 'network/http-common'
import { toast } from 'react-toastify'
import axios, { CancelTokenSource } from 'axios'
import ContentService from '../content/ContentService'
import DelayService from '../delay/DelayService'

class RenewalService {
  source: CancelTokenSource

  constructor () {
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère le récapitulatif de paiement d'un relevé de déchéance.
   * @returns
   * @param transaction
   */
  getPaiement = async (transaction: Transaction): Promise<Payment> => {
    try {
      const result: Payment = await http.get(`/api/renewals/${transaction.id}/paiements`)
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Récupère le type de délai concerné par ce titre
   * @param validityEndDate
   */
  getTypeDelay = async (validityEndDate: string|undefined) => {
    let typeDelay

    // Récupération du type de délais depuis la date de fin de validité
    if (validityEndDate) {
      validityEndDate = DateUtils.formatToEndOfDay(validityEndDate)
      validityEndDate = await DelayService.getUpdatedDelayDate(validityEndDate)
      const delays = await ContentService.getRenewalDelays()

      const nbMonthsRenewalDelay = parseInt(delays.find(delay => delay.description === RENEWAL_DELAY_LABEL_START_RENEWAL)?.value || '0', 10)
      const nbMonthsGraceDelay = parseInt(delays.find(delay => delay.description === RENEWAL_DELAY_LABEL_END_GRACE)?.value || '0', 10)

      const today = DateUtils.now()
      const startDayRenewalDelay = DateUtils.addMonthsAndFormat(today, nbMonthsRenewalDelay)
      let endDayGraceDelay = DateUtils.subtractMonthsAndFormat(today, nbMonthsGraceDelay)
      endDayGraceDelay = DateUtils.subtractDaysAndFormat(endDayGraceDelay, 1)
      endDayGraceDelay = await DelayService.getUpdatedDelayDate(endDayGraceDelay)
      const startDayGraceDelay = await DelayService.getUpdatedDelayDate(today)

      if (DateUtils.isBefore(validityEndDate, endDayGraceDelay)) {
        // après le délai de grâce
        typeDelay = TYPE_DELAY_AFTER_GRACE
      } else if (DateUtils.isBefore(validityEndDate, startDayGraceDelay)) {
        // dans le délai de grâce
        typeDelay = TYPE_DELAY_GRACE
      } else if (DateUtils.isBefore(validityEndDate, startDayRenewalDelay)) {
        // dans le délai de renouvellement
        typeDelay = TYPE_DELAY_RENEWAL
      } else if (DateUtils.isBefore(startDayRenewalDelay, validityEndDate)) {
        // avant délai de renouvellement
        typeDelay = TYPE_DELAY_BEFORE_RENEWAL
      }
    }

    return typeDelay
  }

  /**
   * Récupère les renouvellements qui possèdent déjà la marque
   * @param numnat
   */
  getRelatedRenewalByNumnat = async (numnat: string): Promise<Transaction[]> => {
    try {
      return await http.get(`/api/renewals/${numnat}/related-renewal`, { cancelToken: this.source.token })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }
}

export default new RenewalService()
